import toPath from 'lodash/toPath';
import set from 'lodash/set';
import { ErrorMessage } from 'api';
import { getErrorMessage } from 'errors';

export function dePascalPath(pathstring: string): string[] {
    return toPath(pathstring).map((seg) => seg.slice(0, 1).toLowerCase() + seg.slice(1));
}

export function processErrors(
    errors: ErrorMessage[],
    translateErrorCode?: (code: string) => string
) {
    const dePascalled = errors.map((e) => ({
        ...e,
        fieldName: dePascalPath(e.fieldName),
    }));

    const errorObj: any = {};

    dePascalled.forEach((res) =>
        set(
            errorObj,
            res.fieldName,
            translateErrorCode
                ? translateErrorCode(res.messageCode)
                : getErrorMessage(res.messageCode)
        )
    );
    return errorObj;
}

export const GeneralErrorMessages = {
    Generic: 'Something went wrong',
    Invalid_Value: 'The entry is invalid',
    Invalid_Id: 'The entry is invalid',
    Invalid_Data: 'The entry is invalid',
    Incorrect_Type: 'Incorrect type',
    Missing_Values: 'Missing values',
    Missing_Value: 'Missing value',
    Value_Exists: 'Value already exists elsewhere',
    Invalid_Id_Order_Number_Too_Many_Times: 'Duplicate order number',
    Invalid_Value_bPrimary_Too_Many_Times: 'Only one field can be primary',
    Invalid_Value_bPrimary_Too_Few_Times: 'Please ensure a field is set as primary',
    Invalid_Id_Language_Too_Many_Times: 'You may only use each language once',
    Invalid_Value_At_Least_One: 'Please add a value',
    Invalid_Id_Parent_Missing: 'Parent missing',
    Invalid_Access: 'Invalid access. Please re-sign in.',
    Required: 'The entry is required',
    Duplicate_Request: 'Request already pending.',
    Already_Connected: 'Already Connected.',
    Connection_Rejected: 'Connection request rejected.',
    Invalid_Customer: 'Invalid customer.',
};
