import instance, { ApiResponse } from 'api';
import { AppPath } from 'appConstants';
import { AxiosResponse } from 'axios';
import { FooterLinks } from 'components/footer/types';
import { LanguageItem } from 'components/languageDropdown/LanguageDropdown';
import { UserInformation } from 'components/navigation/Navigation';
import { AccountLink, ElementType, SideMenuLink } from 'components/sideMenu/SideMenu';
import { endpoints } from 'endpoints.config';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { ActionsObservable, combineEpics, Epic, ofType } from 'redux-observable';
import { forkJoin, iif, merge, of, race } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SIGN_OUT, signOut } from './auth';
import { updateLanguage } from './language';
import { Store } from './rootReducer';

export interface Icon {
    active: string;
    inactive?: string;
    mobileInactive?: string;
}
export type Icons = { [iconName: string]: Icon };

/* STATE */
type State = {
    footerLinks: FooterLinks | null;
    languageList: LanguageItem[] | null;
    customerMenuLinks: SideMenuLink[] | null;
    customerMenuIcons: Icons | null;
    userInfo: UserInformation | null;
    fetchingPublicResources: boolean;
    fetchingCustomerResources: boolean;
};

const initialState: State = {
    footerLinks: null,
    languageList: null,
    customerMenuLinks: null,
    customerMenuIcons: null,
    userInfo: null,
    fetchingPublicResources: false,
    fetchingCustomerResources: false,
};

/* ACTIONS */
const SET_COMPONENT_RESOURCES = 'app/resources/SET_COMPONENT_RESOURCES';
const DELETE_CUSTOMER_COMPONENT_RESOURCES = 'app/resources/DELETE_CUSTOMER_COMPONENT_RESOURCES';
const UPDATE_CUSTOMER_ACCOUNT_BALANCE = 'app/resources/UPDATE_CUSTOMER_ACCOUNT_BALANCE';
const UPDATE_CUSTOMER_ACCOUNT = 'app/resources/UPDATE_CUSTOMER_ACCOUNT';
const SET_RESOURCES_FETCHING = 'app/resources/SET_RESOURCES_FETCHING';
const CLEAR_INDIVIDUAL_ICON = 'app/resources/CLEAR_INDIVIDUAL_ICON';
const SET_NETWORK_DISCOVERABLE = 'app/resources/SET_NETWORK_DISCOVERABLE';
type Action =
    | {
          type: typeof SET_COMPONENT_RESOURCES;
          payload: Partial<State>;
      }
    | {
          type: typeof UPDATE_CUSTOMER_ACCOUNT_BALANCE;
          payload: { id: number; balance: number };
      }
    | { type: typeof DELETE_CUSTOMER_COMPONENT_RESOURCES }
    | {
          type: typeof UPDATE_CUSTOMER_ACCOUNT;
          payload: { elementType: ElementType; account: AccountLink };
      }
    | {
          type: typeof CLEAR_INDIVIDUAL_ICON;
          payload: { iconName: string };
      }
    | {
          type: typeof SET_RESOURCES_FETCHING;
          payload: { resourceType: 'customer' | 'public'; loading: boolean };
      }
    | {
          type: typeof SET_NETWORK_DISCOVERABLE;
          payload: { bNetworkDiscoverable: boolean };
      };

enum EpicActionType {
    GET_PUBLIC_COMPONENT_RESOURCES = 'app/resources/GET_PUBLIC_COMPONENT_RESOURCES',
    GET_CUSTOMER_COMPONENT_RESOURCES = 'app/resources/GET_CUSTOMER_COMPONENT_RESOURCES',
    GET_ICONS = 'app/resources/GET_ICONS',
}

/* REDUCER */
export default function reducer(
    state = initialState,
    action: Action | { type: EpicActionType }
): State {
    switch (action.type) {
        case SET_COMPONENT_RESOURCES:
            return { ...state, ...action.payload };
        case DELETE_CUSTOMER_COMPONENT_RESOURCES:
            return {
                ...state,
                userInfo: null,
                customerMenuLinks: null,
                customerMenuIcons: null,
                fetchingCustomerResources: false,
            };
        case CLEAR_INDIVIDUAL_ICON:
            return {
                ...state,
                customerMenuIcons: {
                    ...state.customerMenuIcons,
                    [action.payload.iconName]: { active: '', inactive: '', mobileInactive: '' },
                },
            };
        case UPDATE_CUSTOMER_ACCOUNT_BALANCE: {
            const { customerMenuLinks, ...rest } = state;
            const newCustomerMenuLinks = customerMenuLinks
                ? customerMenuLinks?.map((link) => {
                      if (link.accounts?.some((account) => account.id === action.payload.id)) {
                          const { accounts, ...restOfLink } = link;
                          const newAccounts = accounts?.map((account) =>
                              account.id === action.payload.id
                                  ? { ...account, balance: action.payload.balance }
                                  : account
                          );
                          return { ...restOfLink, accounts: newAccounts };
                      } else return link;
                  })
                : null;
            return { ...rest, customerMenuLinks: newCustomerMenuLinks };
        }
        case UPDATE_CUSTOMER_ACCOUNT: {
            const { customerMenuLinks, ...rest } = state;
            const newCustomerMenuLinks = customerMenuLinks
                ? customerMenuLinks?.map((link) => {
                      if (link.elementType === action.payload.elementType) {
                          const { accounts, ...restOfLink } = link;
                          if (accounts === undefined)
                              return { ...restOfLink, accounts: [action.payload.account] };
                          const indexToReplace = accounts.findIndex(
                              (account) => account.id === action.payload.account.id
                          );
                          if (indexToReplace !== -1) {
                              //insert account into array
                              const newAccounts = [
                                  ...accounts.slice(0, indexToReplace),
                                  action.payload.account,
                                  ...accounts.slice(indexToReplace + 1),
                              ];
                              return { ...restOfLink, accounts: newAccounts };
                          } else {
                              return {
                                  //append account to array
                                  ...restOfLink,
                                  accounts: accounts?.concat(action.payload.account) ?? [
                                      action.payload.account,
                                  ],
                              };
                          }
                      } else return link;
                  })
                : null;
            return { ...rest, customerMenuLinks: newCustomerMenuLinks };
        }
        case SET_RESOURCES_FETCHING: {
            return {
                ...state,
                fetchingCustomerResources:
                    action.payload.resourceType === 'customer'
                        ? action.payload.loading
                        : state.fetchingCustomerResources,
                fetchingPublicResources:
                    action.payload.resourceType === 'public'
                        ? action.payload.loading
                        : state.fetchingPublicResources,
            };
        }
        case SET_NETWORK_DISCOVERABLE: {
            const userInfo: UserInformation = {
                ...(state.userInfo ?? {}),
                ...action.payload,
            } as UserInformation;
            return {
                ...state,
                userInfo: userInfo,
            };
        }

        default:
            return state;
    }
}

// Epic

const fetchComponentResource = (endpoint: string) => {
    return instance.get<ApiResponse>(endpoint);
};

const checkAllResponsesAreSuccess = (responses: AxiosResponse<ApiResponse>[]) =>
    responses.reduce((acc, response) => response.data.status === '1' && acc, true);

// To add new component resources to either public or customer areas, add a new fetchComponentResource
// call to the forkJoin array and include the response in the final object passed to setComponentResources.
// Ensure the array in the second map call is descructured in the correct order.
type GetPublicComponentResourcesActionType = {
    type: EpicActionType.GET_PUBLIC_COMPONENT_RESOURCES;
};

export const getPublicComponentResourcesEpic: Epic = (
    action$: ActionsObservable<GetPublicComponentResourcesActionType>
) =>
    action$.pipe(
        ofType(EpicActionType.GET_PUBLIC_COMPONENT_RESOURCES),
        switchMap(() => {
            return merge(
                of(setResourcesFetching('public', true)),
                forkJoin([
                    fetchComponentResource(endpoints.navigationmodule.footerlinks),
                    fetchComponentResource(endpoints.languagemodule.languages),
                ]).pipe(
                    filter((responses) => checkAllResponsesAreSuccess(responses)),
                    map((responses) => responses.map((response) => response.data.details)),
                    mergeMap(([footerLinks, languageList]) => [
                        setComponentResources({
                            footerLinks: footerLinks ?? [],
                            languageList: languageList?.languageOptions ?? [],
                        }),
                        updateLanguage({
                            languageOptions: languageList.languageOptions,
                            cultureCode: languageList.language,
                        }),
                        setResourcesFetching('public', false),
                    ])
                )
            );
        })
    );

type GetCustomerComponentResourcesActionType = {
    type: EpicActionType.GET_CUSTOMER_COMPONENT_RESOURCES;
    onComplete?: Action[];
};

export const getCustomerComponentResourcesEpic: Epic = (
    action$: ActionsObservable<GetCustomerComponentResourcesActionType>
) =>
    action$.pipe(
        ofType(EpicActionType.GET_CUSTOMER_COMPONENT_RESOURCES),
        switchMap((action) => {
            return merge(
                of(setResourcesFetching('customer', true)),
                forkJoin([
                    fetchComponentResource(endpoints.profilemodule.usersecurityinfo),
                    fetchComponentResource(endpoints.profilemodule.userinfo),
                    fetchComponentResource(endpoints.navigationmodule.customerNavigationV2Links),
                ]).pipe(
                    filter((responses) => checkAllResponsesAreSuccess(responses)),
                    map((responses) => responses.map((response) => response.data.details)),
                    switchMap(([userSecurityInfo, userInfo, customerMenuLinks]) => {
                        userInfo['emailAddress'] = userSecurityInfo.emailAddress;
                        let outputActions = [
                            setComponentResources({
                                userInfo,
                                customerMenuLinks: customerMenuLinks ?? [],
                            }),
                            setResourcesFetching('customer', false),
                            getIcons(customerMenuLinks),
                        ];
                        if (action.onComplete) outputActions.push(...action.onComplete);
                        return outputActions;
                    }),
                    catchError(() => {
                        Toast.openToastMessage(
                            'There was a problem. Please try again later',
                            ToastMessageReason.ERROR
                        );
                        return [
                            signOut(false),
                            setResourcesFetching('customer', false),
                            deleteCustomerComponentResources(),
                        ];
                    }),
                    takeUntil(action$.pipe(ofType(SIGN_OUT)))
                )
            );
        })
    );

type GetIconsEpicAction = {
    type: EpicActionType.GET_ICONS;
    payload: SideMenuLink[];
};

const ICONS_PATH = '/menuIcons/';

const fetchIconAndCreateImgSrc = (path: string, brandPath: string) => {
    const base64SrcPrefix = 'data:image/png;base64, ';
    return instance
        .get(`${window.location.origin}${brandPath}${ICONS_PATH}${path}`, {
            responseType: 'arraybuffer',
        })
        .then(({ data }) => Buffer.from(data, 'binary').toString('base64'))
        .then((base64String) => base64SrcPrefix + base64String)
        .catch((err) => '');
};

const getIconsEpic: Epic = (action$: ActionsObservable<GetIconsEpicAction>, state$) =>
    action$.pipe(
        ofType(EpicActionType.GET_ICONS),
        filter((action) => !!action.payload),
        // Filter as some iconName values are null.
        map(({ payload }) => payload.filter(({ iconName }) => !!iconName)),
        switchMap((iconNames) => {
            return iif(
                () => iconNames.length === 0,
                of(setComponentResources({ customerMenuIcons: {} })),
                forkJoin(
                    iconNames.map(({ iconName }) => {
                        return forkJoin([
                            of(iconName),
                            fetchIconAndCreateImgSrc(
                                `${iconName}_Active.png`,
                                state$.value.ui.imagesPath
                            ),
                            fetchIconAndCreateImgSrc(
                                `${iconName}_Inactive.png`,
                                state$.value.ui.imagesPath
                            ),
                            fetchIconAndCreateImgSrc(
                                `${iconName}_Mobile_Inactive.png`,
                                state$.value.ui.imagesPath
                            ),
                        ]);
                    })
                ).pipe(
                    map((icons) => {
                        /* icons.map(([active, inactive, mobileInactive]) => ({
                        active,
                        inactive,
                        mobileInactive,
                    })) */
                        return icons.reduce(
                            (prev, [iconName, active, inactive, mobileInactive]) => ({
                                ...prev,
                                [iconName]: { active, inactive, mobileInactive },
                            }),
                            {}
                        );
                    }),
                    map((customerMenuIcons) => setComponentResources({ customerMenuIcons }))
                )
            );
        })
    );

export const componentResourcesEpic = combineEpics(
    getPublicComponentResourcesEpic,
    getCustomerComponentResourcesEpic,
    getIconsEpic
);
/* ACTION CREATORS */
const setComponentResources = (resources: Partial<State>): Action => {
    return {
        type: SET_COMPONENT_RESOURCES,
        payload: resources,
    };
};

export const getPublicComponentResources = (): GetPublicComponentResourcesActionType => ({
    type: EpicActionType.GET_PUBLIC_COMPONENT_RESOURCES,
});

export const getCustomerComponentResources = (
    onComplete?: Action[]
): GetCustomerComponentResourcesActionType => {
    return {
        type: EpicActionType.GET_CUSTOMER_COMPONENT_RESOURCES,
        onComplete,
    };
};
export const setResourcesFetching = (
    resourceType: 'public' | 'customer',
    loading: boolean
): Action => ({
    type: SET_RESOURCES_FETCHING,
    payload: { resourceType, loading },
});

const getIcons = (payload: SideMenuLink[]): GetIconsEpicAction => {
    return {
        type: EpicActionType.GET_ICONS,
        payload,
    };
};
export const clearIcon = (iconName: string): Action => {
    return { type: CLEAR_INDIVIDUAL_ICON, payload: { iconName } };
};

export const deleteCustomerComponentResources = (): Action => {
    return {
        type: DELETE_CUSTOMER_COMPONENT_RESOURCES,
    };
};

export const updateAccountBalance = (payload: { id: number; balance: number }): Action => {
    return {
        type: UPDATE_CUSTOMER_ACCOUNT_BALANCE,
        payload,
    };
};
export const updateAccount = (payload: {
    elementType: ElementType;
    account: AccountLink;
}): Action => {
    return {
        type: UPDATE_CUSTOMER_ACCOUNT,
        payload,
    };
};
export const updateUserDiscoverableStatus = (bNetworkDiscoverable: boolean): Action => {
    return {
        type: SET_NETWORK_DISCOVERABLE,
        payload: { bNetworkDiscoverable: bNetworkDiscoverable },
    };
};
/* SELECTORS */
export const selectComponentResources = (store: Store) => store.componentResources;
export const selectUserInfo = (store: Store) => store.componentResources.userInfo;
export const selectAccountTypePathItem = (store: Store) => {
    return store.componentResources.userInfo?.accountType === 'Personal'
        ? AppPath.PERSONAL
        : AppPath.BUSINESS;
};
