import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { AssetCodeFormatter } from '../../../../components/AssetCodeFormatter';
import Button from '../../../../components/button/Button';
import { useGetAccountCode, useGetAccountDetails } from '../../../../helpers/useGetAccountDetails';
import { AccountFeeInfo, MakePaymentForm, PaymentStepProps } from './MakePayment';
import styles from './MakePayment.module.scss';
import api, { ApiResponse, isAxiosErrorHandled } from '../../../../api';
import { endpoints } from '../../../../endpoints.config';
import { Toast, ToastMessageReason } from '../../../../helpers/toast';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomerComponentResources } from '../../../../reducers/componentResources';
import { completeUIUpdate } from '../../../../components/notifications/notificationUIUpdateReducer';
import { getFeeLabel } from './AmountPurpose';
import { getRelevantPayloadFields, useIsFinancialInstitution } from '../helpers';
import { MakePaymentMfaModal } from './MakePaymentMfaModal';
import { FiatAccountLink, ProductType } from 'components/sideMenu/SideMenu';
import { FXOrderSummary } from './FXOrderSummary';
import { FXTransferDetails } from './amountPurposeModel';
import { FormikHelpers } from 'formik';
import instance from '../../../../api';
import { getErrorMessage } from 'errors';
import { useTheme } from '@emotion/react';
import { FoldingCube } from 'better-react-spinkit';

export type FXTranferPaymentForm = MakePaymentForm & {
    quotedFxRate: number;
    quotedFxRateId: number;
    quotedFxRateExpiry: string;
    amountLessFees: number;
    destinationAmountLessFees: number;
    totalFees: number;
};
type Props = PaymentStepProps & {
    data: FXTranferPaymentForm | MakePaymentForm;
    purposes: SelectOption[];
    accountFeeInfo: AccountFeeInfo;
    availableCountries: {
        label: string;
        value: string;
    }[];
    handleEditPayee: () => void;
    payeeId: number | undefined;
    customerAssetAccountsId: number | undefined;
    sourceCurrency: string | undefined;
    destinationCurrency: string | undefined | null;
    bFxTransfer: boolean;
};

const reviewOrder = async (
    values: MakePaymentForm,
    setReviewErrorMessage: React.Dispatch<React.SetStateAction<string>>,
    setFormData: React.Dispatch<React.SetStateAction<MakePaymentForm>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    bSubmit?: boolean
) => {
    const { id, customerAssetAccountsId, destinationAmount, amount, exchangeType } = values;
    const payload = {
        payeesId: id,
        customerAssetAccountsId,
        destinationAmount: exchangeType === 'Send' ? undefined : destinationAmount,
        sourceAmount: exchangeType === 'Send' ? amount : undefined,
        transferType: values.transferType,
    };
    setLoading(true);
    try {
        const res = await instance.post<ApiResponse<FXTransferDetails>>(
            endpoints.accounts.reviewFxTransfer,
            payload
        );
        if (res.data.status === '1' && res.data.details) {
            const details = res.data.details;
            const update: Partial<FXTranferPaymentForm> = {
                amount: details.commandSourceAmount ?? undefined,
                destinationAmount: details.commandDestinationAmount ?? undefined,
                estimatedAmount: details.estimateSourceAmount,
                estimatedDestinationAmount: details.estimateDestinationAmount,
                quotedFxRate: +details.quotedFXRate,
                quotedFxRateId: details.quotedFXRateID,
                quotedFxRateExpiry: details.quotedFxRateExpiry,
                amountLessFees: details.estimateTotalSourceAmount,
                destinationAmountLessFees: details.estimateTotalDestinationAmount,
                totalFees: details.exchangeFeeAmount,
            };
            setFormData((prev) => ({
                ...prev,
                ...update,
            }));
            if (bSubmit) {
                return {
                    ...values,
                    ...update,
                };
            }
        }
    } catch (err: any) {
        if (
            isAxiosErrorHandled(err) &&
            err.response.data.errors?.some((err) => err.messageCode === 'Insufficient_Funds')
        ) {
            setReviewErrorMessage(getErrorMessage('Insufficient_Funds'));
            setReviewErrorMessage(getErrorMessage('Insufficient_Funds'));
        } else if (
            isAxiosErrorHandled(err) &&
            err.response.data.errors?.some(
                (err) =>
                    err.fieldName === 'TransactionFailed' &&
                    err.messageCode.match(
                        /Deal amount .* is less than transaction minimum size \d*/
                    )
            )
        ) {
            setReviewErrorMessage(getErrorMessage('Amount_Too_Low'));
            setReviewErrorMessage(getErrorMessage('Amount_Too_Low'));
        } else setReviewErrorMessage(getErrorMessage('GENERIC'));
    } finally {
        setLoading(false);
    }
};

export const transferTypesThatAllowBeneficiaryReference = ['Wire', 'RTP'];

export const ReviewPayment: React.FC<Props> = ({
    data,
    purposes,
    accountFeeInfo,
    nextStep,
    handleEditPayee,
    availableCountries,
    sourceCurrency,
    destinationCurrency,
    bFxTransfer,
}) => {
    const bFinancialInstitution = useIsFinancialInstitution();
    const [loading, setLoading] = useState<boolean>(bFxTransfer);
    const [reviewErrorMessage, setReviewErrorMessage] = useState('');
    const [formData, setFormData] = useState<FXTranferPaymentForm | MakePaymentForm>(data);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const { colors } = useTheme();

    useEffect(() => {
        if (bFxTransfer) {
            reviewOrder(data, setReviewErrorMessage, setFormData, setLoading);
        }
    }, [data, bFxTransfer]);

    const account = useGetAccountDetails();
    const currencyCode = useGetAccountCode();

    const dispatch = useDispatch();

    const isCardPayee = data.accountType === 'Card';

    const [showEditPayeeButton, setshowEditPayeeButton] = useState(false);

    const [showMfaModal, setShowMfaModal] = useState(false);
    const openMfaModal = () => setShowMfaModal(true);
    const closeMfaModal = () => setShowMfaModal(false);

    const submitPayment = async (tfaCode: string, tfaType: string) => {
        setIsSubmitting(true);
        closeMfaModal();

        const payloadAsFormData = new FormData();

        let updatedQuote: FXTranferPaymentForm | undefined;
        if (bFxTransfer) {
            updatedQuote = (await reviewOrder(
                formData,
                setReviewErrorMessage,
                setFormData,
                setLoading,
                true
            )) as FXTranferPaymentForm;
            payloadAsFormData.append('fxRateId', updatedQuote.quotedFxRateId + '');
        }

        const { id, paymentReference, attachments, feeId, beneficiaryReference, ...payload } =
            getRelevantPayloadFields(updatedQuote ? updatedQuote : formData);

        payloadAsFormData.append('customerAssetAccountsId', account?.id + '');
        payloadAsFormData.append('payeesId', id + '');
        payloadAsFormData.append('tfaCode', tfaCode);
        payloadAsFormData.append('tfaType', tfaType);
        feeId && payloadAsFormData.append('bankFeeSplitType', feeId + '');
        payloadAsFormData.append('reference', paymentReference);
        if (
            transferTypesThatAllowBeneficiaryReference.includes(payload.transferType) &&
            beneficiaryReference
        )
            payloadAsFormData.append('beneficiaryReference', beneficiaryReference);
        Object.entries(payload).forEach(([key, value]) => {
            if (value != null) payloadAsFormData.append(key, value + '');
        });
        attachments.forEach((file) => {
            payloadAsFormData.append('attachments', file);
        });

        try {
            const res = await api.post<
                ApiResponse<{
                    bApproved: boolean;
                }>
            >(
                bFxTransfer
                    ? endpoints.accounts.createExternalFXTransfer
                    : endpoints.accounts.createExternalTransfer,
                payloadAsFormData,
                {
                    headers: { 'content-type': 'multipart/form-data' },
                }
            );

            if (res.data.status === '1') {
                if (!res.data.details.bApproved) {
                    Toast.openToastMessage(
                        "Your transaction has been flagged for review. The funds have been reserved and we'll let you know when it has been approved",
                        ToastMessageReason.ERROR,
                        { autoClose: false }
                    );
                    dispatch(getCustomerComponentResources());
                    dispatch(completeUIUpdate());
                } else {
                    Toast.openSuccessToast('Transfer succeeded');
                    dispatch(getCustomerComponentResources());
                    dispatch(completeUIUpdate());
                }
                nextStep();
            }
        } catch (err) {
            if (isAxiosErrorHandled(err)) {
                const errors = err.response.data.errors;
                if (
                    errors.some(
                        (err) => err.fieldName === 'PayeesId' && err.messageCode === 'Invalid'
                    )
                ) {
                    Toast.openToastMessage('Invalid Payee', ToastMessageReason.ERROR);
                } else if (
                    errors.some((err) => err.fieldName.toLowerCase().includes('transmitter'))
                ) {
                    setshowEditPayeeButton(true);
                    Toast.openErrorToast('Missing transmitting info');
                } else if (errors.some((err) => err.fieldName === 'tfaCode')) {
                    Toast.openErrorToast('Invalid TFA code');
                } else Toast.parseErrorIntoToast(err.response.data.errors?.[0]?.messageCode);
            } else Toast.openGenericErrorToast();
        } finally {
            setIsSubmitting(false);
        }
    };

    const purpose = purposes.find((item) => item.value === formData.purpose);
    const country =
        availableCountries.find((item) => item.value === formData.countryCode)?.label ??
        formData.countryCode;
    const payeeCountry =
        availableCountries.find((item) => item.value === formData.addressCountryCode)?.label ??
        formData.addressCountryCode;

    const feeOptions =
        accountFeeInfo.transferTypes.find(
            (fee) => fee.transferType.toLowerCase() === formData.transferType.toLowerCase()
        )?.feeOptions ?? [];
    const fee = feeOptions.find((fee) => fee.bankFeeSplitType === formData.feeId);

    const exactPrice = bFxTransfer ? (formData.exchangeType === 'Send' ? 'Left' : 'Right') : null;

    return (
        <div className={styles.ReviewPayment}>
            {loading ? (
                <div className={styles.Loading}>
                    <FoldingCube color={colors.first} size={80} />
                </div>
            ) : (
                <>
                    <MakePaymentMfaModal
                        open={showMfaModal}
                        handleClose={closeMfaModal}
                        handleSubmit={submitPayment}
                    />
                    {bFxTransfer && (
                        <FXOrderSummary
                            reviewErrorMessage={reviewErrorMessage}
                            transferDetails={formData as FXTranferPaymentForm}
                            sourceCurrency={sourceCurrency as string}
                            destinationCurrency={destinationCurrency as string}
                            exactPrice={exactPrice as 'Left' | 'Right'}
                        />
                    )}
                    <div className={styles.Layout}>
                        <div>
                            <h4>Recipient</h4>
                            <dl className={styles.Recipient}>
                                {!isCardPayee && (
                                    <div className={styles.Row}>
                                        <dt>Account Type:</dt>
                                        <dd>{formData.payeeType || '-'}</dd>
                                    </div>
                                )}
                                <div className={styles.Row}>
                                    <dt>Account Country:</dt>
                                    <dd>{country || '-'}</dd>
                                </div>
                                {!isCardPayee && (
                                    <div className={styles.Row}>
                                        <dt>Account Name:</dt>
                                        <dd>{formData.accountName || '-'}</dd>
                                    </div>
                                )}
                                <div className={styles.Row}>
                                    <dt>Payee Name:</dt>
                                    <dd>{formData.name}</dd>
                                </div>
                                {!isCardPayee && (
                                    <>
                                        <div className={styles.Row}>
                                            <dt>Payee Country:</dt>
                                            <dd>{payeeCountry || '-'}</dd>
                                        </div>
                                        <div className={styles.Row}>
                                            <dt>Payee Reference:</dt>
                                            <dd>{formData.payeesReference || '-'}</dd>
                                        </div>
                                    </>
                                )}
                                {formData.swiftNumber && (
                                    <div className={styles.Row}>
                                        <dt>Swift Number:</dt>
                                        <dd>{formData.swiftNumber}</dd>
                                    </div>
                                )}
                                {formData.intermediaryBic && (
                                    <div className={styles.Row}>
                                        <dt>Intermediary Swift:</dt>
                                        <dd>{formData.intermediaryBic}</dd>
                                    </div>
                                )}
                                {formData.iban && (
                                    <div className={styles.Row}>
                                        <dt>IBAN:</dt>
                                        <dd>{formData.iban}</dd>
                                    </div>
                                )}
                                {formData.routingNumber && (
                                    <div className={styles.Row}>
                                        <dt>Routing Number:</dt>
                                        <dd>{formData.routingNumber}</dd>
                                    </div>
                                )}
                                {formData.accountNumber && (
                                    <div className={styles.Row}>
                                        <dt>Account Number:</dt>
                                        <dd>{formData.accountNumber}</dd>
                                    </div>
                                )}
                            </dl>
                            {isCardPayee && (
                                <>
                                    <h4>Payment details</h4>
                                    <dl>
                                        <div className={styles.Row}>
                                            <dt>Payment Method:</dt>
                                            <dd>{data.transferType}</dd>
                                        </div>
                                        <div className={styles.Row}>
                                            <dt>Payment Reference:</dt>
                                            <dd>{data.paymentReference || '-'}</dd>
                                        </div>
                                        {(account as FiatAccountLink)?.productDisplayName ===
                                            ProductType.CRB && (
                                            <div className={styles.Row}>
                                                <dt>Beneficiary Reference:</dt>
                                                <dd>{data.beneficiaryReference || '-'}</dd>
                                            </div>
                                        )}
                                    </dl>
                                </>
                            )}
                        </div>
                        <div>
                            {!isCardPayee && (
                                <>
                                    <h4>Payment details</h4>
                                    <dl>
                                        <div className={styles.Row}>
                                            <dt>Payment Method:</dt>
                                            <dd>{formData.transferType}</dd>
                                        </div>
                                        <div className={styles.Row}>
                                            <dt>Payment Reference:</dt>
                                            <dd>{formData.paymentReference || '-'}</dd>
                                        </div>
                                        {(account as FiatAccountLink)?.productDisplayName ===
                                            ProductType.CRB && (
                                            <div className={styles.Row}>
                                                <dt>Beneficiary Reference:</dt>
                                                <dd>{formData.beneficiaryReference || '-'}</dd>
                                            </div>
                                        )}
                                    </dl>
                                </>
                            )}

                            <h4>Amount & purpose</h4>
                            <dl>
                                {fee && feeOptions.length > 1 && (
                                    <div className={styles.Row}>
                                        <dt>Fee:</dt>
                                        {/* @ts-ignore */}
                                        <dd>{getFeeLabel(fee.bankFeeSplitType).label}</dd>
                                    </div>
                                )}
                                <div className={styles.Row}>
                                    {exactPrice ? (
                                        <>
                                            <dt>{`${
                                                exactPrice === 'Left' ? 'Exact ' : 'Estimated '
                                            }Amount (including fees):`}</dt>
                                            <dd>
                                                {exactPrice === 'Left'
                                                    ? formData.amount
                                                    : formData.estimatedAmount}{' '}
                                                <AssetCodeFormatter
                                                    assetCode={sourceCurrency as string}
                                                />
                                            </dd>
                                        </>
                                    ) : (
                                        <>
                                            <dt>Amount:</dt>
                                            <dd>
                                                {formData.amount}{' '}
                                                <AssetCodeFormatter assetCode={currencyCode} />
                                            </dd>
                                        </>
                                    )}
                                </div>
                                {bFxTransfer && (
                                    <>
                                        <div className={styles.Row}>
                                            <dt>Total Exchange Fees:</dt>
                                            <dd>
                                                {(formData as FXTranferPaymentForm).totalFees}{' '}
                                                <AssetCodeFormatter
                                                    assetCode={sourceCurrency as string}
                                                />
                                            </dd>
                                        </div>
                                        <div className={styles.Row}>
                                            <dt>{`${
                                                exactPrice === 'Right' ? 'Exact ' : 'Estimated '
                                            }Recipient Amount:`}</dt>
                                            <dd>
                                                {
                                                    (formData as FXTranferPaymentForm)
                                                        .destinationAmountLessFees
                                                }{' '}
                                                <AssetCodeFormatter
                                                    assetCode={destinationCurrency as string}
                                                />
                                            </dd>
                                        </div>
                                    </>
                                )}
                                <div className={styles.Row}>
                                    <dt>{formData.transferType} Payment Purpose:</dt>
                                    {/* @ts-ignore */}
                                    <dd>{purpose ? purpose.label : formData.purpose || '-'}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>

                    <h4>More details</h4>
                    <dl>
                        <div className={styles.Row}>
                            <dt>Attachments:</dt>
                            <dd>
                                {formData.attachments.length > 0
                                    ? formData.attachments.reduce(
                                          (prev, cur, i) =>
                                              prev + `${i !== 0 ? ', ' : ''}${cur.name}`,
                                          ''
                                      )
                                    : '-'}
                            </dd>
                        </div>
                        <div className={styles.Row}>
                            <dt>Notes:</dt>
                            <dd>{formData.notes || '-'}</dd>
                        </div>
                    </dl>

                    {showEditPayeeButton && (
                        <Button
                            className="MarginBottom"
                            type="button"
                            priority="secondary"
                            disabled={isSubmitting}
                            onClick={handleEditPayee}
                            color="first"
                        >
                            Edit Payee
                        </Button>
                    )}
                    <Button
                        disabled={isSubmitting || loading}
                        onClick={openMfaModal}
                        color="third"
                        showSubmittingSpinner={isSubmitting}
                    >
                        Confirm Payment
                    </Button>
                </>
            )}
        </div>
    );
};
