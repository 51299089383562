import React from 'react';
import { FXTranferPaymentForm } from './ReviewPayment';
import { formatCurrency } from 'helpers/fiatFormatter';
import { roundToPrecision } from 'helpers/calculateToPrecision';

type Props = {
    reviewErrorMessage: string;
    transferDetails: FXTranferPaymentForm;
    sourceCurrency: string;
    destinationCurrency: string;
    exactPrice: 'Left' | 'Right';
};
export const FXOrderSummary: React.FC<Props> = ({
    reviewErrorMessage,
    transferDetails,
    sourceCurrency,
    destinationCurrency,
    exactPrice,
}) => {
    return (
        <div className="CurrencyExchangeModal">
            <h4>Exchange Summary</h4>
            {reviewErrorMessage && <div className="ErrorText">{reviewErrorMessage}</div>}
            <div className="BuySellAmounts">
                <div>
                    <h4>{`You're sending ${exactPrice === 'Left' ? 'exactly' : ''}`}</h4>
                    <h3>
                        {formatCurrency(
                            transferDetails.amount ?? transferDetails.estimatedAmount,
                            sourceCurrency,
                            undefined,
                            {
                                currencyDisplay: 'code',
                            }
                        )}
                    </h3>
                    {transferDetails.exchangeType === 'Receive' && (
                        <span>({transferDetails.amountLessFees} after fees)</span>
                    )}
                </div>
                <div>
                    <h4>{`Recipient will receive ${exactPrice === 'Right' ? 'exactly' : ''}`}</h4>
                    <h3>
                        {formatCurrency(
                            transferDetails.destinationAmountLessFees,
                            destinationCurrency,
                            undefined,
                            {
                                currencyDisplay: 'code',
                            }
                        )}
                    </h3>
                    {transferDetails.exchangeType === 'Send' && (
                        <span>({transferDetails.estimatedDestinationAmount} minus fees)</span>
                    )}
                </div>
            </div>
            <div className="ExchangeRate ExchangeRateConfirm">
                <div>Quoted Price:</div>
                <div className="Rates">
                    {transferDetails?.quotedFxRate && (
                        <>
                            <p>
                                {sourceCurrency} 1 <span className="c1">{'>'}</span>
                                <span className="c2">{'>'}</span>
                                <span className="c3">{'>'}</span> {destinationCurrency} ~{' '}
                                {/* {roundToPrecision(parseFloat(transferDetails.quotedFxRate), 6)} */}
                                {roundToPrecision(transferDetails.quotedFxRate, 6)}
                            </p>
                            <p>
                                {destinationCurrency} 1 <span className="c1">{'>'}</span>
                                <span className="c2">{'>'}</span>
                                <span className="c3">{'>'}</span> {sourceCurrency} ~{' '}
                                {/* {roundToPrecision(1 / parseFloat(transferDetails.quotedFxRate), 6)} */}
                                {roundToPrecision(1 / transferDetails.quotedFxRate, 6)}
                            </p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
