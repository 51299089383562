import { Form, Formik, FormikHelpers } from 'formik';
import api, { ApiResponse, isAxiosErrorHandled } from '../../../../api';
import Button from '../../../../components/button/Button';
import { endpoints } from '../../../../endpoints.config';
import {
    EMPTY_PAYEE_VALUES,
    EMPTY_TRANSMITTER_INFO,
    PayeeForm,
    PayeeFormState,
} from '../EditPayee';
import { PaymentStepProps } from './MakePayment';
import { ItemConnectionAssetAccountModel } from 'pages/IbaneraNetwork/items/ItemConnectionAssetAccount';
import { useEffect, useState } from 'react';
import { ItemConnectionModel } from 'pages/IbaneraNetwork/items/ItemConnection';
import { Spinner } from 'components/spinner/Spinner';

export type ErrorCountry = { name: string; countryISO3: string; errorCode: string | null };
type Props = PaymentStepProps & {
    connectionPayee: ItemConnectionModel;
};

export const RecipientAssetAccount: React.FC<Props> = ({ nextStep, connectionPayee }) => {
    const [loading, setLoading] = useState(false);
    const [selectedAssetAccount, setSelectedAssetAccount] = useState<
        ItemConnectionAssetAccountModel | undefined
    >();
    const [assetAccountsList, setAssetAccountsList] = useState<ItemConnectionAssetAccountModel[]>(
        []
    );

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const response = await api.get<ApiResponse<ItemConnectionAssetAccountModel[]>>(
                    `${endpoints.networkmodule.getNetworkConnectionAssetsAccounts}`,
                    {
                        params: {
                            connectionCustomerID:
                                connectionPayee.customerId == connectionPayee.senderId
                                    ? connectionPayee.receiverId
                                    : connectionPayee.senderId,
                        },
                    }
                );

                if (response.data.details !== null) {
                    setAssetAccountsList(response.data.details);
                } else {
                    setAssetAccountsList([]);
                }
            } catch (error) {
                setAssetAccountsList([]);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleChange = (event: any) => {
        const selectedId = parseInt(event.target.value, 10);
        const selected = assetAccountsList.find(
            (item: ItemConnectionAssetAccountModel) => item.id === selectedId
        );
        setSelectedAssetAccount(selected);
    };
    const handleNextClick = (event: any) => {
        nextStep({ payeeConnectionAssetAccount: selectedAssetAccount });
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <h1 className="make-connect-payment-label">Connection:</h1>
            <span style={{ fontSize: '14px' }}>
                {connectionPayee.customerId === connectionPayee.senderId
                    ? connectionPayee.receiverName
                    : connectionPayee.senderName}
            </span>
            <h1 className="make-connect-payment-label">
                Connection Asset account: {loading && <Spinner className={'Relative'} />}
            </h1>

            <select id="item-dropdown" onChange={handleChange} className="dropdown-asset-accounts">
                <option value="">Select Asset Account</option>
                {assetAccountsList.map((item, index) => (
                    <option key={index} value={item.id} className="dropdown-asset-accounts-label">
                        {item.accountName}
                    </option>
                ))}
            </select>
            <div className="Buttons">
                <Button type="submit" color="third" onClick={handleNextClick} disabled={loading}>
                    {'Next'}
                </Button>
            </div>
        </div>
    );
};
