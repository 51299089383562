import { TextCurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import { useFormikContext } from 'formik';
import { accountAsMergedAccount, ExchangeDetails } from 'pages/cryptoV2/models';
import { FormValues } from '../CryptoExchangeModal';
import { AccountDropdown } from './AccountDropdown';
import { useEffect, useMemo } from 'react';
import { RadioButtons } from 'components/radiobuttons/radiobuttons';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import FormTextField from 'components/form/FormTextField';
import { OtherPurpose } from 'pages/account/Payees/MakePayment/AmountPurpose';
import { TransferType } from 'pages/crypto/Crypto';

export const formatGeneralCurrency = (
    num: number,
    code: string,
    after = false,
    options?: Intl.NumberFormatOptions
) =>
    `${!after ? `${code} ` : ''}${Number(num).toLocaleString('en', {
        maximumFractionDigits: 13,
        ...options,
    })}${after ? ` ${code}` : ''}`;

export const FromTo = ({ exchangeDetails }: { exchangeDetails: ExchangeDetails }) => {
    const {
        values: { exchangeType, asset, pairedAsset, fromAccountId, toMergedAccountGenericId },
        setFieldValue,
    } = useFormikContext<FormValues>();

    const sourceCurrency = exchangeType === 'Buy' ? pairedAsset : asset;
    const destinationCurrency = exchangeType === 'Buy' ? asset : pairedAsset;

    const fromAccount = useMemo(
        () => exchangeDetails.fromAccounts.find((acc) => acc.accountID === fromAccountId),
        [exchangeDetails.fromAccounts, fromAccountId]
    );
    const toAccount = exchangeDetails.mergedToAccounts.find(
        (acc) => acc.genericId === toMergedAccountGenericId
    );

    const fromHasOptions = exchangeDetails.fromAccounts.length > 1;
    const toHasOptions = exchangeDetails.mergedToAccounts.length > 1;

    useEffect(() => {
        if (fromAccount && fromAccount.assetID)
            exchangeType === 'Buy'
                ? setFieldValue('pairedAssetsId', fromAccount.assetID)
                : setFieldValue('assetsId', fromAccount.assetID);
    }, [fromAccount, setFieldValue, exchangeType]);

    return (
        <div className="FromTo">
            {!fromHasOptions && fromAccount && (
                <div className="From">
                    <TextCurrencyIcon currencySymbol={fromAccount.currencySymbol} />
                    <div className="AccountInfo">
                        <p>From:</p>
                        <h4>{fromAccount.displayName}</h4>
                    </div>
                    <div className="Balance">
                        {formatGeneralCurrency(fromAccount.balance, sourceCurrency)}
                    </div>
                </div>
            )}
            {fromHasOptions && (
                <AccountDropdown
                    fieldname="fromAccountId"
                    currency={sourceCurrency}
                    accounts={exchangeDetails.fromAccounts.map(accountAsMergedAccount)}
                    direction="From"
                />
            )}
            {!toHasOptions && toAccount && (
                <div className={`To ${fromHasOptions ? 'ExtraMargin' : ''}`}>
                    <TextCurrencyIcon currencySymbol={toAccount.currencySymbol} />
                    <div className="AccountInfo">
                        <p>To:</p>
                        <h4>{toAccount.label}</h4>
                    </div>
                    {toAccount.balance !== null && (
                        <div className="Balance">
                            {formatGeneralCurrency(toAccount.balance, destinationCurrency)}
                        </div>
                    )}
                </div>
            )}
            {toHasOptions && (
                <AccountDropdown
                    fieldname="toMergedAccountGenericId"
                    currency={destinationCurrency}
                    accounts={exchangeDetails.mergedToAccounts}
                    direction="To"
                />
            )}
            <TransferTypeSelector exchangeDetails={exchangeDetails} />
            <PurposeSelector exchangeDetails={exchangeDetails} />
        </div>
    );
};

const TransferTypeSelector = ({ exchangeDetails }: { exchangeDetails: ExchangeDetails }) => {
    const {
        values: { toMergedAccountGenericId },
    } = useFormikContext<FormValues>();

    const selectedDestination = useMemo(
        () =>
            exchangeDetails.mergedToAccounts.find(
                (account) => account.genericId === toMergedAccountGenericId
            ),
        [exchangeDetails, toMergedAccountGenericId]
    );

    return (
        <RadioButtons
            options={selectedDestination?.allowedTransferTypes ?? []}
            fieldname={'transferType'}
        />
    );
};

const PurposeSelector = ({ exchangeDetails }: { exchangeDetails: ExchangeDetails }) => {
    const {
        values: { toMergedAccountGenericId, isInternalPayee },
        setFieldValue,
    } = useFormikContext<FormValues>();

    const selectedToAccountOrPayee = useMemo(
        () =>
            exchangeDetails.mergedToAccounts.find(
                (account) => account.genericId === toMergedAccountGenericId
            ),
        [exchangeDetails, toMergedAccountGenericId]
    );

    useEffect(() => {
        //clear purpose & purposeOther when payee changes
        setFieldValue('purpose', null);
        setFieldValue('purposeOther', null);
        setFieldValue(
            'isInternalPayee',
            selectedToAccountOrPayee?.type === 'Account' ||
                (selectedToAccountOrPayee?.allowedTransferTypes?.length === 1 &&
                    selectedToAccountOrPayee.allowedTransferTypes[0] === TransferType.Internal)
        );
    }, [selectedToAccountOrPayee, setFieldValue]);

    const purposes = useMemo(
        () =>
            selectedToAccountOrPayee?.availablePurposeCodes?.map((purpose) => ({
                label: purpose.description,
                value: purpose.code,
            })) ?? null,
        [selectedToAccountOrPayee]
    );

    // BE tells me the best way to detect 'other' will be to check for this string
    const otherPurposeCode: string | undefined = purposes?.find(
        (purpose) => purpose.label.toLowerCase() === 'other'
    )?.value;

    if (isInternalPayee) return null;
    return (
        <>
            {purposes && purposes.length > 0 ? (
                <WrappedFormSingleSelectField
                    fieldName="purpose"
                    options={purposes ?? []}
                    label={`Payment Purpose `}
                    dropdownProps={{
                        placeholder: 'Please select',
                    }}
                />
            ) : (
                <FormTextField
                    field="purpose"
                    label={`Payment Purpose `}
                    placeholder="E.g. Utility Bill"
                />
            )}
            <OtherPurpose otherPurposeCode={otherPurposeCode} />
        </>
    );
};
