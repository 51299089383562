import { useEffect } from 'react';
import Icon_Close from 'assets/ibanera/Icon_Close.png';
import BackIcon from 'assets/Icon_Btn_07.png';
import { Filter } from '@avamae/parse-filter';
import { useDebouncedState } from 'helpers/useDebouncedState';
import { Dropdown, SelectOption } from 'components/form/Dropdown';
import { AccountsTable } from './UserSearch';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import React from 'react';

type Props = {
    handleCloseFilter: () => void;
    table: AccountsTable;
    // userSelected: boolean;
};

/*
Improvements:
1) Set initial values based off endpoint. Currently just clearing
all filters on load but could load them off endpoint response
2) Handle more filter types (Only interseted in Single Choice currently)
*/

export const SearchFilter = <ListData extends { [key: string]: any }>({
    table,
    handleCloseFilter,
}: Props) => {
    const [values, setValues, localValues] = useDebouncedState<ListData>({} as any);
    const dispatch = useDispatch();
    useEffect(() => {
        values && handleSetFilters(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const handleSetFilters = (filterValues: ListData) => {
        const filters: Filter[] = Object.entries(filterValues).map(([key, value]) => ({
            columnKey: key,
            operator: 'EQ',
            value: value,
        }));

        table.data?.actions.setFilters(filters);
    };

    const filterColumns = table.data
        ? table.data.details.columns?.filter(
              (col) =>
                  col.bFilterable &&
                  col.filterMetadata &&
                  col.filterMetadata.filterType === 'SingleChoice' &&
                  col.filterMetadata.details &&
                  col.filterMetadata.details?.PossibleValues
          )
        : [];

    const handleChange = (value: string | null, key: string): void => {
        setValues((prev) => {
            if (value === null) {
                const copy = { ...prev };
                delete copy[key];
                return copy;
            } else {
                return {
                    ...prev,
                    [key]: value,
                };
            }
        });
    };

    return (
        <div className="SearchFilter">
            <div className="ModalHeader">
                <button
                    title="Go back"
                    className="BackButton"
                    type="button"
                    onClick={handleCloseFilter}
                >
                    <img src={BackIcon} alt="Go back" />
                </button>
                <h2>Apply Filters</h2>
                <div className="ExitImg">
                    <img
                        src={Icon_Close}
                        alt="CloseIcon"
                        onClick={() => {
                            dispatch(closeModal());
                        }}
                    />
                </div>
            </div>
            <div className="FilterContainer">
                {filterColumns?.map((col, i: number) =>
                    col.filterMetadata?.details?.PossibleValues?.length === 2 ? (
                        <React.Fragment key={col.columnKey + i}></React.Fragment>
                    ) : (
                        // col.filterMetadata?.details?.PossibleValues?.map(
                        //     (opt: SelectOption & { subLabel?: string }, i: number) => (
                        //         <RadioButton
                        //             key={col.columnKey + i}
                        //             label={col.labelValue}
                        //             selected={localValues[col.columnKey] === opt.value}
                        //             onClick={() =>
                        //                 handleChange(
                        //                     values[col.columnKey] === opt.value ? null : opt.value,
                        //                     col.columnKey as string
                        //                 )
                        //             }
                        //         />
                        //     )
                        // )
                        <div className="FormBox" key={col.columnKey + i}>
                            <div className="FormLabel">
                                <label>{col.labelValue}</label>
                            </div>
                            <div className="FormField">
                                <Dropdown
                                    key={col.columnKey}
                                    id={col.columnKey}
                                    value={col.filterMetadata?.details?.PossibleValues?.find(
                                        (opt: any) => opt.label === localValues[col.columnKey]
                                    )}
                                    onChange={(option: SelectOption | null) =>
                                        handleChange(
                                            option ? option.value : option,
                                            col.columnKey as string
                                        )
                                    }
                                    options={col.filterMetadata?.details?.PossibleValues}
                                    dropdownProps={{
                                        isClearable: true,
                                    }}
                                    roundedRectangle
                                />
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};
