/** @jsxImportSource @emotion/react */
import { useField } from 'formik';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { selectImagePath } from 'reducers/ui';

export function CheckboxInput<T>(props: {
    name: string;
    label?: string;
    className?: string;
    withFormStyling?: boolean;
    disabled?: boolean;
    onChange?: any
    checked?: boolean
}) {
    const fieldProps = { ...props, type: 'checkbox' };
    const [field] = useField(fieldProps);
    const { className, label, withFormStyling, ...rest } = fieldProps;

    const imagesPath = useSelector(selectImagePath);

    const CheckboxCss = css`
        input[type='checkbox']:checked + .CustomInput:after {
            background-image: url('${imagesPath}/Icon_Tick.png');
        }
    `;

    return (
        <div
            className={`CheckboxContainer ${withFormStyling ? 'FormStyling' : ''} ${
                field.value ? 'Checked' : ''
            }`}
            css={CheckboxCss}
        >
            <input
                {...(field as any)}
                {...rest}
                className={className ?? 'CheckboxInput'}
                id={field.name}
            />
            <label className="CustomInput" htmlFor={field.name}>
                {label ?? ''}
            </label>
        </div>
    );
}
