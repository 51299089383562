import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import api, { ApiResponse, isAxiosErrorHandled } from 'api';
import { endpoints } from 'endpoints.config';
import './ConnectionsPage.scss';
import Button from 'components/button/Button';
import { FoldingCube } from 'better-react-spinkit';
import { useTheme } from '@emotion/react';
import { ItemConnection, ItemConnectionModel } from './items/ItemConnection';
type Props = {
    back?: () => void;
};

export const PendingConnectionsRequests = forwardRef((props: Props, ref) => {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [networkUsers, setNetworkUsers] = useState<ItemConnectionModel[]>([]);


    const { colors } = useTheme();
    const loadConnections = useCallback(async () => {
        try {
            setIsPageLoading(true);
            const response = await api.get<ApiResponse<ItemConnectionModel[]>>(
                `${endpoints.networkmodule.getNetworkConnections}?ConnectionStatus=PENDING`
            );

            if (response.data.details !== null) {
                setNetworkUsers(response.data.details);
            } else {
                setNetworkUsers([]);
            }
        } catch (error) {
            setNetworkUsers([]);
        } finally {
            setIsPageLoading(false);
        }
    }, []);
    useEffect(() => {
        loadConnections();
    }, [loadConnections]);

    return (
        <div className="list-container-networks">
                {isPageLoading && (
                    <div className="PageLoader">
                        <FoldingCube color={colors.first} size={80} />
                    </div>
                )}
                {networkUsers != null && networkUsers.length > 0 ? (
                    networkUsers.map((item: ItemConnectionModel) => (
                        <ItemConnection
                            networkUser={item}
                            showConnectionRequest={(itemClicked: ItemConnectionModel) => {
                                // setConnectionDetail(itemClicked);
                            }}
                        />
                    ))
                ) : (
                    <div className="no-results-message">No results found</div>
                )}
            </div>
    );
});
