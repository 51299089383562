import { format } from 'date-fns';
import instance, { ApiResponse } from 'api';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './ConnectionsPage.scss';
import { useTheme } from '@emotion/react';
import { ModalAddConnection } from './modals/ModalAddConnection';
import { AllConnectionsList } from './AllConnectionsList';
import { PendingConnectionsRequests } from './PendingConnectionsRequests';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectComponentResources,
    updateUserDiscoverableStatus,
} from 'reducers/componentResources';
import { endpoints } from 'endpoints.config';
import { toast } from 'react-toastify';

type Props = {
    goBack?: () => void;
    isHideHeaders?: boolean;
};

export const ConnectionsPage: React.FC<Props> = ({ goBack, isHideHeaders }) => {
    const { userInfo } = useSelector(selectComponentResources);
    const dispatch = useDispatch();
    const [showAddConnection, setShowAddConnection] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState('connections');
    const [isDiscoverAble, setIsDisconverAble] = useState<boolean>(true);
    const connectionsListRef = useRef<any>();
    const handleAddConClick = useCallback(() => {
        setShowAddConnection(true);
    }, []);
    const handleDiscoverAble = () => {
        setIsDisconverAble((prevISDiscoverAble) => {
            instance
                .post(endpoints.networkmodule.editNetworkConfigs, {
                    bNetworkDiscoverable: prevISDiscoverAble === true ? 0 : 1,
                })
                .then(() => {})
                .catch(() => {});

            dispatch(updateUserDiscoverableStatus(!prevISDiscoverAble));
            return !prevISDiscoverAble;
        });
    };

    useEffect(() => {
        setIsDisconverAble(userInfo?.bNetworkDiscoverable ?? false);
    }, [userInfo?.bNetworkDiscoverable]);
    const handleCopy = () => {
        navigator.clipboard
            .writeText(userInfo?.networkID ?? '')
            .then(() => {
                toast('Copied to clipboard!');
            })
            .catch((err) => {
                toast('Failed to copy!');
            });
    };

    return (
        <div className="IbaneraNetworkWrapper">
            {goBack && (
                <div className="Back" onClick={goBack}>
                    {'< Back'}
                </div>
            )}
            {!isHideHeaders && (
                <div className="RestrictWidth">
                    <div className="IbaneraNetworkHeader">
                        <h1>Ibanera Network</h1>
                    </div>
                </div>
            )}
            <div className="row-container-options">
                {/* First label */}
                <div className="label-item-options" onClick={handleDiscoverAble}>
                    <img
                        src={
                            isDiscoverAble
                                ? '\\assets\\ibanera\\Icon_Form_01.png'
                                : '\\assets\\ibanera\\not_discoverable.png'
                        }
                        alt="icon"
                        className="icon-options"
                    />
                    <span className="title-options" style={isDiscoverAble ? {} : { color: 'red' }}>
                        Discoverable
                    </span>
                </div>

                {/* Second label */}
                <div className="label-item-options">
                    <img
                        src={'\\assets\\ibanera\\copy_icon.png'}
                        alt="icon"
                        className="icon-options"
                        style={{ padding: 7 }}
                    />
                    <span className="title-options" onClick={handleCopy}>
                        Network ID: {userInfo?.networkID ?? ''}
                    </span>
                </div>

                <div className="label-item-options" onClick={handleAddConClick}>
                    <img
                        src={'\\assets\\ibanera\\pending_request.png'}
                        alt="icon"
                        className="icon-options"
                        style={{ padding: 7 }}
                    />
                    <span className="title-options">Add Connection</span>
                </div>
            </div>
            <div className="tab-container">
                {/* Tab Headers */}
                {!isHideHeaders && (
                    <div className="tab-header">
                        <button
                            className={`tab-button ${activeTab === 'connections' ? 'active' : ''}`}
                            onClick={() => setActiveTab('connections')}
                        >
                            Connections
                        </button>
                        <button
                            className={`tab-button ${activeTab === 'requests' ? 'active' : ''}`}
                            onClick={() => setActiveTab('requests')}
                        >
                            Connection Requests
                        </button>
                    </div>
                )}

                {/* Tab Content */}
                <div className="tab-content">
                    {activeTab === 'connections' && <AllConnectionsList ref={connectionsListRef} />}
                    {activeTab === 'requests' && (
                        <PendingConnectionsRequests ref={connectionsListRef} />
                    )}
                </div>
            </div>
            {showAddConnection && (
                <ModalAddConnection
                    closeModal={(status) => {
                        setShowAddConnection(false);
                        if (status) {
                            connectionsListRef.current?.refreshData();
                        }
                    }}
                />
            )}
        </div>
    );
};
