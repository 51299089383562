import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useDebouncedState = <T>(
    initialValue: T,
    debounceTime: number = 500
): [T, Dispatch<SetStateAction<T>>, T, number] => {
    const [debouncedState, setDebouncedState] = useState<T>(initialValue);
    const [temporaryState, setTemporaryState] = useState<T>(initialValue);
    const [timeout, setTimeout] = useState<number | null>(null);
    const [count, setCount] = useState<number>(1);

    useEffect(() => {
        if (timeout) {
            window.clearTimeout(timeout);
        }
        const localTimeout = window.setTimeout(() => {
            setDebouncedState(temporaryState);
            setCount(count + 1);
        }, debounceTime);
        setTimeout(localTimeout);
    }, [temporaryState, debounceTime]);

    return [debouncedState, setTemporaryState, temporaryState, count];
};
