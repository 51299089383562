import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refreshAccessToken } from 'reducers/auth';
import {
    getCustomerComponentResources,
    selectComponentResources,
    updateAccount,
    updateAccountBalance,
} from 'reducers/componentResources';
import {
    completeUIUpdate,
    NotificationIdentifier,
    selectUIUpdate,
    setUIUpdate,
} from '../components/notifications/notificationUIUpdateReducer';
import { updatePrices as setCryptoPrices } from '../reducers/cryptoPrices';

export const useNotificationUIUpdates = () => {
    const dispatch = useDispatch();
    const update = useSelector(selectUIUpdate);

    const { userInfo } = useSelector(selectComponentResources);

    useEffect(() => {
        if (update) {
            switch (update.pushType) {
                case NotificationIdentifier.UPDATE_ASSET_PRICES:
                    dispatch(setCryptoPrices(update.data.prices));
                    dispatch(completeUIUpdate());
                    break;
                case NotificationIdentifier.RELOAD_NAVIGATION_LINKS:
                    dispatch(getCustomerComponentResources());
                    dispatch(completeUIUpdate());
                    break;
                case NotificationIdentifier.QUICKSELL_DEPOSIT_SUCCEEDED:
                    dispatch(getCustomerComponentResources());
                    //not calling completeuiupdate here so that we can catch the change in the component if it's still open
                    break;
                case NotificationIdentifier.UPDATE_ACCOUNT_BALANCE:
                    dispatch(
                        updateAccountBalance({
                            id: update.data.customerAssetAccountsId,
                            balance: update.data.balance,
                        })
                    );
                    dispatch(completeUIUpdate());
                    break;
                case NotificationIdentifier.MERGE_ACCOUNT:
                    dispatch(updateAccount(update.data));
                    dispatch(completeUIUpdate());
                    break;
                case NotificationIdentifier.BUSINESS_VERIFIED:
                    if (
                        userInfo &&
                        userInfo.customerUsersCustomersId ===
                            update.data.additionalData.customerUsersCustomersId
                    ) {
                        dispatch(refreshAccessToken([getCustomerComponentResources()]));
                    }
                    dispatch(completeUIUpdate());
                    break;
            }
        }
    }, [update, dispatch]);
};
