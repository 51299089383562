import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { ReactComponent as ExchangeSvg } from 'assets/icon-exchange.svg';
import { useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import { FXAmountPurposeForm } from './amountPurposeModel';
import { PayeeDetails } from './MakePayment';
import { roundToPrecision } from 'helpers/calculateToPrecision';
import logger from 'helpers/logger';

type Props = {
    sourceCurrency: string | undefined;
    destinationCurrency: string | undefined | null;
    payeeDetails: PayeeDetails | undefined;
};

export const AmountFx: React.FC<Props> = ({
    sourceCurrency,
    destinationCurrency,
    payeeDetails,
}) => {
    const { values, setFieldValue } = useFormikContext<FXAmountPurposeForm>();

    const getFee = useCallback(
        (amount: number, type: 'source' | 'destination') => {
            if (payeeDetails) {
                let fee = amount * (payeeDetails.feePercentage / 100);
                return type === 'source'
                    ? Math.max(fee, payeeDetails.sourceAmountMinFee)
                    : Math.max(fee, payeeDetails.destinationAmountMinFee);
            }
            return 0;
        },
        [payeeDetails]
    );

    const switchExchangeType = () => {
        logger.log(values);
        if (values.exchangeType === 'Send') {
            setFieldValue('exchangeType', 'Receive');
            setFieldValue('amount', null, true);
            setFieldValue('estimatedAmount', values.amount);
            setFieldValue('destinationAmount', values.estimatedDestinationAmount);
            setFieldValue('estimatedDestinationAmount', null);
        } else {
            setFieldValue('exchangeType', 'Send');
            setFieldValue('amount', values.estimatedAmount);
            setFieldValue('estimatedAmount', null);
            setFieldValue('destinationAmount', null);
            setFieldValue('estimatedDestinationAmount', values.destinationAmount);
        }
    };

    useEffect(() => {
        if (values.amount === null) return;
        if (values.amount === 0) {
            setFieldValue('estimatedDestinationAmount', 0);
            return;
        }
        if (payeeDetails) {
            let fees = getFee(values.amount, 'source');

            setFieldValue(
                'estimatedDestinationAmount',
                roundToPrecision((values.amount - fees) * payeeDetails?.buyPrice, 2)
            );
        }
    }, [values.amount, payeeDetails, setFieldValue, getFee]);

    useEffect(() => {
        if (values.destinationAmount === null) return;
        if (values.destinationAmount === 0) {
            setFieldValue('estimatedAmount', 0);
            return;
        }
        if (payeeDetails) {
            let fees = getFee(values.destinationAmount, 'destination');

            setFieldValue(
                'estimatedAmount',
                roundToPrecision((values.destinationAmount + fees) / payeeDetails.buyPrice, 2)
            );
        }
    }, [values.destinationAmount, payeeDetails, setFieldValue, getFee]);

    return (
        <div className="CurrencyExchangeModal ExchangeDetail">
            <div className={'InputSection' + (values.exchangeType === 'Send' ? ' Exact' : '')}>
                <div className="CurrencyInputWrapper">
                    <div className="CurrencyLabel" style={{ top: '37px' }}>
                        {sourceCurrency}
                    </div>
                    <FormTextField
                        field={values.exchangeType === 'Send' ? 'amount' : 'estimatedAmount'}
                        label={`${
                            values.exchangeType === 'Send' ? 'Exact ' : ''
                        }Sending Amount - ${sourceCurrency}`}
                        required={true}
                        className="CurrencyAmountInput"
                        type="number"
                        inputProps={{ pattern: '[0-9]*' }}
                        inputMode="numeric"
                        disabled={values.exchangeType !== 'Send'}
                        key={values.exchangeType}
                    />
                </div>
            </div>
            <div className="ExchangeRate">
                <Button
                    title="Switch selected input"
                    type="button"
                    className="Btn ExactToggle"
                    onClick={switchExchangeType}
                >
                    <ExchangeSvg />
                    {`${values.exchangeType} Exact`}
                </Button>
                <div style={{ marginLeft: 5, width: 60 }}>Indicative Exchange Rate:</div>
                {payeeDetails && (
                    <div className="Rates">
                        <p>
                            {sourceCurrency} 1 <span className="c1">{'>'}</span>
                            <span className="c2">{'>'}</span>
                            <span className="c3">{'>'}</span> {destinationCurrency} ~{' '}
                            {roundToPrecision(payeeDetails.buyPrice, 6)}
                        </p>
                        <p>
                            {destinationCurrency} 1 <span className="c1">{'>'}</span>
                            <span className="c2">{'>'}</span>
                            <span className="c3">{'>'}</span> {sourceCurrency} ~{' '}
                            {roundToPrecision(1 / payeeDetails.buyPrice, 6)}
                        </p>
                    </div>
                )}
            </div>

            <div className={'InputSection' + (values.exchangeType === 'Receive' ? ' Exact' : '')}>
                <div className="CurrencyInputWrapper">
                    <div className="CurrencyLabel" style={{ top: '37px' }}>
                        {destinationCurrency}
                    </div>
                    <FormTextField
                        field={
                            values.exchangeType === 'Send'
                                ? 'estimatedDestinationAmount'
                                : 'destinationAmount'
                        }
                        label={`${
                            values.exchangeType === 'Receive' ? 'Exact ' : ''
                        }Recipient Amount - ${destinationCurrency}`}
                        required={true}
                        className="CurrencyAmountInput"
                        type="number"
                        inputProps={{ pattern: '[0-9]*' }}
                        inputMode="numeric"
                        disabled={values.exchangeType !== 'Receive'}
                        key={values.exchangeType}
                    />
                </div>
            </div>
        </div>
    );
};
