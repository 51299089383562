import { useCallback, useState } from 'react';

type PropsItemNetworkUser = {
    networkUser: NetworkUser;
    handleUSerSelection: (event: React.MouseEvent<HTMLDivElement>) => void;
    selectedUserID: string | undefined;
};
export type NetworkUser = { id: string; title: string };
export const ItemNetworkUser = ({
    networkUser,
    handleUSerSelection,
    selectedUserID,
}: PropsItemNetworkUser) => {
    return (
        <div className="list-item-net-users" onClick={handleUSerSelection} id={networkUser.id}>
            <img
                src={'\\assets\\ibanera\\Icon_Form_01.png'}
                alt="icon"
                className="item-icon-net-users"
            />

            <span className="listitem-title-net-users">{networkUser.title}</span>
            {selectedUserID == networkUser.id && (
                <img
                    src={'\\assets\\ibanera\\Icon_Tick.png'}
                    alt="icon"
                    className="item-icon-net-users"
                />
            )}
        </div>
    );
};
