import React from 'react';
import { css, useTheme } from '@emotion/react';

export const TableFilterButton = ({
    onSelect,
    clear,
    isSelected,
    label,
    icon,
}: {
    onSelect: () => void;
    clear: () => void;
    isSelected: boolean;
    label: React.ReactNode;
    icon?: React.ReactNode;
}) => {
    const theme = useTheme();
    const buttonCSS = isSelected
        ? css`
              background: ${theme.colors.second};
              border: 1px solid ${theme.colors.second};
              color: #fff;
              .TableButtonIcon {
                  fill: #fff;
              }
          `
        : css`
              .TableButtonIcon {
                  fill: #d5d5d5;
              }
          `;
    return (
        <button
            css={buttonCSS}
            className={`Btn TableFilterBtn ${isSelected ? 'Selected' : ''}`}
            onClick={isSelected ? clear : onSelect}
            type="button"
        >
            {icon}
            {label}
        </button>
    );
};
