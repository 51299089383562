import FormTextField from 'components/form/FormTextField';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import Button from 'components/button/Button';
import { Form, FormikProps, useFormikContext } from 'formik';
import { FormFieldWrapper } from 'helpers/formFieldWrapper';
import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { TradeCrypto, OrderDetails, TransferType } from './Crypto';
import { useSelector } from 'react-redux';
import { CalculatedPriceField, FiatToggle } from 'pages/quickCoin/QuickCoinFormComponents';
import { PriceInfo } from 'reducers/cryptoPrices';
import { RadioButtons } from 'components/radiobuttons/radiobuttons';
import { Link } from '@reach/router';
import { selectComponentResources } from 'reducers/componentResources';
import { useBasePath } from 'helpers/useBasePath';
import { AssetCodeFormatter } from 'components/AssetCodeFormatter';
import { ApiResponse, useFetch } from '../../api';
import { endpoints } from '../../endpoints.config';
import { RadioIdButtons } from '../../components/radiobuttons/RadioIdButtons';
import { createFeeOptions, OtherPurpose } from '../account/Payees/MakePayment/AmountPurpose';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';

type BuyCryptoFormProps = {
    assetCode: string;
    currency: string | null;
    onSwitchAccount: (formikProps: FormikProps<TradeCrypto>, id: number) => void;
    action: 'buy' | 'sell';
    setOrderDetails: (d: OrderDetails) => void;
    accounts: SelectOption[];
    payees: Array<SelectOption & { allowedTransferTypes: TransferType[] }>;
    onSwitchPayee: (formikProps: FormikProps<TradeCrypto>, id: number) => void;
    rules: PriceInfo | null;
    formikProps: FormikProps<TradeCrypto>;
    errorMessage: string;
    setErrorMessage?: () => void;
    purposes: SelectOption[] | null;
};

type FeeDetails = {
    results:
        | {
              transferType: string;
              feeOptions: {
                  bankFeeSplitType: string;
                  bankFeeSplitTypesId: number;
                  minFeeAmount: number;
                  maxFeeAmount: number | null;
                  feePercent: number;
                  fixedAdditionalFee: number;
              }[];
          }[]
        | null;
};

type V2Response = {
    amount: number;
    exchangePrice: number;
    totalPrice: number;
    networkFee: number;
    gasStationFee: number;
    commissionFee: number;
};

const BuyCryptoForm: React.FC<BuyCryptoFormProps> = ({
    assetCode,
    currency,
    action,
    setOrderDetails,
    accounts,
    formikProps,
    errorMessage,
    onSwitchAccount,
    payees,
    onSwitchPayee,
    rules,
    purposes,
}) => {
    const { setFieldValue, values } = useFormikContext<TradeCrypto>();

    const isFiat = values.quantityType === 'Price';
    const isBuyAction = action === 'buy';

    const { data: feeDetails, loading: loadingFeeOptions } = useFetch<ApiResponse<FeeDetails>>(
        endpoints.cryptosmodule.feeDetails,
        {
            params: { payeesId: values.account },
        },
        undefined,
        !values.account || isBuyAction
    );

    const { data } = useFetch<ApiResponse<V2Response>>(
        endpoints.pricesModule.getPricev2,
        {
            params: {
                asset: values.asset,
                pairedAsset: values.currency,
                assetExchangeType: 'Sell',
                amount: 100,
                transferType: values.transferType,
                bankFeeSplitType: values.feeId,
            },
        },
        undefined,
        !values.transferType || loadingFeeOptions
    );
    const networkFee = data?.details.networkFee ?? 0;

    useEffect(() => {
        // reset account when switching between buy and sell (may not need this if we end up not using the same account value for purchase accounts and payees)
        setFieldValue('account', 0);
        setFieldValue('transferType', 0);
        setFieldValue('feeId', undefined);
    }, [action, setFieldValue]);

    const basePath = useBasePath();
    const { customerMenuLinks } = useSelector(selectComponentResources);
    const managePayeesUrl = useMemo(() => {
        let relativePath = '';
        // use some since it'll finish early if we find the right item
        customerMenuLinks?.some((link) =>
            link.accounts?.[0]?.childElements?.some((element) => {
                if (element.resourceKey === 'key_access_elements_Accounts_Payees') {
                    relativePath = element.path;
                    return true;
                }
                return false;
            })
        );
        return `${basePath}/${relativePath}`;
    }, [customerMenuLinks, basePath]);

    const selectedTransferType = (feeDetails?.details.results ?? []).find(
        (fee) => fee.transferType === formikProps.values.transferType
    );
    const feeOptions = createFeeOptions(selectedTransferType?.feeOptions ?? []);
    const selectedFee = (selectedTransferType?.feeOptions ?? []).find(
        (fee) => fee.bankFeeSplitType === values.feeId
    );
    const maxCalcFee = selectedFee
        ? (values.amount || 0) * (selectedFee.feePercent / 100) > selectedFee.minFeeAmount
            ? (values.amount || 0) * (selectedFee.feePercent / 100)
            : selectedFee.minFeeAmount
        : 0;
    const maxFeeLimit = selectedFee?.maxFeeAmount || Number.MAX_VALUE;
    const upperFee = selectedFee
        ? maxCalcFee > maxFeeLimit
            ? maxFeeLimit
            : maxCalcFee
        : maxCalcFee;
    const additionalFee = selectedFee?.fixedAdditionalFee || 0;
    const cryptoHint =
        upperFee && !isBuyAction
            ? `Fee: ${upperFee + networkFee + additionalFee} ${currency ?? 'USD'}`
            : undefined;

    useEffect(() => {
        formikProps.setFieldValue('feeId', selectedTransferType?.feeOptions[0].bankFeeSplitType);
        formikProps.setFieldValue(
            'minFee',
            selectedTransferType?.feeOptions[0].minFeeAmount ?? undefined
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formikProps.values.transferType, feeDetails]);

    const transferMethodOptions =
        payees?.find((payee) => payee.value === formikProps.values.account)?.allowedTransferTypes ??
        [];

    const otherPurposeCode = purposes?.find(
        (purpose) => purpose.label.toLowerCase() === 'other'
    )?.value;
    return (
        <Form className="FormBuilderForm">
            {isBuyAction ? (
                <FormFieldWrapper label="Account to purchase from" fieldName="account">
                    <Select
                        isSearchable={false}
                        options={accounts}
                        onChange={(e) => {
                            formikProps.setFieldValue('account', e?.value);
                            e?.value && onSwitchAccount(formikProps, e.value);
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        key="AccountDropdown"
                    />
                </FormFieldWrapper>
            ) : (
                <>
                    <FormFieldWrapper label="Destination Payee" fieldName="account">
                        {payees.length > 0 ? (
                            <Select
                                isSearchable={false}
                                options={payees}
                                onChange={(e) => {
                                    formikProps.setFieldValue('account', e?.value);
                                    e?.value && onSwitchPayee(formikProps, e.value);
                                }}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                key={'PayeeDropdown'}
                            />
                        ) : (
                            managePayeesUrl && (
                                <Link to={managePayeesUrl}>
                                    <Button>Add Payee</Button>
                                </Link>
                            )
                        )}
                    </FormFieldWrapper>
                    {!isBuyAction && transferMethodOptions.length > 0 && (
                        <RadioButtons
                            options={transferMethodOptions}
                            fieldname="transferType"
                            label="Payment Method"
                        />
                    )}
                    {!isBuyAction && feeOptions.length > 1 && (
                        <RadioIdButtons
                            className="FeeColumn"
                            loading={loadingFeeOptions}
                            label="Fee"
                            fieldname="feeId"
                            options={feeOptions}
                        />
                    )}
                    {!isBuyAction && values.isInternalPayee === false && (
                        <>
                            {purposes && purposes.length > 0 ? (
                                <WrappedFormSingleSelectField
                                    fieldName="purpose"
                                    label="Purpose"
                                    options={purposes}
                                />
                            ) : (
                                <FormTextField
                                    field="purpose"
                                    label={`Payment Purpose `}
                                    placeholder="E.g. Utility Bill"
                                />
                            )}
                            <OtherPurpose otherPurposeCode={otherPurposeCode} />
                        </>
                    )}
                </>
            )}
            <FiatToggle currencyCode={currency ?? ''} assetCode={assetCode} />
            <FormTextField
                type="number"
                autoComplete={false}
                step={isFiat ? 'any' : 1 / 10 ** (rules?.maxDecimalPrecision ?? 4)}
                field={isFiat ? 'price' : 'amount'}
                required={true}
                label={
                    isFiat ? (
                        <>
                            Target {isBuyAction ? 'Price' : 'Value'} in{' '}
                            <AssetCodeFormatter assetCode={currency ?? 'USD'} />
                        </>
                    ) : (
                        <>
                            Amount of <AssetCodeFormatter assetCode={assetCode} />
                        </>
                    )
                }
                key={isFiat ? 'price' : 'amount'}
                hint={
                    isFiat
                        ? rules?.maxPrice != null && rules.minPrice != null
                            ? `min: ${rules.minPrice}, max: ${rules.maxPrice}`
                            : undefined
                        : rules?.maxAmount != null && rules?.minAmount != null
                        ? `min: ${rules?.minAmount}, max: ${rules?.maxAmount}`
                        : cryptoHint
                }
            />
            <CalculatedPriceField
                label={
                    isFiat ? (
                        <>
                            Amount of <AssetCodeFormatter assetCode={assetCode} />
                        </>
                    ) : (
                        <>
                            {isBuyAction ? 'Price' : 'Value'} in{' '}
                            <AssetCodeFormatter assetCode={currency ?? 'USD'} />
                        </>
                    )
                }
                isFiat={isFiat}
                hint={
                    !isFiat
                        ? rules?.maxPrice != null && rules.minPrice != null
                            ? `min: ${rules.minPrice}, max: ${rules.maxPrice}`
                            : undefined
                        : rules?.maxAmount != null && rules?.minAmount != null
                        ? `min: ${rules?.minAmount}, max: ${rules?.maxAmount}`
                        : undefined
                }
                assetExchangeType={isBuyAction ? 'Buy' : 'Sell'}
                getAmountUrl={!isBuyAction ? endpoints.pricesModule.getAmountv2 : undefined}
                getPriceUrl={!isBuyAction ? endpoints.pricesModule.getPricev2 : undefined}
                priceFieldKey={!isBuyAction ? 'totalPrice' : undefined}
                enabled={!!formikProps.values.account} // the crypto component sets currency to null when it's
            />
            {errorMessage && <p className="ErrorLabel">{errorMessage}</p>}
            <Button type="submit" className="TradeSubmit" disabled={formikProps.isSubmitting}>
                Review order
            </Button>
        </Form>
    );
};

export { BuyCryptoForm };
