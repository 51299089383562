import Button from 'components/button/Button';
import { RadioButton } from 'components/radiobuttons/radiobuttons';
import { endpoints } from 'endpoints.config';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { CalculatedPriceField, FiatToggle } from './QuickCoinFormComponents';
import FormTextField from 'components/form/FormTextField';
import { AvailableOnOptions } from 'reducers/cryptoPrices';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import { useEffect, useState } from 'react';
import {
    basketValidationSchema,
    detailsValidationSchema,
    initialBasketValues,
    Rules,
    SaleDetails,
} from './model';
import { AddressLookup } from 'pages/account/Payees/MakePayment/AddressLookup/AddressLookup';
import { useFetchPayeeCountries } from 'pages/account/Payees/MakePayment/MakePayment';
import { FormDateField } from 'components/form/FormDateField';
import instance, { ApiResponse } from 'api';
import { convertLocaleDateToUtcDate } from 'helpers/convertLocaleDateToUtcDate';
import { DepositCrypto, DepositDetails } from './DepositCrypto';

type Props = {
    cryptoOptions: { label: string; value: string }[];
    prices: { [key: string]: { availableOn: AvailableOnOptions[] } };
    updateRules: (assetCode: string, currency: string) => void;
    rules: Rules | null;
    onChangeStep: () => void;
};
export const QuickCoinSell: React.FC<Props> = ({
    cryptoOptions,
    prices,
    updateRules,
    rules,
    onChangeStep,
}) => {
    const [saleStage, setSaleStage] = useState<'basket-stage' | 'details-stage' | 'payment-stage'>(
        'basket-stage'
    );
    const { availableCountries, errorCountries } = useFetchPayeeCountries();
    const [basket, setBasket] = useState<SaleDetails | null>(null);
    const [depositDetails, setDepositDetails] = useState<DepositDetails | null>(null);

    const handleSubmit = async (values: SaleDetails, formikHelpers: FormikHelpers<SaleDetails>) => {
        try {
            const { price, amount, asset, currency, socialSecurityNumber, dateOfBirth } = values;
            const res = await instance.post<ApiResponse<DepositDetails>>(
                endpoints.quickCryptoModule.submitSellDetails,
                {
                    currencyAmount: price,
                    assetAmount: amount,
                    currencyCode: currency,
                    assetCode: asset,
                    userInfo: {
                        taxNumber: socialSecurityNumber,
                        dateOfBirth: convertLocaleDateToUtcDate(dateOfBirth)?.toISOString(),
                    },
                }
            );
            if (res.data.status === '1') {
                setBasket(values);
                setSaleStage('payment-stage');
                setDepositDetails(res.data.details);
            }
        } catch (err) {
            console.error(err);
            formikHelpers.setSubmitting(false);
        }
    };

    return (
        <div className="FormPage HeightContent">
            <Formik<SaleDetails>
                initialValues={initialBasketValues}
                onSubmit={async (values, helpers) => {
                    saleStage === 'basket-stage'
                        ? (() => {
                              setSaleStage('details-stage');
                              onChangeStep();
                          })()
                        : await handleSubmit(values, helpers);
                }}
                validationSchema={
                    saleStage === 'basket-stage'
                        ? basketValidationSchema(rules)
                        : detailsValidationSchema
                }
                enableReinitialize
            >
                {({ values, setFieldTouched, errors, setFieldValue, isSubmitting }) => (
                    <Form className="QuickCoinForm">
                        {saleStage === 'basket-stage' && (
                            <>
                                <RuleUpdater updateRules={updateRules} />
                                <WrappedFormSingleSelectField<SaleDetails>
                                    fieldName={'asset'}
                                    options={cryptoOptions}
                                    label={'Crypto to sell'}
                                />
                                <WrappedFormSingleSelectField<SaleDetails>
                                    fieldName={'currency'}
                                    options={
                                        values.asset
                                            ? Object.keys(prices)
                                                  .filter(
                                                      (key) =>
                                                          key.split('/')[0] === values.asset &&
                                                          prices[key].availableOn?.includes(
                                                              AvailableOnOptions.QuickCoin
                                                          )
                                                  )
                                                  .map((key) => key.split('/')[1])
                                                  .map((key) => ({ label: key, value: key }))
                                            : []
                                    }
                                    key={values.currency} // lets us reset the dropdown when currency = ""
                                    label={`Currency to receive`}
                                />
                                <FiatToggle
                                    currencyCode={values.currency}
                                    assetCode={values.asset}
                                />
                                <FormTextField
                                    type="number"
                                    onBlur={() =>
                                        setFieldTouched(
                                            values.quantityType === 'Price' ? 'amount' : 'price',
                                            true
                                        )
                                    }
                                    step={
                                        values.quantityType === 'Price'
                                            ? 'any'
                                            : 1 / 10 ** (rules?.maxDecimalPrecision ?? 4)
                                    }
                                    autoComplete={false}
                                    label={
                                        values.quantityType === 'Price'
                                            ? `Target value${
                                                  values.currency ? ` in ${values.currency}` : ''
                                              }`
                                            : `Amount${values.asset ? ` of ${values.asset}` : ''}`
                                    }
                                    hint={
                                        values.quantityType === 'Price'
                                            ? rules?.maxPrice != null && rules.minPrice != null
                                                ? `min: ${rules?.minPrice}, max: ${rules.maxPrice}`
                                                : undefined
                                            : rules?.maxAmount != null && rules?.minAmount != null
                                            ? `min: ${rules?.minAmount}, max: ${rules?.maxAmount}`
                                            : undefined
                                    }
                                    field={values.quantityType === 'Price' ? 'price' : 'amount'}
                                    required={true}
                                    key={
                                        values.quantityType === 'Price'
                                            ? 'priceField'
                                            : 'amountField'
                                    }
                                />
                                <CalculatedPriceField
                                    label={
                                        values.quantityType === 'Price'
                                            ? `Amount${values.asset ? ` of ${values.asset}` : ''}`
                                            : `Value${
                                                  values.currency ? ` in ${values.currency}` : ''
                                              }`
                                    }
                                    isFiat={values.quantityType === 'Price'}
                                    hint={
                                        values.quantityType === 'Amount'
                                            ? rules?.maxPrice != null && rules.minPrice != null
                                                ? `min: ${rules?.minPrice}, max: ${rules.maxPrice}`
                                                : undefined
                                            : rules?.maxAmount != null && rules?.minAmount != null
                                            ? `min: ${rules?.minAmount}, max: ${rules?.maxAmount}`
                                            : undefined
                                    }
                                    getAmountUrl={endpoints.quickCryptoModule.getAmount}
                                    getPriceUrl={endpoints.quickCryptoModule.getPrice}
                                    assetExchangeType={'Sell'}
                                />
                                <div
                                    className={`TermsConditions ${
                                        values.bAcceptedTermsAndConditions !== true
                                            ? 'NotAcceptedErrLabel'
                                            : ''
                                    }`}
                                >
                                    <div className={'RadioButtons'}>
                                        <RadioButton
                                            label={
                                                "By clicking the Sell button below, you agree to Ibanera's <a href='https://www.ibanera.com/legal-agreements/usa-general-terms-conditions/'>Terms & Conditions</a>, <a href='https://www.ibanera.com/legal-agreements/digital-asset-custody-terms-conditions/'>Digital Asset Custody Terms & Conditions</a> and <a href= 'https://www.ibanera.com/privacy-policy/'>Privacy Policy</a>"
                                            }
                                            selected={values.bAcceptedTermsAndConditions}
                                            onClick={() => {
                                                setFieldValue(
                                                    'bAcceptedTermsAndConditions',
                                                    !values.bAcceptedTermsAndConditions
                                                );
                                            }}
                                            bDangerousHtml={true}
                                            classname="terms-conditions"
                                        />
                                    </div>
                                    {errors.bAcceptedTermsAndConditions && (
                                        <div className="ErrorLabel">
                                            {errors.bAcceptedTermsAndConditions}
                                        </div>
                                    )}
                                </div>
                                <Button type="submit" variety="full" disabled={isSubmitting}>
                                    {`Sell ${values.asset}`}
                                </Button>
                            </>
                        )}
                        {saleStage === 'details-stage' && (
                            <>
                                <h3>Cardholder details</h3>
                                {/* <AddressLookup
                                    fieldnamePrefix={'deliveryAddress'}
                                    countryOptions={availableCountries}
                                    defaultShowLookup={
                                        values.deliveryAddress &&
                                        values.deliveryAddress.addressLine1
                                            ? false
                                            : true
                                    }
                                    fieldNamesMap={{
                                        addressLine1: 'addressLine1',
                                        addressLine2: 'addressLine2',
                                        townCity: 'townCity',
                                        state: 'state',
                                        postCode: 'postcode',
                                        addressCountryCode: 'countryCode',
                                    }}
                                    addressLabel="Delivery Address"
                                /> */}
                                <FormDateField
                                    field={`dateOfBirth`}
                                    sideBySide={false}
                                    formSideBySide={false}
                                    label="Date of Birth"
                                    datePickerProps={{
                                        maxDate: new Date(),
                                        showYearDropdown: true,
                                        scrollableYearDropdown: true,
                                        yearDropdownItemNumber: 80,
                                    }}
                                />
                                <FormTextField
                                    field={`socialSecurityNumber`}
                                    label="Social Security Number"
                                    required={true}
                                />
                                <Button type="submit" variety="full" disabled={isSubmitting}>
                                    Submit Details
                                </Button>
                            </>
                        )}
                        {saleStage === 'payment-stage' && depositDetails && (
                            <DepositCrypto
                                depositAddress={depositDetails.depositAddress.address}
                                depositQrCode={depositDetails.depositAddress.qrCode}
                                assetAmount={depositDetails.quoteReference.depositAssetAmount}
                                quotedCurrencyAmount={
                                    depositDetails.quoteReference.expectedCurrencyAmount
                                }
                                currentExchangeRate={
                                    depositDetails.quoteReference.expectedExchangeRate
                                }
                                quoteReference={depositDetails.quoteReference.quoteReference}
                                assetCode={depositDetails.quoteReference.assetCode}
                                currencyCode={depositDetails.quoteReference.currencyCode}
                                depositDeadline={
                                    new Date(depositDetails.quoteReference.depositDeadline)
                                }
                            />
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

const RuleUpdater = ({
    updateRules,
}: {
    updateRules: (assetCode: string, currency: string) => void;
}) => {
    const { values } = useFormikContext<SaleDetails>();
    useEffect(() => {
        updateRules(values.asset, values.currency);
    }, [values.asset, values.currency, updateRules]);
    return null;
};
