import Button from 'components/button/Button';
import {
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { localizedFormatDate } from 'helpers/language';
import { Toast } from 'helpers/toast';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export type DepositDetails = {
    depositAddress: {
        address: string;
        qrCode: string;
    };
    quoteReference: {
        depositDeadline: string;
        quoteReference: string;
        depositAssetAmount: number;
        expectedCurrencyAmount: number;
        expectedExchangeRate: number;
        currencyCode: string;
        assetCode: string;
        topUpCardsId: number;
    };
};

type Props = {
    depositAddress: string;
    depositQrCode: string;
    assetAmount: number;
    assetCode: string;
    currencyCode: string;
    quotedCurrencyAmount: number;
    currentExchangeRate: number;
    quoteReference: string;
    depositDeadline?: Date;
};

export const DepositCrypto = ({
    depositAddress,
    depositQrCode,
    assetAmount,
    quotedCurrencyAmount,
    currentExchangeRate,
    quoteReference,
    assetCode,
    currencyCode,
    depositDeadline: quoteDeadline,
}: Props) => {
    const [hasSucceeded, setHasSucceeded] = useState(false);
    const handleCopy = () => {
        const addressToCopy = depositAddress;
        if (addressToCopy) {
            Toast.openSuccessToast('Copied');
            window.navigator.clipboard.writeText(addressToCopy);
        }
    };
    const uiUpdate = useSelector(selectUIUpdate);
    useEffect(() => {
        if (
            uiUpdate?.pushType === NotificationIdentifier.QUICKSELL_DEPOSIT_SUCCEEDED &&
            uiUpdate.data.quoteReference === quoteReference
        )
            setHasSucceeded(true);
    }, [quoteReference, uiUpdate]);
    if (hasSucceeded)
        return (
            <div>
                <h3>Deposit Succeeded</h3>
                <p>
                    Your deposit has been recieved, please check your email for the details on your
                    new card
                </p>
            </div>
        );
    return (
        <div className="DepositCrypto">
            <h3>
                Deposit {assetCode} ({quoteReference})
            </h3>
            <p>
                Please transfer{' '}
                <strong>
                    {assetAmount} {assetCode}
                </strong>{' '}
                to the below address
                {quoteDeadline ? ` by ${localizedFormatDate(quoteDeadline, undefined, 'Pp')}` : ''}.
            </p>
            <p>
                At the current market rate of {currentExchangeRate} {currencyCode} / {assetCode},
                you will receive{' '}
                <strong>
                    approximately {quotedCurrencyAmount} {currencyCode}.
                </strong>
                <br></br>Please note that the conversion will only take place once your deposit has
                completed, and will be subject to actual market rates at the time of completion.
            </p>
            <img
                src={`data:image/jpeg;base64, ${depositQrCode}`}
                alt="Deposit QR code"
                className="DepositQrCode"
            />
            <p>{depositAddress}</p>
            <Button className="CopyButton" priority="secondary" type="button" onClick={handleCopy}>
                Copy
            </Button>
        </div>
    );
};
