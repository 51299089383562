import { ThreeBounce } from 'better-react-spinkit';
import Button from 'components/button/Button';
import { Modal } from 'components/modal/Modal';
import { useCallback, useEffect, useState } from 'react';
import './../ConnectionsPage.scss';
import { debounce } from 'lodash';
import searchIcon from 'assets/Icon_TEMP_Search.png';
import { endpoints } from 'endpoints.config';
import api, { ApiResponse } from 'api';
import { ItemNetworkUser, NetworkUser } from '../items/ItemNetworkUser';
import { Spinner } from 'components/spinner/Spinner';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { GeneralErrorMessages } from 'helpers/categoryHelpers/processErrors';
type PropsModalAddConnection = { closeModal: (status?:boolean) => void };

export const ModalAddConnection = ({ closeModal }: PropsModalAddConnection) => {
    const [loading, setLoading] = useState(false);
    const [networkUsers, setNetworkUsers] = useState<NetworkUser[]>([]);
    const [selectedUserID, setSeletectedUserID] = useState<string | undefined>();
    const [isAddingConnection, setAddingConnection] = useState(false);
    const handleChange = useCallback(
        debounce(async (value) => {
            try {
                setLoading(true);
                const response = await api.get<ApiResponse<NetworkUser[]>>(
                    `${endpoints.networkmodule.sarchnetworkuser}?search=${value.trim()}`
                );

                if (response.data.details !== null) {
                    setNetworkUsers(response.data.details);
                } else {
                    setNetworkUsers([]);
                }
            } catch (error) {
                setNetworkUsers([]);
            } finally {
                setLoading(false);
            }
        }, 1000),
        []
    );
    const handleUSerSelection = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        setSeletectedUserID(event.currentTarget.id);
    }, []);
    const handleInputChange = useCallback(
        (e: any) => {
            const value = e.target.value;
            handleChange(value);
        },
        [handleChange]
    );
    const handleAddConnection = useCallback(
        async (e: any) => {
            setAddingConnection(true);
            try {
                const payload = {
                    id: selectedUserID,
                    type: "SEND"
                };
                const res = await api.post<ApiResponse>(
                    endpoints.networkmodule.connectionRequest,
                    payload
                );
                if (res.data?.status === '1') {
                    Toast.openToastMessage('Request sent!', ToastMessageReason.VALID);
                    closeModal(true)
                } else {
                    Toast.openToastMessage(GeneralErrorMessages.Generic, ToastMessageReason.ERROR);
                }

                setAddingConnection(false);
            } catch (ex: any) {
                if (ex?.response?.data?.errors?.length > 0) {
                    const key = ex.response.data.errors[0]
                        .messageCode as keyof typeof GeneralErrorMessages;
                    Toast.openToastMessage(
                        GeneralErrorMessages[key] ?? GeneralErrorMessages.Generic,
                        ToastMessageReason.ERROR
                    );
                }
                setAddingConnection(false);
            }
        },
        [selectedUserID]
    );

    useEffect(() => {
        handleChange('a');
    }, [handleChange]);

    return (
        <Modal
            className="add-connection-modal"
            title="Add network connection"
            onCloseModal={closeModal}
        >
            <div className="container">
                <div className="connection-message">Choose a connection to add.</div>
                <div className="search-container">
                    <img src={searchIcon} alt="Search" className="search-icon" />
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Search by name or id"
                        // value={searchTerm}
                        onChange={handleInputChange}
                    />
                    {loading && (
                        <div className="LoadingSearchSpinner">
                            <Spinner />;
                        </div>
                    )}
                </div>
                <div className="container-net-users">
                    {networkUsers != null && networkUsers.length > 0 ? (
                        networkUsers.map((item: NetworkUser) => (
                            <ItemNetworkUser
                                networkUser={item}
                                handleUSerSelection={handleUSerSelection}
                                selectedUserID={selectedUserID}
                            />
                        ))
                    ) : (
                        <div className="no-results-message">No results found</div>
                    )}
                </div>
                <div className="Buttons">
                    <Button onClick={()=>closeModal()} priority="secondary" color="grey">
                        Close
                    </Button>
                    <Button
                        color="third"
                        disabled={selectedUserID === undefined || isAddingConnection}
                        onClick={handleAddConnection}
                    >
                        {isAddingConnection ? (
                            <ThreeBounce size={10} color={'#ffffff'} />
                        ) : (
                            'Add Connection'
                        )}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
