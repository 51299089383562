import { useTheme } from '@emotion/react';
import Icon_Close from 'assets/Cat-Entity/Icon_Form_Search_02.png';
import EmptyPanel from 'assets/ui-update/empty-side-01.svg';
import { FoldingCube } from 'better-react-spinkit';
import { useDispatch } from 'react-redux';
import { GeneralError } from '../../../components/GeneralError/GeneralError';
import Button from '../../../components/button/Button';
import { ModalTypes, closeModal, openModal } from '../../../reducers/modal';
import { updatePayeeSubpage } from '../../../reducers/payee';
import { MoveMoneySubPages, SavedPayee } from '../MoveMoney';
import styles from './PayeePanel.module.scss';
import { endpoints } from '../../../endpoints.config';
import { ApiResponse, useFetch } from '../../../api';
import { PayeeType, TransmitterType } from '../Payees/EditPayee';
import { useIsFinancialInstitution } from '../Payees/helpers';
import { Collapse } from '../../../components/Collapse/Collapse';
import { format } from 'date-fns';

export type PayeeInfo = {
    accountName: string;
    accountType: 'Card' | string;
    achAccountNumber: string;
    achRoutingNumber: string;
    countryName: string;
    iban: string;
    id: number;
    name: string;
    swiftNumber: string;
    intermediaryBic?: string;
    type: PayeeType;
    bSuppressed: boolean; // awaiting approval - can't make payments while true
    cardLast4Digits?: string;
    cardCompany?: string;

    // transmitter info
    transmitterFirstPartyTransfer: boolean;
    transmitterType: TransmitterType;
    transmitterName: string;
    transmitterDateOfBirth?: string;
    transmitterAccountNumber: string;
    transmitterAddressLine1: string;
    transmitterAddressLine2: string;
    transmitterTownCity: string;
    transmitterState: string;
    transmitterPostcode: string;
    transmitterCountryCode: string;
};

type Props = {
    selectedRowData?: SavedPayee;
    disablePayment?: boolean;
    closePanel: () => void;
};

export const PayeePanel: React.FC<Props> = ({
    selectedRowData: rowData,
    closePanel,
    disablePayment,
}) => {
    const dispatch = useDispatch();
    const bFinancialInstitution = useIsFinancialInstitution();

    const { colors } = useTheme();

    const { data, loading, error } = useFetch<ApiResponse<PayeeInfo>>(
        endpoints.accounts.payeeDetails,
        {
            params: { id: rowData?.payees__Id + '' },
        },
        undefined,
        !rowData
    );

    if (!rowData) return <EmptySelection />;

    const handleMakePayment = () => {
        dispatch(
            updatePayeeSubpage({
                page: MoveMoneySubPages.SAVED_PAYEE,
                payee: rowData,
            })
        );
        closePanel();
    };
    const handleEditPayee = () => {
        dispatch(
            updatePayeeSubpage({
                page: MoveMoneySubPages.EDIT_PAYEE,
                payee: rowData,
            })
        );
        dispatch(closeModal());
        closePanel();
    };

    const handleRemovePayee = () => {
        closePanel();
        dispatch(
            openModal({
                modalType: ModalTypes.REMOVE_PAYEE,
                data: rowData,
            })
        );
    };

    const isCardPayee = data?.details.accountType === 'Card';
    return (
        <div key={rowData?.payees__Id} className={styles.Panel}>
            <div className={styles.Header}>
                <h2 className={styles.Title}>{rowData.payees__Name}</h2>
                <img
                    onClick={closePanel}
                    className={styles.CloseIcon}
                    src={Icon_Close}
                    alt="Close modal"
                />
            </div>

            {loading && (
                <div className={styles.Loading}>
                    <FoldingCube color={colors.first} size={80} />
                </div>
            )}
            {error && !loading && (
                <div className={styles.Error}>
                    <GeneralError message="Unable to load payee details" />
                </div>
            )}
            {data && !loading && (
                <div className={styles.Details}>
                    {data.details.bSuppressed && (
                        <TransactionInfoItem
                            label={'Status'}
                            value="Pending approval"
                            valueClassname={styles.ApprovalPending}
                        />
                    )}
                    <TransactionInfoItem
                        label={'Account type'}
                        value={
                            isCardPayee ? data.details.accountType : (data.details.type as string)
                        }
                    />

                    <TransactionInfoItem
                        label={'Account country'}
                        value={data.details.countryName}
                    />
                    <TransactionInfoItem label={'Payee name'} value={data.details.name} />
                    {!isCardPayee && (
                        <TransactionInfoItem
                            label={'Account name'}
                            value={data.details.accountName}
                        />
                    )}
                    {isCardPayee && (
                        <>
                            {data.details.cardLast4Digits && (
                                <TransactionInfoItem
                                    label={'Card number'}
                                    value={`XXXX XXXX XXXX ${data.details.cardLast4Digits}`}
                                />
                            )}
                            {data.details.cardCompany && (
                                <TransactionInfoItem
                                    label={'Card provider'}
                                    value={data.details.cardCompany}
                                />
                            )}
                        </>
                    )}
                    {data.details.achAccountNumber && (
                        <TransactionInfoItem
                            label={'Account number'}
                            value={data.details.achAccountNumber}
                        />
                    )}
                    {data.details.achRoutingNumber && (
                        <TransactionInfoItem
                            label={'Routing number'}
                            value={data.details.achRoutingNumber}
                        />
                    )}
                    {data.details.iban && (
                        <TransactionInfoItem label={'IBAN number'} value={data.details.iban} />
                    )}
                    {data.details.swiftNumber && (
                        <TransactionInfoItem
                            label={'Swift number'}
                            value={data.details.swiftNumber}
                        />
                    )}
                    {data.details.intermediaryBic && (
                        <TransactionInfoItem
                            label={'Intermediary Swift'}
                            value={data.details.intermediaryBic}
                        />
                    )}
                    {bFinancialInstitution && (
                        <>
                            <TransactionInfoItem
                                label="First Party Transfer"
                                value={data.details.transmitterFirstPartyTransfer ? 'Yes' : 'No'}
                            />
                            {!data.details.transmitterFirstPartyTransfer && (
                                <Collapse header="Transmitter Info">
                                    <TransactionInfoItem
                                        label="Type"
                                        value={data.details.transmitterType}
                                    />
                                    <TransactionInfoItem
                                        label="Name"
                                        value={data.details.transmitterName}
                                    />
                                    {data.details.transmitterType === TransmitterType.Personal && (
                                        <TransactionInfoItem
                                            label="Date Of Birth"
                                            value={
                                                data.details.transmitterDateOfBirth &&
                                                Date.parse(data.details.transmitterDateOfBirth)
                                                    ? format(
                                                          new Date(
                                                              data.details.transmitterDateOfBirth
                                                          ),
                                                          'dd/MM/yyyy'
                                                      )
                                                    : data.details.transmitterDateOfBirth
                                            }
                                        />
                                    )}
                                    {data.details.transmitterAccountNumber && (
                                        <TransactionInfoItem
                                            label="Account Number"
                                            value={data.details.transmitterAccountNumber}
                                        />
                                    )}
                                    <TransactionInfoItem
                                        label="Address Line 1"
                                        value={data.details.transmitterAddressLine1}
                                    />
                                    {data.details.transmitterAddressLine2 && (
                                        <TransactionInfoItem
                                            label="Address Line 2"
                                            value={data.details.transmitterAddressLine2}
                                        />
                                    )}
                                    <TransactionInfoItem
                                        label="Town / City"
                                        value={data.details.transmitterTownCity}
                                    />
                                    <TransactionInfoItem
                                        label="State"
                                        value={data.details.transmitterState}
                                    />
                                    <TransactionInfoItem
                                        label="ZIP code"
                                        value={data.details.transmitterPostcode}
                                    />
                                    <TransactionInfoItem
                                        label="Country"
                                        value={data.details.transmitterCountryCode}
                                    />
                                </Collapse>
                            )}
                        </>
                    )}
                </div>
            )}
            {data && !loading && (
                <div className={styles.Buttons}>
                    {!disablePayment && !data?.details.bSuppressed && (
                        <Button onClick={handleMakePayment} color="third">
                            Make payment
                        </Button>
                    )}
                    {!isCardPayee && (
                        <Button onClick={handleEditPayee} color="second" priority="secondary">
                            Edit payee details
                        </Button>
                    )}
                    <Button onClick={handleRemovePayee} color="grey">
                        Remove payee
                    </Button>
                </div>
            )}
        </div>
    );
};

type InfoItemProps = {
    label: string;
    value?: string;
    valueClassname?: string;
};

const TransactionInfoItem: React.FC<InfoItemProps> = ({ label, value, valueClassname = '' }) => (
    <div className={styles.InfoItem}>
        <p className={styles.Label}>{label}:</p>
        <p className={`${styles.Value} ${valueClassname}`}>{value ?? '-'}</p>
    </div>
);

const EmptySelection = () => (
    <div className={`${styles.Panel} ${styles.Empty}`}>
        <img alt="Empty" src={EmptyPanel} />
        <p>You have not selected a payee to view information, select one.</p>
    </div>
);
