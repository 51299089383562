import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { TFAField } from 'components/form/TFAField';
import { Modal } from 'components/modal/Modal';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'endpoints.config';
import { getErrorMessage } from 'errors';
import { Form, Formik, FormikHelpers } from 'formik';
import { roundToPrecision } from 'helpers/calculateToPrecision';
import { useTFAField } from 'helpers/useTFAField';
import {
    accountAsMergedAccount,
    CryptoExchangeItem,
    ExchangeAccountDetails,
    ExchangeDetails,
    GetExchangeDetailsResponse,
    MergedToAccountDetails,
    payeeAsMergedAccount,
} from 'pages/cryptoV2/models';
import { TFAType } from 'pages/register/models';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalTypes, selectModalState } from 'reducers/modal';
import * as Yup from 'yup';

import { OrderSummary } from './CryptoExchangeModal/OrderSummary';
import { FromTo } from './CryptoExchangeModal/FromTo';
import { ExchangeDetail } from './CryptoExchangeModal/ExchangeDetail';
import { OrderComplete } from './CryptoExchangeModal/OrderComplete';
import { buildStepOneValidationSchema } from './CryptoExchangeModal/helpers';
import { Toast } from '../../helpers/toast';
import { TransferType } from 'pages/crypto/Crypto';

export type CryptoExchangeModalData = CryptoExchangeItem & {
    amount?: number | undefined;
    exchangeType: 'Buy' | 'Sell';
};

type GenericValues = {
    comment: string;
    exchangeType: 'Buy' | 'Sell';
    tfaType: TFAType;
    tfaCode: string;
} & CryptoExchangeItem;

export enum QuantityType {
    'Source' = 'Source',
    'Destination' = 'Destination',
}
export type FormValues = {
    quantityType: QuantityType;
    sourceAmount: number | null;
    destinationAmount: number | null;
    estimatedSourceAmount: number | null;
    estimatedDestinationAmount: number | null;

    fromAccountId: number | null;
    toMergedAccountGenericId: string | null;
    transferType: TransferType | null;
    isInternalPayee: boolean | null;
    purpose: string | null;
    purposeOther: string | null;
} & GenericValues;

export type ReviewedDetails = ApiReviewResponse & {
    comment: string;
    exchangeType: 'Buy' | 'Sell';
    quantityType: QuantityType;
    assetPairSwapped: boolean;
    pairedAsset: string;
    asset: string;
    buyPrice: number;
    sellPrice: number;

    // Needed for the final post
    sourceAmount: number | null;
    destinationAmount: number | null;
    transferType: TransferType | null;
};
type ApiReviewResponse = {
    sourceAccountId: number;
    destinationAccountId: number | null;
    destinationPayeeId: number | null;
    pairedAssetsId: number;
    assetsId: number;
    amount: number;
    price: number;
    networkFee: number;
    commissionFee: number;
    totalPrice: number;
};

export type ExecuteExchangeDetails = {
    bApproved: boolean;
    sourceAmount: number;
    sourceAccountId: number;
    sourceAccountName: string;
    destinationAmount: number;
    destinationAccountId: number;
    destinationAccountName: string;
    reference: string;
    buyPrice: number;
    sellPrice: number;
};

const validationSchemaStep2 = Yup.object({});

export const CryptoExchangeModal = () => {
    const modalState = useSelector(selectModalState);

    const [exchangeDetails, setExchangeDetails] = useState<ExchangeDetails | null>(null);
    const [orderDetails, setOrderDetails] = useState<ReviewedDetails | null>(null);
    const [executedDetails, setExecutedDetails] = useState<ExecuteExchangeDetails | null>(null);

    const [tfaType, toggleTfaType] = useTFAField(!!orderDetails);

    const [executeErrorMessage, setExecuteErrorMessage] = useState('');
    const [reviewErrorMessage, setReviewErrorMessage] = useState('');

    const [retryInitialFetch, setRetryInitialFetch] = useState(false);
    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        if (modalState.modalType !== ModalTypes.CRYPTO_EXCHANGE) return;
        instance
            .get<ApiResponse<GetExchangeDetailsResponse>>(
                endpoints.cryptoExchange.getExchangeDetails,
                {
                    params: {
                        pairedAssetsId: modalState.data.pairedAssetsId,
                        assetsId: modalState.data.assetsId,
                        exchangeType: modalState.data.exchangeType,
                    },
                }
            )
            .then((res) =>
                setExchangeDetails({
                    pairedAsset: modalState.data.pairedAsset,
                    asset: modalState.data.asset,
                    sourceAsset: res.data.details.sourceAsset,
                    destinationAsset: res.data.details.destinationAsset,
                    fromAccounts: res.data.details.fromAccounts,
                    mergedToAccounts: res.data.details.availablePayees?.length
                        ? res.data.details.availablePayees.map((payee) =>
                              payeeAsMergedAccount(payee)
                          )
                        : res.data.details.toAccounts?.map((acc) => accountAsMergedAccount(acc)),
                })
            )
            .catch((err) => {
                let msg = 'Failed to load exchange details';
                if (
                    isAxiosErrorHandled(err) &&
                    err.response?.data.errors &&
                    err.response.data.errors.length > 0 &&
                    err.response.data.errors[0].messageCode === 'PayeeMustExist'
                )
                    msg = 'You must have at least one Payee setup on your fiat funding account';

                setError(msg);
            });
    }, [modalState, retryInitialFetch]);

    const validationSchemaStep1 = useMemo(
        () => buildStepOneValidationSchema(exchangeDetails),
        [exchangeDetails]
    );

    if (modalState.modalType !== ModalTypes.CRYPTO_EXCHANGE) return null;
    const { data } = modalState;

    const assetPairSwapped = data.swapAssetPair;

    const handleSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        const selectedToAccountOrPayee = exchangeDetails?.mergedToAccounts?.find(
            (acc) => acc.genericId === values.toMergedAccountGenericId
        );
        if (!orderDetails) {
            //call api to review order and set order details

            const payload = {
                assetsId: values.assetsId,
                pairedAssetsId: values.pairedAssetsId,
                sourceAccountId: values.fromAccountId,
                destinationAccountId: selectedToAccountOrPayee?.accountID ?? null,
                destinationPayeeId: selectedToAccountOrPayee?.payeesId ?? null,
                transferType: values.transferType,
                sourceAmount: values.sourceAmount ?? null,
                destinationAmount: values.destinationAmount ?? null,
                purpose: values.purpose,
                purposeOther: values.purposeOther,
            };
            instance
                .post<ApiResponse<ApiReviewResponse>>(endpoints.cryptoExchange.reviewOrder, payload)
                .then((res) => {
                    setOrderDetails({
                        ...res.data.details,
                        comment: values.comment,
                        exchangeType: values.exchangeType,
                        quantityType: values.quantityType,
                        assetPairSwapped,
                        pairedAsset: values.pairedAsset,
                        asset: values.asset,
                        buyPrice: values.buyPrice,
                        sellPrice: values.sellPrice,
                        sourceAmount: values.sourceAmount,
                        destinationAmount: values.destinationAmount,
                        transferType: values.transferType,
                    });
                })
                .catch((err) => {
                    if (isAxiosErrorHandled(err)) {
                        if (
                            err.response.data.errors?.some(
                                (err) => err.messageCode === 'Insufficient_Funds'
                            )
                        ) {
                            helpers.setFieldError(
                                'sourceAmount',
                                getErrorMessage('Insufficient_Funds')
                            );
                            helpers.setFieldError(
                                'estimatedSourceAmount',
                                getErrorMessage('Insufficient_Funds')
                            );
                        } else if (
                            err.response.data.errors?.some(
                                (err) => err.messageCode === 'Price_Less_Than_Min_Amount'
                            )
                        ) {
                            helpers.setFieldError(
                                'sourceAmount',
                                getErrorMessage('MIN_NOT_REACHED_WITH_FEES')
                            );
                            helpers.setFieldError(
                                'estimatedSourceAmount',
                                getErrorMessage('MIN_NOT_REACHED_WITH_FEES')
                            );
                        } else if (
                            err.response.data.errors?.some((err) =>
                                err.fieldName.toLowerCase().includes('transmitter')
                            )
                        ) {
                            setReviewErrorMessage(
                                'Missing payee details. Please updated selected payee or choose another payee'
                            );
                        } else
                            setReviewErrorMessage(
                                getErrorMessage(err.response.data.errors[0]?.messageCode) ??
                                    'Generic'
                            );
                    } else setReviewErrorMessage(getErrorMessage('GENERIC'));
                })
                .finally(() => helpers.setSubmitting(false));

            return;
        }
        //post to create transaction

        const payload = {
            tfaCode: values.tfaCode,
            tfaType: values.tfaType,
            amount: orderDetails.amount,
            totalPrice: orderDetails.totalPrice,
            assetsId: orderDetails.assetsId,
            pairedAssetsId: orderDetails.pairedAssetsId,
            sourceAmount: orderDetails.sourceAmount,
            destinationAmount: orderDetails.destinationAmount,
            sourceAccountId: orderDetails.sourceAccountId,
            destinationAccountId: selectedToAccountOrPayee?.accountID ?? null,
            destinationPayeeId: selectedToAccountOrPayee?.payeesId ?? null,
            transferType: orderDetails.transferType,
            notes: orderDetails.comment,
            purpose: values.purpose,
            purposeOther: values.purposeOther,
        };

        instance
            .post<ApiResponse<ExecuteExchangeDetails>>(
                endpoints.cryptoExchange.executeExchange,
                payload
            )
            .then((res) => {
                if (res.data.status === '1') {
                    setExecutedDetails(res.data.details);
                } else throw Error('Status not marked as success');
            })
            .catch((err) => {
                helpers.setSubmitting(false);
                if (isAxiosErrorHandled(err) && err.response.data?.errors?.length > 0) {
                    if (
                        err.response.data.errors?.some((err) =>
                            err.fieldName.toLowerCase().includes('transmitter')
                        )
                    ) {
                        setExecuteErrorMessage(
                            'Missing payee details. Please updated selected payee or choose another payee'
                        );
                    } else
                        setExecuteErrorMessage(
                            getErrorMessage(err.response.data.errors[0]?.messageCode) ?? 'Generic'
                        );
                } else {
                    setExecuteErrorMessage(getErrorMessage('Failed to complete exchange'));
                }
                Toast.openErrorToast('Failed to complete exchange');
            })
            .finally();
    };
    const title = executedDetails
        ? 'Successfully Booked Contract'
        : orderDetails
        ? 'Accept Price'
        : `Book ${!assetPairSwapped ? data.asset : data.pairedAsset} / ${
              !assetPairSwapped ? data.pairedAsset : data.asset
          } Contract`;

    return (
        <Formik
            initialValues={generateInitialValues(
                data,
                tfaType,
                exchangeDetails?.fromAccounts ? exchangeDetails?.fromAccounts[0] : undefined,
                exchangeDetails?.mergedToAccounts ? exchangeDetails.mergedToAccounts[0] : undefined,
                assetPairSwapped
            )}
            onSubmit={handleSubmit}
            validationSchema={orderDetails ? validationSchemaStep2 : validationSchemaStep1}
            enableReinitialize
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Modal className="CryptoExchangeModal" title={title} closeOnClickOutside={false}>
                    {executedDetails ? (
                        <OrderComplete
                            orderDetails={orderDetails!}
                            exchangeDetails={exchangeDetails!}
                            executedDetails={executedDetails}
                            navigate={data.navigate}
                        />
                    ) : orderDetails ? (
                        <Form>
                            <OrderSummary
                                orderDetails={orderDetails}
                                exchangeDetails={exchangeDetails!}
                            />
                            <TFAField
                                field={'tfaCode'}
                                label={'TFA Code'}
                                required={true}
                                toggleTFAType={
                                    toggleTfaType
                                        ? () => {
                                              toggleTfaType();
                                              setFieldValue(
                                                  'tfaType',
                                                  values.tfaType === 'SMS'
                                                      ? 'AuthenticatorApp'
                                                      : 'SMS'
                                              );
                                          }
                                        : null
                                }
                                tfaType={tfaType}
                                holdFocus
                                autoFocus
                            />

                            {executeErrorMessage && (
                                <div className="ErrorText">{executeErrorMessage}</div>
                            )}
                            <div className="OrderButtons">
                                <Button
                                    type="button"
                                    priority="secondary"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setOrderDetails(null);
                                        setExecuteErrorMessage('');
                                    }}
                                >
                                    Reject
                                </Button>
                                <Button type="submit" priority="primary" disabled={isSubmitting}>
                                    Accept
                                </Button>
                            </div>
                        </Form>
                    ) : exchangeDetails ? (
                        <Form>
                            <ExchangeDetail
                                exchangeDetails={exchangeDetails}
                                reverseUX={assetPairSwapped}
                            />
                            <FromTo exchangeDetails={exchangeDetails} />
                            <FormTextField
                                type="textarea"
                                label={'My comment'}
                                labelExtraInfo="Optional"
                                field={'comment'}
                                hint="Maximum length is 1000 characters"
                                required={false}
                                className="CommentField"
                            />
                            {reviewErrorMessage && (
                                <div className="ErrorText">{reviewErrorMessage}</div>
                            )}
                            <Button disabled={isSubmitting} variety="full" type="submit">
                                Book Now
                            </Button>
                        </Form>
                    ) : !!error ? (
                        <div className="CryptoExchangeModalError">
                            <p>{error}</p>
                            <Button
                                onClick={() => {
                                    setError(null);
                                    setRetryInitialFetch((prev) => !prev);
                                }}
                            >
                                Retry
                            </Button>
                        </div>
                    ) : (
                        <Spinner />
                    )}
                </Modal>
            )}
        </Formik>
    );
};

const generateInitialValues = (
    data: CryptoExchangeModalData,
    tfaType: TFAType,
    fromAccount: ExchangeAccountDetails | undefined,
    mergedToAccount: MergedToAccountDetails | undefined,
    assetPairSwapped: boolean
): FormValues => {
    const { amount, ...rest } = data;
    if (data.exchangeType === 'Buy') {
        const values = {
            ...rest,
            comment: '',
            quantityType: !assetPairSwapped ? QuantityType.Destination : QuantityType.Source,
            sourceAmount: !assetPairSwapped ? null : amount ?? 0,
            estimatedDestinationAmount: !assetPairSwapped
                ? null
                : roundToPrecision((amount ?? 0) * data.buyPrice, 8),
            estimatedSourceAmount: !assetPairSwapped
                ? roundToPrecision((amount ?? 0) / data.buyPrice, 8)
                : null,
            destinationAmount: !assetPairSwapped ? amount ?? 0 : null,
            tfaType,
            tfaCode: '',
            fromAccountId: fromAccount?.accountID ?? null,
            pairedAssetsId: fromAccount?.assetID ?? data.pairedAssetsId,
            toMergedAccountGenericId: mergedToAccount?.genericId ?? null,
            transferType: null,
            isInternalPayee: null,
            purpose: null,
            purposeOther: null,
        };
        return values;
    }
    const values = {
        ...rest,
        comment: '',
        exchangeType: data.exchangeType,
        quantityType: !assetPairSwapped ? QuantityType.Source : QuantityType.Destination,
        sourceAmount: !assetPairSwapped ? amount ?? 0 : null,
        estimatedDestinationAmount: !assetPairSwapped
            ? roundToPrecision((amount ?? 0) / data.sellPrice, 8)
            : null,
        estimatedSourceAmount: !assetPairSwapped
            ? null
            : roundToPrecision((amount ?? 0) * data.sellPrice, 8),
        destinationAmount: !assetPairSwapped ? null : amount ?? 0,
        tfaType,
        tfaCode: '',
        fromAccountId: fromAccount?.accountID ?? null,
        assetsId: fromAccount?.assetID ?? data.assetsId,
        toMergedAccountGenericId: mergedToAccount?.genericId ?? null,
        transferType: null,
        isInternalPayee: null,
        purpose: null,
        purposeOther: null,
    };
    return values;
};
