import * as Yup from 'yup';
import { ExchangeDetails } from '../../../pages/cryptoV2/models';

export const buildStepOneValidationSchema = (exchangeDetails?: ExchangeDetails | null) =>
    Yup.object({
        exchangeType: Yup.string(),
        fromAccountId: Yup.string().nullable(),
        toMergedAccountGenericId: Yup.string().nullable(),
        // Using mixed so type errors aren't thrown when not using the input
        sourceAmount: Yup.mixed()
            .test('enoughFunds', 'Not enough funds', function (this, val) {
                const { fromAccountId } = this.parent;
                if (!fromAccountId) return true;
                const fromAccount = exchangeDetails?.fromAccounts.find(
                    (acc) => acc.accountID === fromAccountId
                );
                if (!fromAccount) return true;
                return fromAccount.balance >= (val ?? 0);
            })
            .when('exchangeType', {
                is: 'Buy',
                then: (schmea) =>
                    schmea.test('maxTadeCap', 'LimitsCheck', function (this, val) {
                        if (!val) return true;
                        const { maxTradeSize, minTradeSize, pairedAsset } = this.parent;
                        if (val > maxTradeSize)
                            return this.createError({
                                path: this.path,
                                message: 'Reached max trade amount',
                            });
                        if (val < minTradeSize)
                            return this.createError({
                                path: this.path,
                                message: `Min trade amount not reached (${minTradeSize} ${pairedAsset})`,
                            });

                        return true;
                    }),
            })
            .when('exchangeType', {
                is: 'Sell',
                then: (schmea) =>
                    schmea.test('maxTadeCap', 'LimitsCheck', function (this, val) {
                        const {
                            maxTradeSize,
                            minTradeSize,
                            estimatedDestinationAmount,
                            pairedAsset,
                        } = this.parent;
                        if (
                            !estimatedDestinationAmount &&
                            typeof estimatedDestinationAmount !== 'number'
                        )
                            return true;
                        if (estimatedDestinationAmount > maxTradeSize)
                            return this.createError({
                                path: this.path,
                                message: 'Reached max trade amount',
                            });
                        if (estimatedDestinationAmount < minTradeSize)
                            return this.createError({
                                path: this.path,
                                message: `Min trade amount not reached (${minTradeSize} ${pairedAsset})`,
                            });

                        return true;
                    }),
            }),
        destinationAmount: Yup.mixed()
            .test('enoughFunds', 'Not enough funds', function (this, val) {
                const { fromAccountId, estimatedSourceAmount: fromRequestedAmount } = this.parent;
                if (!fromAccountId) return true;
                const fromAccount = exchangeDetails?.fromAccounts.find(
                    (acc) => acc.accountID === fromAccountId
                );
                if (!fromAccount) return true;
                return fromAccount.balance >= (fromRequestedAmount ?? 0);
            })
            .when('exchangeType', {
                is: 'Sell',
                then: (schmea) =>
                    schmea.test('maxTadeCap', 'LimitsCheck', function (this, val) {
                        const { maxTradeSize, minTradeSize, pairedAsset } = this.parent;
                        if (!val) return true;
                        if (val > maxTradeSize)
                            return this.createError({
                                path: this.path,
                                message: 'Reached max trade amount',
                            });
                        if (val < minTradeSize)
                            return this.createError({
                                path: this.path,
                                message: `Min trade amount not reached (${minTradeSize} ${pairedAsset})`,
                            });

                        return true;
                    }),
            })
            .when('exchangeType', {
                is: 'Buy',
                then: (schmea) =>
                    schmea.test('maxTadeCap', 'LimitsCheck', function (this, val) {
                        const { maxTradeSize, minTradeSize, estimatedSourceAmount, pairedAsset } =
                            this.parent;
                        if (!estimatedSourceAmount) return true;
                        if (estimatedSourceAmount > maxTradeSize)
                            return this.createError({
                                path: this.path,
                                message: 'Reached max trade amount',
                            });
                        if (estimatedSourceAmount < minTradeSize)
                            return this.createError({
                                path: this.path,
                                message: `Min trade amount not reached (${minTradeSize} ${pairedAsset})`,
                            });

                        return true;
                    }),
            }),
        isInternalPayee: Yup.boolean().nullable(),
        purpose: Yup.string()
            .nullable()
            .when('isInternalPayee', {
                is: (value?: boolean) => value === false, //ignore if null
                then: (schema) => schema.required('Required'),
            }),
    });
