import { useState, useEffect } from 'react';


/**
 *  function to get the current window width and height.
 * 
 * @returns {{ width: number, height: number }} - the window width and height.
 */
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

/**
 * hook to track resize and return the current window dimensions.
 * Listens for window resize events and updates the dimensions in state.
 * 
 * @returns {{ width: number, height: number }} - The current window's width and height.
 */
export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};
