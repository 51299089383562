import instance, { ApiResponse, isErrorHandled } from 'api';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { TFAField } from 'components/form/TFAField';
import { Modal } from 'components/modal/Modal';
import { endpoints } from 'endpoints.config';
import { getErrorMessage } from 'errors';
import { Form, Formik, FormikHelpers } from 'formik';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { useTFAField } from 'helpers/useTFAField';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectModalState, ModalTypes } from 'reducers/modal';
import * as Yup from 'yup';
import WarningIcon from 'assets/Icon_Warning.png';
import { OrderDetails, PayeeDetail, TradeCrypto, TransferType } from 'pages/crypto/Crypto';
import { TFAType } from 'pages/register/models';
import { RadioButtons } from 'components/radiobuttons/radiobuttons';
import { ReviewOrderForm } from 'pages/crypto/ReviewOrderForm';
import { SelectOption, WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import { Toast, ToastMessageReason } from 'helpers/toast';
import logger from 'helpers/logger';
import { OtherPurpose } from 'pages/account/Payees/MakePayment/AmountPurpose';

type WithdrawCryptoForm = TradeCrypto & {
    payeesId?: number;
    isInternalPayee: boolean | null;
    purpose: string | null;
    purposeOther: string | null;
};

const initialValues: WithdrawCryptoForm = {
    account: 0,
    amount: '',
    price: '',
    tfaCode: '',
    payeesId: undefined,
    tfaType: 'SMS',
    quantityType: 'Amount',
    transferType: TransferType.ACH,
    asset: 'dUSD',
    currency: 'USD',
    bVirtualBuy: false,
    isInternalPayee: null,
    purpose: null,
    purposeOther: null,
};

const validationSchemaStep1 = Yup.object({
    amount: Yup.number().required('Please enter an amount').moreThan(0, 'Please enter an amount'),
    payeesId: Yup.number().required('Please Select a Payee'),
    isInternalPayee: Yup.boolean().nullable(),
    purpose: Yup.string()
        .nullable()
        .when('isInternalPayee', {
            is: (value?: boolean) => value === false, //ignore if null
            then: (schema) => schema.required('Required'),
        }),
});

const validationSchemaStep2 = Yup.object({
    tfaCode: Yup.string()
        .required('Please enter your MFA code')
        .min(6, 'Please enter your MFA code'),
});

export const WithdrawCryptoAsFiatModal = () => {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const modalState = useSelector(selectModalState);

    const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
    const [purposes, setPurposes] = useState<SelectOption[] | null>(null);

    //getavailablepayees
    const [payees, setPayees] = useState<PayeeDetail[] | null>(null);
    useEffect(() => {
        instance
            .get<ApiResponse<PayeeDetail[]>>(endpoints.cryptosmodule.getPayees, {
                params: { asset: initialValues.asset },
            })
            .then((res) => {
                setPayees(res.data.details);
            });
    }, []);

    const onPayeeSelected = useCallback(
        (value: number, setFieldValue: (field: string, value: any) => void) => {
            const selectedPayee = payees?.find((payee) => payee.payeesId === value);
            setPurposes(
                selectedPayee?.availablePurposeCodes?.map((purpose) => ({
                    label: purpose.description,
                    value: purpose.code,
                })) ?? null
            );
            setFieldValue(
                'isInternalPayee',
                selectedPayee?.allowedTransferTypes?.includes(TransferType.Internal) ?? null
            );
            setFieldValue('purpose', null);
            setFieldValue('purposeOther', null);
        },
        [payees]
    );

    const otherPurposeCode: string | undefined = purposes?.find(
        (purpose) => purpose.label.toLowerCase() === 'other'
    )?.value;

    //getprice

    //revieworder

    //handle tfa

    const handleSubmit = (
        values: WithdrawCryptoForm,
        formikHelpers: FormikHelpers<WithdrawCryptoForm>
    ) => {
        if (!orderDetails) {
            //get order details
            const payload = {
                customerAssetAccountId: undefined,
                payeesId: values.payeesId,
                assetExchangeType: 'Sell',
                asset: values.asset,
                amount: values.amount,
                totalPrice: values.price,
                transferType: values.transferType,
                quantityType: values.quantityType,
                bVirtualBuy: values.bVirtualBuy,
                pairedAsset: undefined,
                purpose: values.purpose,
                purposeOther: values.purposeOther,
            };
            instance
                .get<ApiResponse<OrderDetails>>(endpoints.cryptosmodule.reviewOrder, {
                    params: payload,
                })
                .then((res) => {
                    setOrderDetails(res.data.details);
                    setErrorMessage('');
                })
                .catch((err) => {
                    if (isErrorHandled(err) && err.response.data?.errors?.length > 0) {
                        if (
                            err.response.data.errors?.some((err) =>
                                err.fieldName.toLowerCase().includes('transmitter')
                            )
                        ) {
                            setErrorMessage(
                                'Missing payee details. Please updated selected payee or choose another payee'
                            );
                        } else
                            setErrorMessage(
                                getErrorMessage(err.response.data.errors[0].messageCode)
                            );
                    } else {
                        setErrorMessage(getErrorMessage(''));
                    }
                })
                .finally(() => {
                    formikHelpers.setSubmitting(false);
                });
            return;
        }

        instance
            .post(endpoints.cryptosmodule.sellAsset, {
                ...values,
                ...orderDetails,
            })
            .then((res) => {
                if (!res?.data?.details?.bApproved) {
                    Toast.openToastMessage(
                        "Your transaction has been flagged for review. The funds have been reserved and we'll let you know when it has been approved",
                        ToastMessageReason.ERROR,
                        { autoClose: false }
                    );
                }
                dispatch(closeModal());
            })
            .catch((err) => {
                if (isErrorHandled(err) && err.response.data?.errors?.length > 0) {
                    if (
                        err.response.data.errors?.some((err) =>
                            err.fieldName.toLowerCase().includes('transmitter')
                        )
                    ) {
                        setErrorMessage(
                            'Missing payee details. Please updated selected payee or choose another payee'
                        );
                    } else
                        setErrorMessage(getErrorMessage(err.response.data.errors[0].messageCode));
                } else setErrorMessage(getErrorMessage('Generic'));
                formikHelpers.setSubmitting(false);
                console.error(err);
            });

        // if (!values.showTfa) {
        //     formikHelpers.setFieldValue('showTfa', true);
        //     setShowTfa(true);
        //     formikHelpers.setSubmitting(false);
        //     formikHelpers.setFieldTouched('tfaCode', false);
        //     return;
        // }
        // const payload = { ...values, asset: modalState.data.crypto, tfaType: TFAType };
        // instance
        //     .post(endpoints.cryptosmodule.withdraw, payload)
        //     .then((res) => {
        //         dispatch(closeModal());
        //     })
        //     .catch((err) => {
        //         formikHelpers.setSubmitting(false);
        //         if (isErrorHandled(err)) {
        //             const errors = err.response.data.errors;
        //             if (
        //                 errors.some((error) =>
        //                     ['Amount', 'Address', 'TfaCode'].includes(error.fieldName)
        //                 )
        //             ) {
        //                 errors.forEach((error) =>
        //                     formikHelpers.setFieldError(
        //                         toCamelCase(error.fieldName) as string,
        //                         getErrorMessage(error.messageCode)
        //                     )
        //                 );
        //             } else setErrorMessage(getErrorMessage(errors[0].messageCode));
        //         } else {
        //             setErrorMessage('Something went wrong');
        //         }
        //     });
    };

    const createModalButtons = (submitfn: () => void, submitting: boolean) => (
        <div className="ModalNavigation">
            <Button onClick={() => dispatch(closeModal())} priority="secondary">
                Close
            </Button>
            <Button onClick={submitfn} priority="primary" disabled={submitting}>
                Withdraw
            </Button>
        </div>
    );

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={orderDetails ? validationSchemaStep2 : validationSchemaStep1}
        >
            {({ submitForm, isSubmitting, values, setFieldValue }) => (
                <Modal
                    title={`Withdraw USD`}
                    customButtons={createModalButtons(submitForm, isSubmitting)}
                    closeOnClickOutside={false}
                >
                    <Form>
                        <div className="WithdrawCryptoModal">
                            {!orderDetails && (
                                <>
                                    <WrappedFormSingleSelectField
                                        fieldName={'payeesId'}
                                        options={
                                            payees?.map((payee) => ({
                                                label: payee.payeeLabel,
                                                value: payee.payeesId,
                                            })) ?? []
                                        }
                                        label={'Payee'}
                                        dropdownProps={{
                                            menuPortalTarget: document.body,
                                            styles: {
                                                menuPortal: (provided: any) => ({
                                                    ...provided,
                                                    zIndex: 500,
                                                }),
                                            },
                                        }}
                                        onChange={onPayeeSelected}
                                    />
                                    {values.payeesId && (
                                        <RadioButtons
                                            options={
                                                payees?.find(
                                                    (payee) => payee.payeesId === values.payeesId
                                                )?.allowedTransferTypes ?? ['ACH', 'Wire']
                                            }
                                            fieldname="transferType"
                                            label="Payment Method"
                                        />
                                    )}

                                    {values.isInternalPayee === false && (
                                        <>
                                            {purposes && purposes.length > 0 ? (
                                                <WrappedFormSingleSelectField
                                                    fieldName="purpose"
                                                    label="Purpose"
                                                    options={purposes}
                                                    dropdownProps={{
                                                        menuPortalTarget: document.body,
                                                        styles: {
                                                            menuPortal: (provided: any) => ({
                                                                ...provided,
                                                                zIndex: 500,
                                                            }),
                                                        },
                                                    }}
                                                />
                                            ) : (
                                                <FormTextField
                                                    field="purpose"
                                                    label={`Payment Purpose `}
                                                    placeholder="E.g. Utility Bill"
                                                />
                                            )}
                                            <OtherPurpose otherPurposeCode={otherPurposeCode} />
                                        </>
                                    )}
                                    <FormTextField
                                        field="amount"
                                        label={'Amount (USD)'}
                                        required={true}
                                    />
                                </>
                            )}
                            {orderDetails && (
                                <ReviewOrderForm
                                    back={() => setOrderDetails(null)}
                                    orderDetails={orderDetails}
                                    refreshOrder={() => {}}
                                    errorMessage={''} //displayed below
                                    hideSubmitButton
                                    noFormComponent
                                />
                            )}
                        </div>
                        {errorMessage && <div className="ErrorText">{errorMessage}</div>}
                        <button type="submit" style={{ display: 'none' }} />
                    </Form>
                </Modal>
            )}
        </Formik>
    );
};
