import { useLocation, useMatch, useParams } from '@reach/router';
import { AppPath, DashboardPath } from 'appConstants';
import { AccountLink, ElementType, FiatAccountLink } from 'components/sideMenu/SideMenu';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectComponentResources } from 'reducers/componentResources';

export type PropsUseGetFiatAccountDetails = {
    currency: string;
};
export const useGetFiatAccountDetails = (
    propsUseGetFiatAccountDetails?: PropsUseGetFiatAccountDetails
) => {
    const params = useParams();
    const accountId = [DashboardPath.ACCOUNTS, DashboardPath.FUNDINGACCOUNTS].includes(
        `/${params.page}` as DashboardPath
    )
        ? params.sub_page
        : null;

    const { customerMenuLinks } = useSelector(selectComponentResources);

    const accountDetails = useMemo(() => {
        if (!accountId && !propsUseGetFiatAccountDetails?.currency) return undefined;
        const allAccounts = customerMenuLinks?.reduce<AccountLink[]>(
            (prev, curr) => prev.concat(curr?.accounts ?? []),
            []
        );
        const account_details = accountId
            ? allAccounts?.find((account) => account.id === parseInt(accountId))
            : allAccounts?.find((account) => 'currencyCode' in account && account.currencyCode === propsUseGetFiatAccountDetails?.currency);

        return isFiatAccountLink(account_details) ? account_details : undefined;
    }, [accountId, propsUseGetFiatAccountDetails?.currency, customerMenuLinks]);
    return accountDetails;
};
export const useGetFiatAccountForCurrency = (currencyCode?: string) => {
    const { customerMenuLinks } = useSelector(selectComponentResources);
    const accountDetails = useMemo(() => {
        if (!currencyCode) return undefined;
        const allAccounts = customerMenuLinks?.reduce<AccountLink[]>(
            (prev, curr) => prev.concat(curr?.accounts ?? []),
            []
        );
        return allAccounts
            ?.filter(isFiatAccountLink)
            .find((account) => account.currencyCode === currencyCode);
    }, [customerMenuLinks, currencyCode]);
    return accountDetails;
};

const isFiatAccountLink = (link?: AccountLink): link is FiatAccountLink => {
    return !!(link as FiatAccountLink).productDisplayName;
};
