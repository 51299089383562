import { isProduction } from '../endpoints.config';
import logger from './logger';

export type InitProperties = {
    gatewayName: string;
    gatewayMerchantId: string;
};
export const googlePayHelper = (function () {
    const baseRequest = {
        apiVersion: 2,
        apiVersionMinor: 0,
    };
    let initialized = false;
    /**
     * Card networks supported by your site and your gateway
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters | CardParameters}
     * @todo confirm card networks supported by your site and gateway
     */
    const allowedCardNetworks: google.payments.api.CardNetwork[] = ['AMEX', 'MASTERCARD', 'VISA'];

    /**
     * Card authentication methods supported by your site and your gateway
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters | CardParameters}
     * @todo confirm your processor supports Android device tokens for your
     * supported card networks
     */
    const allowedCardAuthMethods: google.payments.api.CardAuthMethod[] = [
        'PAN_ONLY',
        'CRYPTOGRAM_3DS',
    ];

    const gatewayParameters = {
        gateway: '',
        gatewayMerchantId: '',
    };

    const updateGatewayParameters = (gateway: string, merchantId: string) => {
        // updates these parameters in place so that anything with a reference to gatewayparameters should get updated
        gatewayParameters.gateway = gateway;
        gatewayParameters.gatewayMerchantId = merchantId;
    };

    /**
     * Identify your gateway and your site's gateway merchant identifier
     *
     * The Google Pay API response will return an encrypted payment method capable
     * of being charged by a supported gateway after payer authorization
     *
     * @todo check with your gateway on the parameters to pass
     * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#gateway | PaymentMethodTokenizationSpecification}
     */
    const tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification = {
        type: 'PAYMENT_GATEWAY',
        parameters: gatewayParameters,
    };
    /**
     * Describe your site's support for the CARD payment method and its required
     * fields
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters | CardParameters}
     */
    const baseCardPaymentMethod: google.payments.api.IsReadyToPayPaymentMethodSpecification = {
        type: 'CARD',
        parameters: {
            allowedAuthMethods: allowedCardAuthMethods,
            allowedCardNetworks: allowedCardNetworks,
        },
    };

    let isReady = false;

    /**
     * Describe your site's support for the CARD payment method including optional
     * fields
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters | CardParameters}
     */
    const getCardPaymentMethod = () => {
        if (!tokenizationSpecification.parameters.gatewayMerchantId)
            throw new Error('Gateway merchantId not specified');
        return Object.assign({}, baseCardPaymentMethod, {
            tokenizationSpecification: tokenizationSpecification,
        });
    };

    /**
     * An initialized google.payments.api.PaymentsClient object or null if not yet set
     *
     * @see {@link getGooglePaymentsClient}
     */
    let paymentsClient: google.payments.api.PaymentsClient | null = null;

    /**
     * Configure your site's support for payment methods supported by the Google Pay
     * API.
     *
     * Each member of allowedPaymentMethods should contain only the required fields,
     * allowing reuse of this base request when determining a viewer's ability
     * to pay and later requesting a supported payment method
     *
     * @returns {object} Google Pay API version, payment methods supported by the site
     */
    function getGoogleIsReadyToPayRequest(): google.payments.api.IsReadyToPayRequest {
        return Object.assign({}, baseRequest, {
            allowedPaymentMethods: [baseCardPaymentMethod],
        });
    }

    /**
     * Configure support for the Google Pay API
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest | PaymentDataRequest}
     * @returns {object} PaymentDataRequest fields
     */
    function getGooglePaymentDataRequest(): google.payments.api.PaymentDataRequest {
        const paymentDataRequest = {
            ...baseRequest,
            allowedPaymentMethods: [getCardPaymentMethod()],
            transactionInfo: getGoogleTransactionInfo(),
            merchantInfo: {
                // @todo a merchant ID is available for a production environment after approval by Google
                // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist | Integration checklist}
                merchantId: isProduction ? 'BCR2DN4T4GZ5DSBJ' : '12345678901234567890',
                merchantName: isProduction ? 'Ibanera' : 'Example Merchant',
            },
        };
        return paymentDataRequest;
    }

    /**
     * Return an active PaymentsClient or initialize
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/client#PaymentsClient | PaymentsClient constructor}
     * @returns {google.payments.api.PaymentsClient} Google Pay API client
     */
    function getGooglePaymentsClient() {
        if (paymentsClient === null) {
            paymentsClient = new google.payments.api.PaymentsClient({
                environment: isProduction ? 'PRODUCTION' : 'TEST',
            });
        }
        return paymentsClient;
    }

    function injectGooglePay() {
        return new Promise((resolve, reject) => {
            const scriptId = 'GooglePayScript';
            if (document.getElementById(scriptId)) {
                return resolve(isReady);
            }
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = 'https://pay.google.com/gp/p/js/pay.js';
            script.onload = () => {
                onGooglePayLoaded().then(() => resolve(isReady));
            };
            document.head.appendChild(script);
        });
    }

    /**
     * Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
     *
     * Display a Google Pay payment button after confirmation of the viewer's
     * ability to pay.
     */
    function onGooglePayLoaded() {
        const paymentsClient = getGooglePaymentsClient();
        return paymentsClient
            .isReadyToPay(getGoogleIsReadyToPayRequest())
            .then(function (response) {
                if (response.result) {
                    isReady = true;

                    // @todo prefetch payment data to improve performance after confirming site functionality
                    // prefetchGooglePaymentData();
                }
            })
            .catch(function (err) {
                // show error in developer console for debugging
                console.error(err);
            });
    }

    function updateValues({ price, currencyCode }: { price: string; currencyCode: string }) {
        values.totalPrice = price;
        values.currencyCode = currencyCode;
    }

    let button: HTMLElement | null = null;
    /**
     * Add a Google Pay purchase button alongside an existing checkout button
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#ButtonOptions | Button options}
     * @see {@link https://developers.google.com/pay/api/web/guides/brand-guidelines | Google Pay brand guidelines}
     */
    function createGooglePayButton() {
        if (button) return button;
        const paymentsClient = getGooglePaymentsClient();
        button = paymentsClient.createButton({
            onClick: onGooglePaymentButtonClicked,
            allowedPaymentMethods: [baseCardPaymentMethod],
            buttonSizeMode: 'fill',
        });
        return button;
    }

    /**
     * Provide Google Pay API with a payment amount, currency, and amount status
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#TransactionInfo | TransactionInfo}
     * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
     */
    function getGoogleTransactionInfo(): google.payments.api.TransactionInfo {
        return values;
    }

    const values: google.payments.api.TransactionInfo = {
        countryCode: 'US',
        currencyCode: 'USD',
        totalPriceStatus: 'FINAL',
        // set to cart total
        totalPrice: '1.00',
    };

    /**
     * Prefetch payment data to improve performance
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/client#prefetchPaymentData | prefetchPaymentData()}
     */
    function prefetchGooglePaymentData() {
        const paymentDataRequest: google.payments.api.PaymentDataRequest =
            getGooglePaymentDataRequest();
        // transactionInfo must be set but does not affect cache
        paymentDataRequest.transactionInfo = {
            ...paymentDataRequest.transactionInfo,
            totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
            currencyCode: 'USD',
        };
        const paymentsClient = getGooglePaymentsClient();
        paymentsClient.prefetchPaymentData(paymentDataRequest);
    }

    /**
     * Show Google Pay payment sheet when Google Pay payment button is clicked
     */
    function onGooglePaymentButtonClicked() {
        const paymentDataRequest = getGooglePaymentDataRequest();
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

        const paymentsClient = getGooglePaymentsClient();
        paymentsClient
            .loadPaymentData(paymentDataRequest)
            .then(function (paymentData) {
                // handle the response
                processPayment(paymentData);
            })
            .catch(function (err) {
                // show error in developer console for debugging
                console.error(err);
            });
    }

    const init = (properties: InitProperties) => {
        //we can call this multiple times, the google script will only be added once
        updateGatewayParameters(properties.gatewayName, properties.gatewayMerchantId);
        return injectGooglePay();
    };

    /**
     * Process payment data returned by the Google Pay API
     *
     * @param {object} paymentData response from Google Pay API after user approves payment
     * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentData | PaymentData object reference}
     */
    let processPayment: (paymentData: google.payments.api.PaymentData) => void;
    const updateOnComplete = (onComplete: typeof processPayment) => (processPayment = onComplete);
    return {
        init: init,
        addGooglePayButton: createGooglePayButton,
        updateValues,
        updateOnComplete,
    };
})();
