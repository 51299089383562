import React, { useState } from 'react';
import './modals.scss';
import { PaymentStatus } from '../models/models';

const ModelTransferSuccess = ({
    txDetail,
    closeModal,
}: {
    txDetail: PaymentStatus
    closeModal: () => void;
}) => {
    return (
        <div className="success-container">
            <div className="success-message">
                <h1 className="success-animation">🎉</h1>
                <h2>Payment Successful!</h2>
                <p>
                    You have transferred <strong>${txDetail.amount}</strong> to <strong>{txDetail.recipient}</strong>.
                </p>
                <p className="transaction-id">
            <strong>Transaction ID:</strong> {txDetail.transactionId}
          </p>
                <button onClick={closeModal} className="success-dismiss-button">
                    Done
                </button>
            </div>
        </div>
    );
};

export default ModelTransferSuccess;
