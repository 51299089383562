import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { AssetCodeFormatter } from '../../../../components/AssetCodeFormatter';
import Button from '../../../../components/button/Button';
import { AccountFeeInfo, MakePaymentForm, PaymentStepProps } from './MakePayment';
import styles from './MakePayment.module.scss';
import api, { ApiResponse, isAxiosErrorHandled } from '../../../../api';
import { endpoints } from '../../../../endpoints.config';
import { Toast, ToastMessageReason } from '../../../../helpers/toast';
import { useEffect, useMemo, useState } from 'react';
import { getFeeLabel } from './AmountPurpose';
import { getRelevantPayloadFields, useIsFinancialInstitution } from '../helpers';
import { MakePaymentMfaModal } from './MakePaymentMfaModal';
import { ItemConnectionModel } from 'pages/IbaneraNetwork/items/ItemConnection';
import { PaymentStatus } from 'pages/IbaneraNetwork/models/models';
import { useGetFiatAccountDetails } from 'helpers/useGetFiatAccountDetails';

type Props = PaymentStepProps & {
    data: MakePaymentForm;
    purposes: SelectOption[];
    accountFeeInfo: AccountFeeInfo;
    availableCountries: {
        label: string;
        value: string;
    }[];
    closeHandler: (paymentStatus?: PaymentStatus) => void;
    connectionPayee: ItemConnectionModel;
};

export const ReviewConnectionPayment: React.FC<Props> = ({
    data,
    purposes,
    accountFeeInfo,
    nextStep,
    closeHandler,
    availableCountries,
    connectionPayee,
}) => {
    const bFinancialInstitution = useIsFinancialInstitution();
    const assetAcountDetail = useGetFiatAccountDetails({
        currency: data?.payeeConnectionAssetAccount?.currencyCode ?? '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const purpose = useMemo(
        () => purposes.find((item) => item.value === data?.purpose),
        [data?.purpose, purposes]
    );
    const [showMfaModal, setShowMfaModal] = useState(false);
    const openMfaModal = () => setShowMfaModal(true);
    const closeMfaModal = () => setShowMfaModal(false);
    const feeOptions = useMemo(
        () =>
            accountFeeInfo.transferTypes.find(
                (fee) => fee.transferType.toLowerCase() === data.transferType.toLowerCase()
            )?.feeOptions ?? [],
        [accountFeeInfo?.transferTypes, data?.transferType]
    );
    const fee = useMemo(
        () => feeOptions.find((fee) => fee.bankFeeSplitType === data.feeId),
        [data.feeId, feeOptions]
    );

    const submitPayment = async (tfaCode: string, tfaType: string) => {
        setIsSubmitting(true);
        closeMfaModal();
        const { id, paymentReference, attachments, amount, feeId } = getRelevantPayloadFields(data);
        if (
            assetAcountDetail?.productDisplayName !==
            data?.payeeConnectionAssetAccount?.productDisplayName
        ) {
            const apiDataaaa = {
                CustomerAssetAccountsId: 6236,
                TransferType: 4,
                CountryCode: 'PAK',
                RoutingNumber: '123456',
                AccountNumber: '54199918081',
                SwiftNumber: 'NBPAPKKA02S',
                IBAN: 'BPUN1245636',
                AccountName: 'Ahsin',
                Name: 'Ali',
                AddressLine1: 'Line 1',
                AddressLine2: 'Line 2',
                AddressCountryCode: 'PAK',
                TownCity: 'Lahore',
                State: 'PU',
                Postcode: '54000',
                bSavePayee: false,
                CurrencyCode: 'USD',
                Amount: 1.0,
                Reference: 'IBC14508',
                BeneficiaryReference: null,
                Purpose: 'PUC001',

                TfaCode: '113',
                TfaType: '12343',
            };

            const apiData = {
                CustomerAssetAccountsId: assetAcountDetail?.id,
                TransferType: 4,
                CountryCode: data?.payeeConnectionAssetAccount?.addressCountryCode,
                RoutingNumber: '',
                AccountNumber: data?.payeeConnectionAssetAccount?.accountNumber,
                SwiftNumber: data?.payeeConnectionAssetAccount?.swiftBic,
                IBAN: data?.payeeConnectionAssetAccount?.accountNumber,
                AccountName: data?.payeeConnectionAssetAccount?.accountName,
                Name: data?.payeeConnectionAssetAccount?.name,
                AddressLine1: data?.payeeConnectionAssetAccount?.addressLine1,
                AddressLine2: data?.payeeConnectionAssetAccount?.addressLine2,
                AddressCountryCode: data?.payeeConnectionAssetAccount?.addressCountryCode,
                TownCity: data?.payeeConnectionAssetAccount?.townCity,
                State: '',
                Postcode: data?.payeeConnectionAssetAccount?.addressPostcode,
                bSavePayee: false,
                CurrencyCode: data?.payeeConnectionAssetAccount?.currencyCode,
                Amount: amount,
                Reference: paymentReference,
                BeneficiaryReference: null,
                Purpose: purpose?.value ?? '',
                TfaCode: tfaCode,
                TfaType: tfaType,
            };
            const apiParmsNew = {
                FromCustomerAssetAccountsId:  assetAcountDetail?.id,
                ToCustomerAssetAccountsId: data?.payeeConnectionAssetAccount?.id,
                Amount: amount,
                Reference: paymentReference,
                TfaCode: tfaCode,
                TfaType: tfaType,
            };
           
            try {
                const res = await api.post<
                    ApiResponse<{
                        bApproved: boolean;
                        id: string;
                    }>
                >(
                    'http://localhost:5001/api/v1/customerrole/accountsmodule/create-customer-internal-transfer',
                    apiParmsNew,
                    {
                        headers: { 'content-type': 'application/json' },
                    }
                );

                if (res.data.status === '1') {
                    closeHandler({
                        amount: amount + '',
                        recipient:
                            connectionPayee?.customerId == connectionPayee?.senderId
                                ? connectionPayee.receiverName
                                : connectionPayee.senderName,
                        transactionId: res.data?.details?.id,
                    });
                    // if (!res.data.details.bApproved) {
                    //     Toast.openToastMessage(
                    //         "Your transaction has been flagged for review. The funds have been reserved and we'll let you know when it has been approved",
                    //         ToastMessageReason.ERROR,
                    //         { autoClose: false }
                    //     );
                    //     dispatch(getCustomerComponentResources());
                    //     dispatch(completeUIUpdate());
                    // } else {
                    //     Toast.openSuccessToast('Transfer succeeded');
                    //     dispatch(getCustomerComponentResources());
                    //     dispatch(completeUIUpdate());
                    // }
                    nextStep();
                }
            } catch (err) {
                if (isAxiosErrorHandled(err)) {
                    const errors = err.response.data.errors;
                    if (
                        errors.some(
                            (err) => err.fieldName === 'PayeesId' && err.messageCode === 'Invalid'
                        )
                    ) {
                        Toast.openToastMessage('Invalid Payee', ToastMessageReason.ERROR);
                    } else if (
                        errors.some((err) => err.fieldName.toLowerCase().includes('transmitter'))
                    ) {
                        // setshowEditPayeeButton(true);
                        Toast.openErrorToast('Missing transmitting info');
                    } else if (errors.some((err) => err.fieldName === 'tfaCode')) {
                        Toast.openErrorToast('Invalid TFA code');
                    } else Toast.parseErrorIntoToast(err.response.data.errors?.[0]?.messageCode);
                } else Toast.openGenericErrorToast();
            } finally {
                setIsSubmitting(false);
            }
        } else {
            // formdata.append('customerAssetAccountsId', account?.id + '');
            // const apiData = {
            //     // payeesId: id + '',
            //     tfaCode: tfaCode,
            //     tfaType: tfaType,
            //     sourceAccountId: '4504',
            //     destinationAccountId: data.payeeConnectionAssetAccount?.id + '',
            //     amount: amount + '',
            //     reference: paymentReference,
            // };
            const apiData = {
                FromCustomerAssetAccountsId  : assetAcountDetail?.id,
                ToCustomerAssetAccountsId  : data.payeeConnectionAssetAccount?.id,
                Amount: amount,
                reference: paymentReference,
                description: paymentReference,
                TfaCode: tfaCode,
                TfaType: tfaType,
            };
            // feeId && formdata.append('bankFeeSplitType', feeId + '');,'reference', paymentReference);
            // Object.entries(payload).forEach(([key, value]) => {
            //     formdata.append(key, value + '');
            // });
            // attachments.forEach((file) => {
            //     formdata.append('attachments', file);
            // });
            // endpoints.accounts.createExternalTransfer
            try {
                const res = await api.post<
                    ApiResponse<{
                        bApproved: boolean;
                        id: string;
                    }>
                >(
                    'http://localhost:5001/api/v1/customerrole/accountsmodule/create-customer-internal-transfer',
                    apiData,
                    {
                        headers: { 'content-type': 'application/json' },
                    }
                );

                if (res.data.status === '1') {
                    closeHandler({
                        amount: amount + '',
                        recipient:
                            connectionPayee?.customerId == connectionPayee?.senderId
                                ? connectionPayee.receiverName
                                : connectionPayee.senderName,
                        transactionId: res.data?.details?.id,
                    });
                    // if (!res.data.details.bApproved) {
                    //     Toast.openToastMessage(
                    //         "Your transaction has been flagged for review. The funds have been reserved and we'll let you know when it has been approved",
                    //         ToastMessageReason.ERROR,
                    //         { autoClose: false }
                    //     );
                    //     dispatch(getCustomerComponentResources());
                    //     dispatch(completeUIUpdate());
                    // } else {
                    //     Toast.openSuccessToast('Transfer succeeded');
                    //     dispatch(getCustomerComponentResources());
                    //     dispatch(completeUIUpdate());
                    // }
                    nextStep();
                }
            } catch (err) {
                if (isAxiosErrorHandled(err)) {
                    const errors = err.response.data.errors;
                    if (
                        errors.some(
                            (err) => err.fieldName === 'PayeesId' && err.messageCode === 'Invalid'
                        )
                    ) {
                        Toast.openToastMessage('Invalid Payee', ToastMessageReason.ERROR);
                    } else if (
                        errors.some((err) => err.fieldName.toLowerCase().includes('transmitter'))
                    ) {
                        // setshowEditPayeeButton(true);
                        Toast.openErrorToast('Missing transmitting info');
                    } else if (errors.some((err) => err.fieldName === 'tfaCode')) {
                        Toast.openErrorToast('Invalid TFA code');
                    } else Toast.parseErrorIntoToast(err.response.data.errors?.[0]?.messageCode);
                } else Toast.openGenericErrorToast();
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    return (
        <div className={styles.ReviewPayment}>
            <MakePaymentMfaModal
                open={showMfaModal}
                handleClose={closeMfaModal}
                handleSubmit={submitPayment}
            />
            <div className={styles.ReviewPaymentIbaneraNetwork}>
                <div className="Row">
                    <h4>Payer Details</h4>
                    <dl className={styles.Recipient}>
                        <div className={styles.Row}>
                            <dt>Account Name:</dt>
                            <dd>{assetAcountDetail?.accountName || '-'}</dd>
                        </div>

                        {/* {assetAcountDetail?.swiftBic && (
                            <div className={styles.Row}>
                                <dt>Swift Number:</dt>
                                <dd>{assetAcountDetail.swiftBic}</dd>
                            </div>
                        )} */}

                        {/* {assetAcountDetail?.accountNumber && (
                            <div className={styles.Row}>
                                <dt>Account Number:</dt>
                                <dd>{assetAcountDetail?.accountNumber}</dd>
                            </div>
                        )} */}
                    </dl>
                </div>
                <div className="Row">
                    <h4>Recipient Details</h4>
                    <dl className={styles.Recipient}>
                        {/* <div className={styles.Row}>
                            <dt>Account Type:</dt>
                            <dd>{data.payeeType || '-'}</dd>
                        </div> */}
                        {/* <div className={styles.Row}>
                            <dt>Account Country:</dt>
                            <dd>{country || '-'}</dd>
                        </div> */}
                        <div className={styles.Row}>
                            <dt>Account Name:</dt>
                            <dd>{data?.payeeConnectionAssetAccount?.accountName || '-'}</dd>
                        </div>
                        <div className={styles.Row}>
                            <dt>Payee Name:</dt>
                            <dd>
                                {connectionPayee?.customerId == connectionPayee?.senderId
                                    ? connectionPayee.receiverName
                                    : connectionPayee.senderName}
                            </dd>
                        </div>

                        {/* <div className={styles.Row}>
                            <dt>Payee Country:</dt>
                            <dd>{payeeCountry || '-'}</dd>
                        </div> */}
                        <div className={styles.Row}>
                            <dt>Payee Reference:</dt>
                            <dd>{data.payeesReference || '-'}</dd>
                        </div>
                        {data.swiftNumber && (
                            <div className={styles.Row}>
                                <dt>Swift Number:</dt>
                                <dd>{data?.payeeConnectionAssetAccount?.swiftBic}</dd>
                            </div>
                        )}
                        {/* {data.intermediaryBic && (
                            <div className={styles.Row}>
                                <dt>Intermediary Swift:</dt>
                                <dd>{data.intermediaryBic}</dd>
                            </div>
                        )} */}
                        {data.iban && (
                            <div className={styles.Row}>
                                <dt>IBAN:</dt>
                                <dd>{data.iban}</dd>
                            </div>
                        )}
                        {data.routingNumber && (
                            <div className={styles.Row}>
                                <dt>Routing Number:</dt>
                                <dd>{data.routingNumber}</dd>
                            </div>
                        )}
                        {data?.payeeConnectionAssetAccount?.accountNumber && (
                            <div className={styles.Row}>
                                <dt>Account Number:</dt>
                                <dd>{data?.payeeConnectionAssetAccount?.accountNumber}</dd>
                            </div>
                        )}
                    </dl>
                </div>
                <div className="Row">
                    <h4>Payment details</h4>
                    <dl>
                        <div className={styles.Row}>
                            <dt>Payment Method:</dt>
                            <dd>{data.transferType}</dd>
                        </div>
                        <div className={styles.Row}>
                            <dt>Payment Reference:</dt>
                            <dd>{data.paymentReference || '-'}</dd>
                        </div>
                    </dl>
                </div>
                <div className="Row">
                    <h4>Amount & purpose</h4>
                    <dl>
                        {fee && feeOptions.length > 1 && (
                            <div className={styles.Row}>
                                <dt>Fee:</dt>
                                <dd>{getFeeLabel(fee.bankFeeSplitType).label}</dd>
                            </div>
                        )}
                        <div className={styles.Row}>
                            <dt>Amount:</dt>
                            <dd>
                                {data.amount}{' '}
                                <AssetCodeFormatter
                                    assetCode={data.payeeConnectionAssetAccount?.currencyCode ?? ''}
                                />
                            </dd>
                        </div>
                        <div className={styles.Row}>
                            <dt>{data.transferType} Payment Purpose:</dt>
                            <dd>{purpose ? purpose.label : data.purpose || '-'}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <h4>More details</h4>
            <dl>
                {!bFinancialInstitution && (
                    <div className={styles.Row}>
                        <dt>Memo:</dt>
                        <dd>{data?.memo || '-'}</dd>
                    </div>
                )}
                <div className={styles.Row}>
                    <dt>Attachments:</dt>
                    <dd>
                        {data.attachments.length > 0
                            ? data.attachments.reduce(
                                  (prev, cur, i) => prev + `${i !== 0 ? ', ' : ''}${cur.name}`,
                                  ''
                              )
                            : '-'}
                    </dd>
                </div>
                <div className={styles.Row}>
                    <dt>Notes:</dt>
                    <dd>{data.notes || '-'}</dd>
                </div>
            </dl>

            <Button
                disabled={isSubmitting}
                onClick={openMfaModal}
                color="third"
                showSubmittingSpinner={isSubmitting}
            >
                Confirm Payment
            </Button>
        </div>
    );
};
