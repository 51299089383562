import Footer from 'components/footer/Footer';
import Header from 'components/header/Header';
import { MobileNavigation } from 'components/mobileNavigation/MobileNavigation';
import SideMenu from 'components/sideMenu/SideMenu';
import { useGetComponentResources } from 'helpers/useGetComponentResources';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BusinessVerificationFlow, CryptoOtcDeskVersion } from 'reducers/appSettings';
import { selectSignedIn } from 'reducers/auth';
import { selectComponentResources } from 'reducers/componentResources';

export interface UserInformation {
    firstName: string;
    lastName: string;
    accountType: 'Personal' | 'Business';
    bAccountSwitchAvailable: boolean;
    bMainAccountHolder: boolean;
    emailAddress?: string;
    customerUsersCustomersId: number;
    id: number;
    onboardingVersion: BusinessVerificationFlow;
    otcDeskVersion: CryptoOtcDeskVersion;
    bPushToCardEnabled: boolean;
    bRequiresApprovalToCreatePayee: boolean;
    bCanBulkTransfer: boolean;
    bCanReverseTransfer: boolean;
    bFinancialInstitution: boolean;
    bExtendedUserList: boolean | undefined;
    bNetworkDiscoverable?: boolean;
    networkID?: string;
}

export const Navigation: React.FC<{ hideFooterAndLoginButtons?: boolean }> = ({
    children,
    hideFooterAndLoginButtons,
}) => {
    const isSignedIn = useSelector(selectSignedIn);
    // Need to use this hook here as moving it up a level to App causes entire application
    // rerenders on culture code change which is causing problems with the culture code setting
    // logic.
    useGetComponentResources();
    const { footerLinks, customerMenuLinks, customerMenuIcons } =
        useSelector(selectComponentResources);
    const leftMenuLinks = useMemo(
        //memoize this because otherwise it gets reloaded every time the children change
        () =>
            customerMenuLinks?.filter(
                (item) => item.position === 'LeftMenu' && item.path !== 'information-requests'
            ),
        [customerMenuLinks]
    );

    return isSignedIn ? (
        <div className={'PartialWidth'}>
            <Header hideLoginButtons={hideFooterAndLoginButtons} />
            {children}
            <SideMenu links={leftMenuLinks ? leftMenuLinks : null} icons={customerMenuIcons} />
            {footerLinks && !hideFooterAndLoginButtons && (
                <>
                    <MobileNavigation
                        moreMenuFooterLinks={footerLinks}
                        sideMenuLinks={leftMenuLinks ? leftMenuLinks : null}
                        sideMenuIcons={customerMenuIcons}
                    />
                    <Footer links={footerLinks} />
                </>
            )}
        </div>
    ) : (
        <div className={'FullWidth'}>
            <Header hideLoginButtons={hideFooterAndLoginButtons} />
            {children}
            {footerLinks && !hideFooterAndLoginButtons && (
                <>
                    <MobileNavigation moreMenuFooterLinks={footerLinks} />
                    <Footer links={footerLinks} />
                </>
            )}
        </div>
    );
};
