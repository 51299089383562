import { Form, Formik } from 'formik';
import { PaymentStepProps } from './MakePayment';
import FormTextField from '../../../../components/form/FormTextField';
import { FileUpload } from '../../../../components/form/FileUpload/FileUpload';
import Button from '../../../../components/button/Button';
import { useCallback, useEffect, useState } from 'react';
import { useIsFinancialInstitution } from '../helpers';

type Props = PaymentStepProps & {
    initialValues: Partial<MoreDetailsForm>;
};

export type MoreDetailsForm = {
    memo?: string;
    attachments: File[];
    notes: string;
};

export const MoreDetails: React.FC<Props> = ({ initialValues, nextStep }) => {
    const bFinancialInstitution = useIsFinancialInstitution();

    const [memoLengthMessage, setMemoLengthMessage] = useState(
        'Maximum memo length is 140 characters'
    );
    const [notesLengthMessage, setNotesLengthMessage] = useState(
        'Maximum text length is 1000 characters'
    );

    const onSubmit = async (values: MoreDetailsForm) => {
        nextStep(values);
    };

    useEffect(() => {
        if (initialValues?.notes?.length !== undefined && initialValues?.notes?.length > 0) {
            setNotesLengthMessage(`${1000 - initialValues?.notes?.length} characters remaining`);
        } else {
            setNotesLengthMessage('Maximum text length is 1000 characters');
        }
    }, [initialValues?.notes]);

    /**
     * Method to update the notes on input change.
     * This method calculates the remaining characters and updates the notes message
     * in real-time as the user types in the input field.
     *
     */
    const handleNotesChange = useCallback(
        (e: { target: { value: any } }, setFieldValue: (name: string, value: any) => void) => {
            const value = e.target.value;
            if (value.length > 0) {
                setNotesLengthMessage(`${1000 - value.length} characters remaining`);
            } else {
                setNotesLengthMessage('Maximum text length is 1000 characters');
            }
            setFieldValue('notes', value);
        },
        []
    );

    return (
        <Formik
            initialValues={{
                attachments: [],
                notes: '',
                ...initialValues,
            }}
            onSubmit={onSubmit}
        >
            {({ setFieldValue }) => (
                <Form>
                    <p className="mb-4">More information related to this payment</p>
                    <FileUpload
                        label="Attachments"
                        fieldName="attachments"
                        emptyUploadTitle="Drag and drop here or click to upload"
                        emptyUploadMessage="You may upload PDF, PNG or JPEG files"
                        multi
                        small
                    />
                    <FormTextField
                        label="Notes"
                        field="notes"
                        type="textarea"
                        placeholder="Type your text here"
                        maxLength={1000}
                        inputProps={{ rows: undefined }}
                        hint={notesLengthMessage}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleNotesChange(e, setFieldValue);
                        }}
                    />
                    <Button type="submit" color="third">
                        Next
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
