import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { handleGenericError } from './QuickCoinPage';
import { useCallback, useEffect, useState } from 'react';
import { getTokenAndEncrypt } from 'helpers/encryption';
import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import { endpoints } from 'endpoints.config';
import { useWpgSdk } from './WpgIframe';
import { useDispatch, useSelector } from 'react-redux';
import {
    completeUIUpdate,
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import Button from 'components/button/Button';
import { RadioButton, RadioButtons } from 'components/radiobuttons/radiobuttons';
import { CalculatedPriceField, FiatToggle } from './QuickCoinFormComponents';
import FormTextField from 'components/form/FormTextField';
import { SelectOption, WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import {
    initialValues,
    InitResponse,
    PaymentFlowType,
    PaymentGatewayMidConfig,
    PurchaseDetails,
    Rules,
    SavedCardDetails,
    validationSchema,
} from './model';
import {
    CardPaymentForm,
    CardPaymentFormValues_New,
    CardPaymentFormValues_Saved,
    handleSubmit_Saved,
    initialValues_New,
    initialValues_Saved,
    NewCardDetails,
    PaymentInfo,
    validationSchema_New,
    validationSchema_Saved,
    validationSchema_WpgNew,
} from './CardPaymentForm';
import { useFIS } from './useFIS';
import { AssetPairsDetails, AvailableOnOptions } from 'reducers/cryptoPrices';

type Props = {
    rules: Rules | null;
    cryptoOptions: SelectOption[];
    updateRules: (asset: string, pairedAsset: string) => void;
    prices: AssetPairsDetails;
    midConfigs: PaymentGatewayMidConfig[] | null;
    setMidConfigs: React.Dispatch<React.SetStateAction<PaymentGatewayMidConfig[] | null>>;
    onChangeStep: () => void;
};

export const QuickCoinBuy: React.FC<Props> = ({
    rules,
    cryptoOptions,
    updateRules,
    prices,
    midConfigs,
    setMidConfigs,
    onChangeStep,
}) => {
    const [cardType, setCardType] = useState<string>('New card');
    const [basket, setBasket] = useState<PurchaseDetails | null>(null);
    const [purchaseStage, setPurchaseStage] = useState<'basket-stage' | 'checkout-stage'>(
        'basket-stage'
    );
    const [paymentReference, setPaymentReference] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [redirectUrl, setRedirectUrl] = useState('');
    const [iframeUrl, setIframeUrl] = useState('');
    const [deviceSessionId, setDeviceSessionId] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [encryption, setEncryption] = useState<{ publicKey: string; keyId: string } | null>(null);
    const [idempotencyKey, setIdempotencyKey] = useState<string>('');
    const [paymentFlow, setPaymentFlow] = useState<PaymentFlowType>();
    const [cardPaymentErrMsg, setCardPaymentErrMsg] = useState('');

    const dispatch = useDispatch();

    useWpgSdk(paymentFlow ?? PaymentFlowType.Form);

    const update = useSelector(selectUIUpdate);

    useEffect(() => {
        if (update?.pushType === NotificationIdentifier.QUICKCRYPTO_CARD_PAYMENT_UPDATED) {
            instance
                .post<ApiResponse<PaymentInfo>>(endpoints.quickCryptoModule.getPaymentStatus, {
                    chargeCardIdempotencyKey: idempotencyKey,
                })
                .then((res) => {
                    //if (res.data.details.status !== paymentStatus)
                    setPaymentStatus(res.data.details.status);
                    setRedirectUrl(res.data.details.redirectUrl);
                });
            dispatch(completeUIUpdate());
        }
    }, [
        update, //paymentStatus
    ]);
    useEffect(() => {
        if (!paymentStatus || paymentStatus !== 'Pending') return;
        const interval = setInterval(() => {
            instance
                .post<ApiResponse<PaymentInfo>>(endpoints.quickCryptoModule.getPaymentStatus, {
                    chargeCardIdempotencyKey: idempotencyKey,
                })
                .then((res) => {
                    if (res.data.details.status !== paymentStatus) {
                        setPaymentStatus(res.data.details.status);
                        setRedirectUrl(res.data.details.redirectUrl);
                    }
                });
        }, 15000);
        return () => clearInterval(interval);
    }, [paymentStatus]);

    useFIS();

    useEffect(() => {
        if (purchaseStage === 'basket-stage') {
            instance
                .get<ApiResponse<InitResponse>>(endpoints.quickCryptoModule.init)
                .then((res) => {
                    const {
                        paymentFlow: flow,
                        encryption: publicKeyId,
                        idempotencyKey: IdempotencyKey,
                        paymentGatewayMidConfig,
                    } = res.data.details;
                    setPaymentFlow(flow);
                    setEncryption(publicKeyId);
                    setIdempotencyKey(IdempotencyKey);
                    setMidConfigs(paymentGatewayMidConfig ?? null);
                })
                .catch((err) => {
                    if (err?.response?.status === 403) {
                        // window.postMessage({ errorMessage: 'QuickBuy_Forbidden' });
                    }
                    if (
                        isAxiosErrorHandled(err) &&
                        err?.response?.data?.errors?.some(
                            (error) =>
                                error.messageCode === 'Invalid_Signature' ||
                                error.messageCode === 'Signature_Expired'
                        )
                    ) {
                    }
                });
        } else onChangeStep();
    }, [purchaseStage]);

    const handleSubmit_New = async (
        values: CardPaymentFormValues_New,
        formikHelpers: FormikHelpers<CardPaymentFormValues_New>
    ) => {
        if (!encryption) return formikHelpers.setSubmitting(false);
        const sessionID = (window as any).sessionUUID;
        const { cardNumber, cvv, ...payload } = values;
        const cardDetails = { number: cardNumber.replace(/\s/g, ''), cvv };
        const { encryptedMessage, keyId } = await getTokenAndEncrypt(cardDetails, encryption);
        const {
            currency: currencyCode,
            asset: assetCode,
            amount: assetAmount,
            price: currencyAmount,
            quantityType,
            bSaveCardDetails: bSaveCard,
            bAcceptedTermsAndConditions,
            countryISOCode: countryISO2,
            ...rest
        } = { ...payload, ...basket };

        const [expMonth, expYear] = rest.expiryDate.split('/');
        await instance
            .post<ApiResponse<any>>(endpoints.quickCryptoModule.submitNewCardForm, {
                ...rest,
                encryptedContent: encryptedMessage,
                keyId,
                // keyId:encryption.keyId,
                currencyCode,
                currencyAmount,
                expMonth,
                expYear: new Date().getFullYear().toString().slice(0, -2) + expYear,
                countryISO2,
                bSaveCard,
                chargeCardIdempotencyKey: idempotencyKey,
                emailAddress,
            })
            .then((res) => {
                setPaymentReference(res.data.details.paymentReference);
                setPaymentStatus(res.data.details.status);
                setRedirectUrl(res.data.details.redirectUrl);
            })
            .catch((err) => {
                if (
                    isAxiosErrorHandled(err) &&
                    err.response.data.errors.some(
                        (error) =>
                            error.fieldName === 'Create_Payment' && error.messageCode === 'Failed'
                    )
                ) {
                    return setPaymentStatus('Failed');
                }
                handleGenericError(err, values, formikHelpers, setCardPaymentErrMsg);
            })
            .finally(() => {
                formikHelpers.setSubmitting(false);
            });

        //formikHelpers.setSubmitting(false);
    }; //useCallback?

    const handleMobileWalletComplete = useCallback((result: PaymentInfo) => {
        setPaymentReference(result.paymentReference);
        setPaymentStatus(result.status);
        setRedirectUrl(result.redirectUrl);
    }, []);

    const getWpgUrl = async (
        values: PurchaseDetails,
        basket: PurchaseDetails | null,
        helpers: FormikHelpers<any>
    ) => {
        if (!basket) return helpers.setSubmitting(false);
        const { currency, asset, amount, price, quantityType } = basket;
        const { bSaveCard } = values;
        try {
            helpers.setSubmitting(true);
            const res = await instance.get<ApiResponse<{ iFrameUrl: string }>>(
                endpoints.quickCryptoModule.getPaymentIframe,
                {
                    params: {
                        asset,
                        currency,
                        quantityType,
                        bSaveCard,
                        price,
                        amount,
                    },
                }
            );
            helpers.setSubmitting(false);
            setIframeUrl(res.data.details.iFrameUrl);
            setPaymentStatus('WpgIframe');
        } catch {
            helpers.setSubmitting(false);
            setPaymentStatus('Failed');
        }
    };

    const handleSubmit = async (
        values: PurchaseDetails,
        formikHelpers: FormikHelpers<PurchaseDetails>
    ) => {
        const {
            currency: currencyCode,
            asset: assetCode,
            amount: assetAmount,
            price: currencyAmount,
            quantityType,
            bAcceptedTermsAndConditions,
            ...restPayload
        } = values;
        try {
            if (paymentFlow === PaymentFlowType.Form) {
                const res = await instance.post(endpoints.quickCryptoModule.submitExchangeOptions, {
                    bAcceptedTermsAndConditions,
                    currencyCode,
                    assetCode,
                    assetAmount,
                    currencyAmount,
                    quantityType,
                    bSaveCard: true,
                    chargeCardIdempotencyKey: idempotencyKey,
                    currency: currencyCode,
                    asset: assetCode,
                    amount: assetAmount,
                    price: currencyAmount,
                });
                setDeviceSessionId(res.data.details.deviceSessionId);
                setEmailAddress(res.data.details.emailAddress);
            }
            setPurchaseStage('checkout-stage'); //last step
            setBasket(values);
            formikHelpers.setSubmitting(false);
        } catch (err) {
            console.error(err);
            handleGenericError(err, values, formikHelpers, setCardPaymentErrMsg);
            formikHelpers.setSubmitting(false);
        }
    };

    if (!paymentFlow || !encryption || !idempotencyKey) return <></>;

    return (
        <div className="FormPage HeightContent">
            <Formik<PurchaseDetails | (PurchaseDetails & (NewCardDetails | SavedCardDetails))>
                initialValues={
                    //purchaseStage === 'basket-stage'? initialValues : //like setBasket(null); in cardpaymentform
                    purchaseStage === 'basket-stage'
                        ? basket ?? initialValues //initialValues
                        : cardType === 'Saved card'
                        ? {
                              ...initialValues_Saved,
                              ...(basket ?? initialValues),
                              cardType,
                          }
                        : {
                              ...initialValues_New,
                              ...(basket ?? initialValues),
                              cardType: 'New card',
                          }
                }
                onSubmit={async (values, helpers) => {
                    purchaseStage === 'basket-stage'
                        ? await handleSubmit(values, helpers)
                        : cardType === 'Saved card'
                        ? await handleSubmit_Saved({
                              paymentFlow,
                              encryption,
                              idempotencyKey,
                              setPaymentReference,
                              setPaymentStatus,
                              setRedirectUrl,
                              setCardPaymentErrMsg,
                          })(
                              values as CardPaymentFormValues_Saved,
                              helpers as FormikHelpers<CardPaymentFormValues_Saved>
                          )
                        : paymentFlow === PaymentFlowType.IFrame
                        ? await getWpgUrl(values, basket, helpers)
                        : await handleSubmit_New(
                              values as CardPaymentFormValues_New,
                              helpers as FormikHelpers<CardPaymentFormValues_New>
                          );
                }}
                validationSchema={
                    purchaseStage === 'basket-stage'
                        ? validationSchema(rules)
                        : paymentFlow === PaymentFlowType.IFrame
                        ? validationSchema_WpgNew
                        : cardType === 'Saved card'
                        ? validationSchema_Saved
                        : validationSchema_New
                }
            >
                {(formikPropsBasket) => {
                    const selectedMidConfig = midConfigs?.find(
                        (config) => config.currency === formikPropsBasket.values.currency
                    );
                    return selectedMidConfig && !!basket && purchaseStage === 'checkout-stage' ? (
                        //<div className="FormPage HeightContent">
                        <CardPaymentForm
                            basket={basket}
                            setPurchaseStage={setPurchaseStage}
                            setCardType={setCardType}
                            setBasket={setBasket}
                            idempotencyKey={idempotencyKey}
                            encryption={encryption}
                            paymentFlow={paymentFlow}
                            paymentReference={paymentReference}
                            paymentStatus={paymentStatus}
                            setPaymentStatus={setPaymentStatus}
                            redirectUrl={redirectUrl}
                            cardPaymentErrMsg={cardPaymentErrMsg}
                            setCardPaymentErrMsg={setCardPaymentErrMsg}
                            iframeUrl={iframeUrl}
                            onMobileWalletComplete={handleMobileWalletComplete}
                            deviceSessionId={deviceSessionId}
                            midConfig={selectedMidConfig}
                        />
                    ) : (
                        <Form className="QuickCoinForm">
                            <RuleUpdater updateRules={updateRules} />
                            <WrappedFormSingleSelectField<PurchaseDetails>
                                fieldName={'asset'}
                                options={cryptoOptions}
                                label={'Crypto to buy'}
                            />
                            <WrappedFormSingleSelectField<PurchaseDetails>
                                fieldName={'currency'}
                                options={
                                    formikPropsBasket.values.asset
                                        ? Object.keys(prices)
                                              .filter(
                                                  (key) =>
                                                      key.split('/')[0] ===
                                                          formikPropsBasket.values.asset &&
                                                      prices[key].availableOn?.includes(
                                                          AvailableOnOptions.QuickCoin
                                                      )
                                              )
                                              .map((key) => key.split('/')[1])
                                              .map((key) => ({ label: key, value: key }))
                                        : []
                                }
                                key={formikPropsBasket.values.currency} // lets us reset the dropdown when currency = ""
                                label={`Currency to purchase with`}
                            />
                            <FiatToggle
                                currencyCode={formikPropsBasket.values.currency}
                                assetCode={formikPropsBasket.values.asset}
                            />
                            <FormTextField
                                type="number"
                                onBlur={() =>
                                    formikPropsBasket.setFieldTouched(
                                        formikPropsBasket.values.quantityType === 'Price'
                                            ? 'amount'
                                            : 'price',
                                        true
                                    )
                                }
                                step={
                                    formikPropsBasket.values.quantityType === 'Price'
                                        ? 'any'
                                        : 1 / 10 ** (rules?.maxDecimalPrecision ?? 4)
                                }
                                autoComplete={false}
                                label={
                                    formikPropsBasket.values.quantityType === 'Price'
                                        ? `Target price${
                                              formikPropsBasket.values.currency
                                                  ? ` in ${formikPropsBasket.values.currency}`
                                                  : ''
                                          }`
                                        : `Amount${
                                              formikPropsBasket.values.asset
                                                  ? ` of ${formikPropsBasket.values.asset}`
                                                  : ''
                                          }`
                                }
                                hint={
                                    formikPropsBasket.values.quantityType === 'Price'
                                        ? rules?.maxPrice != null && rules.minPrice != null
                                            ? `min: ${rules?.minPrice}, max: ${rules.maxPrice}`
                                            : undefined
                                        : rules?.maxAmount != null && rules?.minAmount != null
                                        ? `min: ${rules?.minAmount}, max: ${rules?.maxAmount}`
                                        : undefined
                                }
                                field={
                                    formikPropsBasket.values.quantityType === 'Price'
                                        ? 'price'
                                        : 'amount'
                                }
                                required={true}
                                key={
                                    formikPropsBasket.values.quantityType === 'Price'
                                        ? 'priceField'
                                        : 'amountField'
                                }
                            />
                            <CalculatedPriceField
                                label={
                                    formikPropsBasket.values.quantityType === 'Price'
                                        ? `Amount${
                                              formikPropsBasket.values.asset
                                                  ? ` of ${formikPropsBasket.values.asset}`
                                                  : ''
                                          }`
                                        : `Price${
                                              formikPropsBasket.values.currency
                                                  ? ` in ${formikPropsBasket.values.currency}`
                                                  : ''
                                          }`
                                }
                                isFiat={formikPropsBasket.values.quantityType === 'Price'}
                                hint={
                                    formikPropsBasket.values.quantityType === 'Amount'
                                        ? rules?.maxPrice != null && rules.minPrice != null
                                            ? `min: ${rules?.minPrice}, max: ${rules.maxPrice}`
                                            : undefined
                                        : rules?.maxAmount != null && rules?.minAmount != null
                                        ? `min: ${rules?.minAmount}, max: ${rules?.maxAmount}`
                                        : undefined
                                }
                                getAmountUrl={endpoints.quickCryptoModule.getAmount}
                                getPriceUrl={endpoints.quickCryptoModule.getPrice}
                                // assetExchangeType={formikPropsBasket.values.transactionType}
                            />
                            <div
                                className={`TermsConditions ${
                                    formikPropsBasket.values.bAcceptedTermsAndConditions !== true
                                        ? //&& formikPropsBasket.touched.bAcceptedTermsAndConditions === true
                                          'NotAcceptedErrLabel'
                                        : ''
                                } ${cardPaymentErrMsg ? 'StagedPaymentError' : ''}`}
                            >
                                <div className={'RadioButtons'}>
                                    <RadioButton
                                        label={
                                            "By clicking the Buy button below, you agree to Ibanera's <a href='https://www.ibanera.com/legal-agreements/usa-general-terms-conditions/'>Terms & Conditions</a>, <a href='https://www.ibanera.com/legal-agreements/digital-asset-custody-terms-conditions/'>Digital Asset Custody Terms & Conditions</a> and <a href= 'https://www.ibanera.com/privacy-policy/'>Privacy Policy</a>"
                                        }
                                        selected={
                                            formikPropsBasket.values.bAcceptedTermsAndConditions
                                        }
                                        onClick={() => {
                                            formikPropsBasket.setFieldValue(
                                                'bAcceptedTermsAndConditions',
                                                !formikPropsBasket.values
                                                    .bAcceptedTermsAndConditions
                                            );
                                        }}
                                        bDangerousHtml={true}
                                        classname="terms-conditions"
                                    />
                                </div>
                                {formikPropsBasket.errors.bAcceptedTermsAndConditions && (
                                    <div className="ErrorLabel">
                                        {formikPropsBasket.errors.bAcceptedTermsAndConditions}
                                    </div>
                                )}
                            </div>
                            <div className="ErrorLabel StagedPayment">{cardPaymentErrMsg}</div>
                            <Button
                                type="submit"
                                variety="full"
                                disabled={formikPropsBasket.isSubmitting}
                            >
                                {`Buy ${formikPropsBasket.values.asset}`}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export const RuleUpdater = ({
    updateRules,
}: {
    updateRules: (assetCode: string, currency: string) => void;
}) => {
    const { values } = useFormikContext<PurchaseDetails>();
    useEffect(() => {
        updateRules(values.asset, values.currency);
    }, [values.asset, values.currency, updateRules]);
    return null;
};
