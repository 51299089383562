import { useCallback, useEffect, useState } from 'react';
import Button from '../button/Button';
import { Modal } from '../modal/Modal';
import { Formik, Form, Field, useFormikContext } from 'formik';
import {
    FiltersTxnsFormInitialValues,
    ModalTransactionsFiltersProps,
    TransactionTypes,
    TypeSelectedFilters,
} from './FiltersConsts';

/** Modal for transactions listing filters selections */
export const ModalTransactionsFilters: React.FC<ModalTransactionsFiltersProps> = ({
    onFilterApply,
    onCloseModal,
    filtersSelected,
}) => {
    const [initialValues, setInitialValues] = useState(FiltersTxnsFormInitialValues);

    useEffect(() => {
        setInitialValues(filtersSelected);
    }, [filtersSelected]);
    const handleApplyFilter = useCallback(
        (values: TypeSelectedFilters) => {
            onFilterApply(values);
        },
        [onFilterApply]
    );

    // const handleAmountChange = useCallback((e) => {
    //     const value = e.target.value;
    //     if (value.length > 0) {
    //         setDisableMaxMinAmount(true);
    //     } else {
    //         setDisableMaxMinAmount(false);
    //     }
    //     handleChange(e);
    // }, []);

    return (
        <Modal title="Filters" onCloseModal={onCloseModal} className="Modal-Filters-Txns">
            <div className="FilterSection">
                <h3>Filter Options</h3>

                <Formik
                    initialValues={initialValues}
                    onSubmit={handleApplyFilter}
                    enableReinitialize
                >
                    {({ resetForm, setFieldValue, values }) => (
                        <Form>
                            <div className="form-group">
                                <label htmlFor="direction">Direction</label>
                                <Field
                                    as="select"
                                    id="TransactionDirection__Direction"
                                    name="TransactionDirection__Direction"
                                >
                                    <option value="">All</option>
                                    <option value="Inbound">Inbound</option>
                                    <option value="Outbound">Outbound</option>
                                </Field>
                            </div>

                            <div className="form-group">
                                <label htmlFor="type">Transaction Type</label>
                                <Field
                                    as="select"
                                    id="TransactionTypes__Type"
                                    name="TransactionTypes__Type"
                                >
                                    <option value="">All</option>
                                    {TransactionTypes.map((type) => (
                                        <option key={type} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </Field>
                            </div>

                            <div className="form-group">
                                <label htmlFor="amount">Amount</label>
                                <Field
                                    type="number"
                                    id="filterExactAmount"
                                    name="filterExactAmount"
                                    placeholder="Exact amount"
                                    value={
                                        values['CustomerAssetAccountsTransactions__Amount']
                                            ?.amount ?? ''
                                    }
                                    onChange={(e: { target: { value: any } }) => {
                                        const value = e.target.value;
                                        setFieldValue('CustomerAssetAccountsTransactions__Amount', {
                                            amount: value,
                                            minAmount: '',
                                            maxAmount: '',
                                        });
                                    }}
                                />
                            </div>

                            <div className="form-group amount-range">
                                <label htmlFor="amountRange">Amount Range</label>
                                <div className="range-group">
                                    <Field
                                        type="number"
                                        id="CustomerAssetAccountsTransactions__Amount__From"
                                        name="CustomerAssetAccountsTransactions__Amount"
                                        placeholder="Min amount"
                                        value={
                                            values['CustomerAssetAccountsTransactions__Amount']
                                                ?.minAmount ?? ''
                                        }
                                        max={ values['CustomerAssetAccountsTransactions__Amount']
                                            ?.maxAmount ?? ''}
                                        onChange={(e: { target: { value: any } }) => {
                                            const value = e.target.value;
                                            setFieldValue(
                                                'CustomerAssetAccountsTransactions__Amount',
                                                {
                                                    amount: '',
                                                    minAmount: value,
                                                    maxAmount:
                                                        values[
                                                            'CustomerAssetAccountsTransactions__Amount'
                                                        ]?.maxAmount,
                                                }
                                            );
                                        }}
                                    />
                                    <span>to</span>
                                    <Field
                                        type="number"
                                        id="CustomerAssetAccountsTransactions__Amount__To"
                                        name="CustomerAssetAccountsTransactions__Amount"
                                        placeholder="Max amount"
                                        value={
                                            values['CustomerAssetAccountsTransactions__Amount']
                                                ?.maxAmount ?? ''
                                        }
                                       
                                        onChange={(e: { target: { value: any } }) => {
                                            const value = e.target.value;
                                            setFieldValue(
                                                'CustomerAssetAccountsTransactions__Amount',
                                                {
                                                    amount: '',
                                                    minAmount:
                                                        values[
                                                            'CustomerAssetAccountsTransactions__Amount'
                                                        ]?.minAmount,
                                                    maxAmount: value,
                                                }
                                            );
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="payeeName">Payee/Originator Name</label>
                                <Field
                                    type="text"
                                    id="CustomerAssetAccountsExternalTransfers__Name"
                                    name="CustomerAssetAccountsExternalTransfers__Name"
                                    placeholder="includes"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="swiftCode">Bic / Routing Number</label>
                                <Field
                                    type="text"
                                    id="filterBicOrRoutingNumber"
                                    name="filterBicOrRoutingNumber"
                                    placeholder="Bic / Routing Number"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="accountNumber">Account Number</label>
                                <Field
                                    type="text"
                                    id="CustomerAssetAccountsExternalTransfers__AchAccountNumber"
                                    name="CustomerAssetAccountsExternalTransfers__AchAccountNumber"
                                    placeholder="Account Number"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="purpose">Memo / Description / Purpose</label>
                                <Field
                                    type="text"
                                    id="filterMemoOrDesc"
                                    name="filterMemoOrDesc"
                                    placeholder="Includes"
                                />
                            </div>
                            {/* <div className="form-group"/> */}
                            <div className="form-group">
                                <div className="form-actions">
                                    <Button
                                        priority="secondary"
                                        type={'button'}
                                        onClick={() => {
                                            // setInitialValues(FiltersTxnsFormInitialValues);
                                            resetForm({ values: FiltersTxnsFormInitialValues });
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button priority="primary" color="third" type={'submit'}>
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
};
