import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { TransferType } from 'pages/crypto/Crypto';

export type CryptoExchangeItem = {
    pairedAssetsId: number;
    assetsId: number;
    pairedAsset: string; // e.g. USD if we've selected to view USD exchanges
    asset: string;
    sellPrice: number; // selling 1 basecurrency gets you this amount of exchangecurrency
    buyPrice: number; // this amount of exchangecurrency will buy 1 basecurrency
    bFavourited: boolean;
    bBuyAvailable: boolean;
    bSellAvailable: boolean; // TODO: Handle this
    maxTradeSize: number;
    minTradeSize: number;
};

export type ExchangeType = 'Buy' | 'Sell'; // buy means buying asset with pairedAsset. Sell means selling asset for pairedAsset

export type ExchangeDetails = {
    pairedAsset: string;
    asset: string;
    sourceAsset: string;
    destinationAsset: string;
    fromAccounts: ExchangeAccountDetails[];
    mergedToAccounts: MergedToAccountDetails[];
};

export type QuantityType = 'Source' | 'Destination';

export type GetExchangeDetailsResponse = {
    sourceAsset: string;
    destinationAsset: string;
    fromAccounts: ExchangeAccountDetails[];
    toAccounts: ExchangeAccountDetails[];
    availablePayees: PayeeDetails[];
};

export type ExchangeAccountDetails = {
    accountID: number; // can be null if the account is not yet created
    displayName: string;
    currencySymbol: string;
    balance: number;
    assetID: number | null; // paired asset  - if a source account is dUSD we need to set pairedAssetId to dUSD on the form
};

export type PayeeDetails = {
    payeesId: number;
    assetCode: string;
    payeeLabel: string;
    allowedTransferTypes: TransferType[];
    customerAssetAccountsId: number;
    availablePurposeCodes: { code: string; description: string }[] | null;
};

export type MergedToAccountDetails = {
    accountID: number | null;
    payeesId: number | null;
    genericId: string; // A+number for account, P+number for payee
    type: 'Payee' | 'Account';
    label: string;
    balance: number | null;
    currencySymbol: string;
    allowedTransferTypes: TransferType[] | null;
    assetId: number | null; // paired asset - if a source account is dUSD we need to set pairedAssetId to dUSD on the form
    availablePurposeCodes: { code: string; description: string }[] | null;
};

export const payeeAsMergedAccount = (payee: PayeeDetails): MergedToAccountDetails => ({
    accountID: null,
    payeesId: payee.payeesId,
    genericId: `P${payee.payeesId}`,
    type: 'Payee',
    label: payee.payeeLabel,
    balance: null,
    currencySymbol: '$',
    allowedTransferTypes: payee.allowedTransferTypes,
    assetId: null,
    availablePurposeCodes: payee.availablePurposeCodes,
});

export const accountAsMergedAccount = (acc: ExchangeAccountDetails): MergedToAccountDetails => ({
    accountID: acc.accountID,
    genericId: `A${acc.accountID}`,
    payeesId: null,
    type: 'Account',
    label: acc.displayName,
    balance: acc.balance,
    currencySymbol: acc.currencySymbol,
    allowedTransferTypes: null,
    assetId: acc.assetID,
    availablePurposeCodes: null,
});
