import { useEffect, useState } from 'react';
import { ProductType } from '../../../components/sideMenu/SideMenu';
import {
    parseCountryFromPayee,
    parseFeeOptionsFromTypeAndPayee,
    parsePayee,
    parseTransferTypeFromPayee,
} from './csvDataParsers';
import {
    ADDRESS_COUNTRY_LABEL,
    csvCellConfig,
    FEE_LABEL,
    matchAccount,
    matchFeeOption,
    matchState,
    matchToPurpose,
    matchTransferType,
    TRANSMITTER_COUNTRY_LABEL,
    transmitterFieldsHide,
    transmitterFieldsKeep,
} from './helpers';
import {
    BulkTransferOptions,
    ParsedCsv,
    PurposeOptionsResponse,
    TransferTypeOptionsResponse,
} from './models';
import { useQueryClient } from '@tanstack/react-query';
import { endpoints } from '../../../endpoints.config';
import api, { ApiResponse } from '../../../api';
import { useIsFinancialInstitution } from '../Payees/helpers';
import { TransmitterType } from '../Payees/EditPayee';
import { isDbsOrBc } from './validationSchema';

export const TRANSFER_OPTIONS_KEY = 'transferOptions';
export const PURPOSE_OPTIONS_KEY = 'purposeOptions';

//we use this symbol instead of sending null when we can't match a payee. This indicates that the user has tried to provide a payee but has not made a match, rather than no payee provided
export const PAYEE_MISMATCH_SYMBOL = Symbol('Payee mismatch');
export const COUNTRY_MISMATCH_SYMBOL = Symbol('Country mismatch');

export const fetchTransferOptions = async (
    accountId: number | string,
    _countryCode: string | null | symbol
) => {
    const countryCode = typeof _countryCode !== 'symbol' ? _countryCode : null;
    try {
        const res = await api.get<ApiResponse<TransferTypeOptionsResponse>>(
            endpoints.accounts.transferTypeOptions,
            { params: { customerAssetAccountsId: accountId, countryCode: countryCode } }
        );
        if (res.data.status === '1')
            return {
                key: `${accountId}-${countryCode}`,
                transferOptions: res.data.details.transferTypes,
            };
        else throw new Error();
    } catch (error) {
        return { key: `${accountId}-${countryCode}`, transferOptions: [] };
    }
};
export const fetchPurposeOptions = async (
    accountId: number | string,
    _countryCode: string | null | symbol
) => {
    const countryCode = typeof _countryCode !== 'symbol' ? _countryCode : null;
    try {
        const res = await api.get<ApiResponse<PurposeOptionsResponse>>(
            endpoints.accounts.getPurposeCodes,
            {
                params: { customerAssetAccountsId: accountId, countryCode: countryCode },
            }
        );
        if (res.data.status === '1')
            return {
                key: `${accountId}-${countryCode}`,
                purposeCodes: res.data.details.purposeCodes,
            };
        else throw new Error();
    } catch (error) {
        return { key: `${accountId}-${countryCode}`, purposeCodes: [] };
    }
};

const setNestedValue = (obj: any, fieldName: string, value: any): void => {
    const keys = fieldName.split('.');
    let current = obj;

    keys.forEach((key, index) => {
        if (index === keys.length - 1) {
            current[key] = value;
        } else {
            current[key] = current[key] || {};
            current = current[key];
        }
    });
};

type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;
export type CachedTransferOptions = Awaited<ReturnType<typeof fetchTransferOptions>>;
export type CachedPurposeOptions = Awaited<ReturnType<typeof fetchPurposeOptions>>;

type Data =
    | {
          [key: string]: string;
      }[]
    | undefined;

export const useParseCsvResults = (
    data: Data,
    accountId: number | undefined,
    accounts: BulkTransferOptions['accounts'],
    countries: BulkTransferOptions['countries']
): { parsedData: ParsedCsv[] | null; missingColumns: string[] } => {
    const bFinancialInstitution = useIsFinancialInstitution();
    const [missingColumns, setMissingColumns] = useState<string[]>([]);
    const [purposeCodesNeeded, setPurposeCodesNeeded] = useState<
        { key: string; transfers: { index: number; givenPurpose: string | undefined }[] }[]
    >([]);
    const [transferTypesNeeded, setTransferTypesNeeded] = useState<
        {
            key: string;
            transfers: {
                index: number;
                transferType: string | undefined;
                feeId: string | undefined;
            }[];
        }[]
    >([]);
    const [parsedData, setParsedData] = useState<ParsedCsv[] | null>(null);
    const [finalData, setFinalData] = useState<ParsedCsv[] | null>(null);

    const queryClient = useQueryClient();

    const handleStoreNeededTransfer = (
        accountId: number,
        countryCode: string | null | symbol,
        transferType: string | undefined,
        feeId: string | undefined,
        index: number
    ) => {
        const key = `${accountId}-${typeof countryCode !== 'symbol' ? countryCode : null}`;
        const hasKeyIndex = transferTypesNeeded.findIndex((type) => type.key === key);
        if (hasKeyIndex !== -1)
            setTransferTypesNeeded((prev) =>
                prev.map((trans, i) =>
                    i === hasKeyIndex
                        ? {
                              ...trans,
                              transfers: [...trans.transfers, { index, feeId, transferType }],
                          }
                        : trans
                )
            );
        else
            setTransferTypesNeeded((prev) => [
                ...prev,
                {
                    key,
                    transfers: [{ index, feeId, transferType }],
                },
            ]);
    };
    const handleStoreNeededPurpose = (
        accountId: number,
        countryCode: string | null | symbol,
        givenPurpose: string | undefined,
        index: number
    ) => {
        const key = `${accountId}-${typeof countryCode !== 'symbol' ? countryCode : null}`;
        const hasKeyIndex = purposeCodesNeeded.findIndex((type) => type.key === key);
        if (hasKeyIndex !== -1)
            setPurposeCodesNeeded((prev) =>
                prev.map((purpose, i) =>
                    i === hasKeyIndex
                        ? {
                              ...purpose,
                              transfers: [...purpose.transfers, { index, givenPurpose }],
                          }
                        : purpose
                )
            );
        else
            setPurposeCodesNeeded((prev) => [
                ...prev,
                {
                    key,
                    transfers: [{ index, givenPurpose }],
                },
            ]);
    };

    // Parse data from labels to fieldNames
    const pareseData = (
        data:
            | {
                  [key: string]: string;
              }[]
            | undefined
    ) => {
        return (data ?? []).map((transfer: any, transferIndex) => {
            transfer.bFinancialInstitution = bFinancialInstitution;
            csvCellConfig.forEach(async (header, j) => {
                // If the user is on an account page the default template won't have the account id so we set it here

                if (header.fieldValue === 'sourceAccountId' && !!accountId) {
                    const account = matchAccount(
                        accountId ?? transfer[header.fieldValue],
                        accounts
                    );
                    transfer.productType = account?.productDisplayName;
                    transfer.currencyCode = account?.currencyCode;
                }

                if ((transfer as Object).hasOwnProperty(header.label)) {
                    // This parsing is basic parsing where the field can be parsed irrespective of other fields
                    // We're also swapping out the field labels for actual field names eg "Bank Name" -> "bankName"
                    if (!!header.parser)
                        setNestedValue(
                            transfer,
                            header.fieldValue,
                            header.parser(transfer[header.label], accounts, countries)
                        );
                    else setNestedValue(transfer, header.fieldValue, transfer[header.label]);

                    delete transfer[header.label];

                    /* ---------------------------------------------------------- 
                    Any parsing done below here is because the field depends 
                    on other fields to be parsed other than itself
                ------------------------------------------------------------*/
                    if (header.fieldValue === 'sourceAccountId') {
                        // In case the user used the template with the source account and uploaded different accounts than the account page we reset to the account pages account
                        if (!!accountId) transfer.sourceAccountId = accountId;
                        const account = matchAccount(
                            accountId ?? transfer[header.fieldValue],
                            accounts
                        );
                        transfer.productType = account?.productDisplayName;
                        transfer.currencyCode = account?.currencyCode;
                    } else if (header.fieldValue === 'payeeId') {
                        // For existing payees this depends on the selected account so we need to pass it the parsed account
                        const payee = parsePayee(
                            transfer[header.fieldValue],
                            accountId ?? transfer.sourceAccountId,
                            accounts
                        );
                        transfer.payeeId = payee;
                    } else if (header.fieldValue === 'state' && !transfer.payeeId) {
                        // Because state is listed before country in the config it will be stored under it's label still
                        const country =
                            transfer.addressCountryCode ?? transfer[ADDRESS_COUNTRY_LABEL];
                        const selectedCountry = countries.find((c) => c.countryISO3 === country);
                        if (!selectedCountry) transfer[header.fieldValue] = '';
                        else {
                            const state = matchState(transfer[header.fieldValue], selectedCountry);
                            transfer[header.fieldValue] = state ?? '';
                        }
                    } else if (
                        header.fieldValue === 'transmitter.dateOfBirth' &&
                        !transfer.payeeId &&
                        bFinancialInstitution
                    ) {
                        // Remove DOB if transmitter type is company
                        const transmitterType = transfer.transmitter.type as TransmitterType | '';
                        if (!transmitterType || transmitterType === TransmitterType.Company)
                            setNestedValue(transfer, header.fieldValue, '');
                        // else do nothing as it's been set as it should be already
                    } else if (
                        header.fieldValue === 'transmitter.state' &&
                        !transfer.payeeId &&
                        bFinancialInstitution
                    ) {
                        // Because state is listed before country in the config it will be stored under it's label still
                        const country =
                            transfer.transmitter.countryCode ?? transfer[TRANSMITTER_COUNTRY_LABEL];
                        const selectedCountry = countries.find((c) => c.countryISO3 === country);
                        if (!selectedCountry) setNestedValue(transfer, header.fieldValue, '');
                        else {
                            const state = matchState(transfer.transmitter.state, selectedCountry);
                            setNestedValue(transfer, header.fieldValue, state ?? '');
                        }
                    } else if (header.fieldValue === 'transferType') {
                        /* For transfer types this depends on either on the existing payee or BE response. I'm just going to check for
                    existing payee transfer types here. Once the items render I'll call the BE */
                        if (transfer.payeeId) {
                            const transferType = parseTransferTypeFromPayee(
                                transfer[header.fieldValue],
                                accountId ?? transfer.sourceAccountId,
                                transfer.payeeId,
                                accounts
                            );
                            transfer.transferType = transferType;
                        } else if (
                            !transfer.sourceAccountId ||
                            !transfer.countryCode ||
                            transfer.countyCode === COUNTRY_MISMATCH_SYMBOL
                        ) {
                            transfer.transferType = null;
                        } else {
                            /* Here as we don't have a payee we'll need to call an endpoint to get the transfer options 
                            for a payee of the configuration given. The BE uses the account and country code to determine this */
                            handleStoreNeededTransfer(
                                transfer.sourceAccountId,
                                transfer.countryCode,
                                transfer.transferType,
                                transfer.feeId ?? transfer[FEE_LABEL],
                                transferIndex
                            );
                        }
                    } else if (header.fieldValue === 'purpose') {
                        /* If no payee is given and we're mising the account or country 
                        we can't fetch the available purpose codes so set it to empty
                        as there is nothing to match it to */
                        if (
                            !transfer.payeeId &&
                            (!transfer.sourceAccountId || !transfer.countryCode)
                        ) {
                            transfer.purpose = '';
                        } else {
                            /* Each account & country combo could have a different purpose code list 
                            We need to store that option to be fetched. We store it so we don't keep making repeat calls*/
                            if (transfer.payeeId) {
                                const country = parseCountryFromPayee(
                                    accountId ?? transfer.sourceAccountId,
                                    transfer.payeeId,
                                    accounts,
                                    countries
                                );
                                if (!country) {
                                    transfer.purpose = '';
                                } else {
                                    handleStoreNeededPurpose(
                                        accountId ?? transfer.sourceAccountId,
                                        country,
                                        transfer.purpose,
                                        transferIndex
                                    );
                                }
                            } else {
                                handleStoreNeededPurpose(
                                    transfer.sourceAccountId,
                                    transfer.countryCode,
                                    transfer.purpose,
                                    transferIndex
                                );
                            }
                        }
                    } else if (header.fieldValue === 'feeId') {
                        // If the input isn't filled out use the default split of id null;
                        if (!transfer[header.fieldValue]) {
                            transfer.feeId = null;
                        }
                        // If the input was filled out but no matching transfer type has been found reset to undefined to show error
                        else if (
                            transfer[header.fieldValue] &&
                            !transfer.payeeId &&
                            !transfer.transferType
                        ) {
                            // If fee label was given but not transfer type then we should reset the given fee
                            transfer.feeId = undefined;
                        } else if (transfer.payeeId) {
                            /* Otherwise try to match the fee split they asked for. Failing to do so set to undefined (returned from the function) 
                             so they have to pick which fee. If we just set to the default when they've input something it could mean we set a fee
                             option they aren't expecting. Better to notify with an error */

                            if (!transfer[header.fieldValue]) {
                                transfer.feeId = null;
                            } else {
                                /* Similar to the above */
                                const feeOption = parseFeeOptionsFromTypeAndPayee(
                                    transfer[header.fieldValue],
                                    accountId ?? transfer.sourceAccountId,
                                    transfer.payeeId,
                                    transfer.transferType,
                                    accounts
                                );
                                transfer.feeId = feeOption;
                            }
                        }
                        // Next few checks will be for the various bank details
                    } else if (header.fieldValue === 'routingNumber' && transfer.sourceAccountId) {
                        const bIsDbsOrBc = isDbsOrBc(transfer.productType);
                        const bIsCRB = transfer.productType === ProductType.CRB;
                        const bIsGLDB = transfer.productType === ProductType.GLDB;
                        const bInternational =
                            transfer.countryCode && transfer.countryCode !== 'USA';
                        // If a routing number is given when it shouldn't be we should reset it
                        if (bIsDbsOrBc || (bIsCRB && bInternational) || bIsGLDB || transfer.payeeId)
                            transfer.routingNumber = '';
                    } else if (header.fieldValue === 'swiftNumber' && transfer.sourceAccountId) {
                        const bIsCRB = transfer.productType === ProductType.CRB;
                        const bInternational =
                            transfer.countryCode && transfer.countryCode !== 'USA';
                        // If a swift number is given when it shouldn't be we should reset it
                        if ((bIsCRB && !bInternational) || transfer.payeeId)
                            transfer.swiftNumber = '';
                    } else if (
                        header.fieldValue === 'intermediaryBic' &&
                        transfer.sourceAccountId
                    ) {
                        const bIsDbsOrBc = isDbsOrBc(transfer.productType);
                        const bIsGLDB = transfer.productType === ProductType.GLDB;
                        // If a intermediaryBic is given when it shouldn't be we should reset it
                        if (!(bIsDbsOrBc || bIsGLDB) || transfer.payeeId)
                            transfer.intermediaryBic = '';
                    } else if (header.fieldValue === 'accountNumber' && transfer.sourceAccountId) {
                        const bIsCRB = transfer.productType === ProductType.CRB;
                        const bIsGLDB = transfer.productType === ProductType.GLDB;
                        const bInternational =
                            transfer.countryCode && transfer.countryCode !== 'USA';
                        // If a accountNumber is given when it shouldn't be we should reset it
                        if ((bIsCRB && bInternational) || bIsGLDB || transfer.payeeId)
                            transfer.accountNumber = '';
                    } else if (header.fieldValue === 'iban' && transfer.sourceAccountId) {
                        const bIsDbsOrBc = isDbsOrBc(transfer.productType);
                        const bIsCRB = transfer.productType === ProductType.CRB;
                        const bInternational =
                            transfer.countryCode && transfer.countryCode !== 'USA';
                        // If a iban is given when it shouldn't be we should reset it
                        if (bIsDbsOrBc || (bIsCRB && !bInternational) || transfer.payeeId)
                            transfer.iban = '';
                        /* Next checks will just clear up the payee fields if an existing payee is selected.
                    I think the BE would ignore these fields anyway if a payeeId is given but it's clearer 
                    if we tidy these up */
                    } else if (header.fieldValue === 'payeesReference' && transfer.payeeId) {
                        transfer.payeesReference = '';
                    } else if (header.fieldValue === 'payeeType' && transfer.payeeId) {
                        transfer.payeeType = '';
                    } else if (header.fieldValue === 'bankName' && transfer.payeeId) {
                        transfer.bankName = '';
                    } else if (header.fieldValue === 'countryCode' && transfer.payeeId) {
                        transfer.countryCode = '';
                    } else if (header.fieldValue === 'accountName' && transfer.payeeId) {
                        transfer.accountName = '';
                    } else if (header.fieldValue === 'addressLine1' && transfer.payeeId) {
                        transfer.addressLine1 = '';
                    } else if (header.fieldValue === 'addressLine2' && transfer.payeeId) {
                        transfer.addressLine2 = '';
                    } else if (header.fieldValue === 'townCity' && transfer.payeeId) {
                        transfer.townCity = '';
                    } else if (header.fieldValue === 'state' && transfer.payeeId) {
                        transfer.state = '';
                    } else if (header.fieldValue === 'postCode' && transfer.payeeId) {
                        transfer.postCode = '';
                    } else if (header.fieldValue === 'addressCountryCode' && transfer.payeeId) {
                        transfer.addressCountryCode = '';
                    } else if (
                        header.fieldValue === 'transmitter.firstPartyTransfer' &&
                        transfer.payeeId &&
                        bFinancialInstitution
                    ) {
                        transfer.transmitter.firstPartyTransfer = '';
                    } else if (
                        header.fieldValue === 'transmitter.type' &&
                        // If there is a payee selected or if it's first party transfer then reset these fields
                        (transfer.payeeId || !!transfer.transmitter.firstPartyTransfer) &&
                        bFinancialInstitution
                    ) {
                        transfer.transmitter.type = '';
                    } else if (
                        header.fieldValue === 'transmitter.name' &&
                        // If there is a payee selected or if it's first party transfer then reset these fields
                        (transfer.payeeId || !!transfer.transmitter.firstPartyTransfer) &&
                        bFinancialInstitution
                    ) {
                        transfer.transmitter.name = '';
                    } else if (
                        header.fieldValue === 'transmitter.dateOfBirth' &&
                        // If there is a payee selected or if it's first party transfer then reset these fields
                        (transfer.payeeId || !!transfer.transmitter.firstPartyTransfer) &&
                        bFinancialInstitution
                    ) {
                        transfer.transmitter.dateOfBirth = '';
                    } else if (
                        header.fieldValue === 'transmitter.accountNumber' &&
                        (transfer.payeeId || !!transfer.transmitter.firstPartyTransfer) &&
                        bFinancialInstitution
                    ) {
                        transfer.transmitter.accountNumber = '';
                    } else if (
                        header.fieldValue === 'transmitter.addressLine1' &&
                        (transfer.payeeId || !!transfer.transmitter.firstPartyTransfer) &&
                        bFinancialInstitution
                    ) {
                        transfer.transmitter.addressLine1 = '';
                    } else if (
                        header.fieldValue === 'transmitter.addressLine2' &&
                        (transfer.payeeId || !!transfer.transmitter.firstPartyTransfer) &&
                        bFinancialInstitution
                    ) {
                        transfer.transmitter.addressLine2 = '';
                    } else if (
                        header.fieldValue === 'transmitter.townCity' &&
                        (transfer.payeeId || !!transfer.transmitter.firstPartyTransfer) &&
                        bFinancialInstitution
                    ) {
                        transfer.transmitter.townCity = '';
                    } else if (
                        header.fieldValue === 'transmitter.state' &&
                        (transfer.payeeId || !!transfer.transmitter.firstPartyTransfer) &&
                        bFinancialInstitution
                    ) {
                        transfer.transmitter.state = '';
                    } else if (
                        header.fieldValue === 'transmitter.postcode' &&
                        (transfer.payeeId || !!transfer.transmitter.firstPartyTransfer) &&
                        bFinancialInstitution
                    ) {
                        transfer.transmitter.postcode = '';
                    } else if (
                        header.fieldValue === 'transmitter.countryCode' &&
                        (transfer.payeeId || !!transfer.transmitter.firstPartyTransfer) &&
                        bFinancialInstitution
                    ) {
                        transfer.transmitter.countryCode = '';
                    }
                } else if (transferIndex === 0) {
                    if (header.fieldValue === 'sourceAccountId' && !!accountId) return;
                    if (
                        (!bFinancialInstitution &&
                            transmitterFieldsKeep.includes(header.fieldValue as any)) ||
                        (bFinancialInstitution &&
                            transmitterFieldsHide.includes(header.fieldValue as any))
                    )
                        return;
                    // Add to missing column array
                    setMissingColumns((prev) => [...prev, `"${header.label}"`]);
                }
            });

            /*
            The user might have uploaded a csv with transmitter info. If they did and they shouldn't
            have (not a FI) then we just clear the transmitter info here
            We clear memo for FIs as we don't have the memo field for them
            */
            if (!bFinancialInstitution) transfer.transmitter = null;
            else transfer.memo = '';

            return transfer;
        });
    };

    const processData = async (data: Data) => {
        const formattedData: ParsedCsv[] = pareseData(data);
        setParsedData(formattedData);
    };
    const fetchPayeeTransferTypes = async (parsedData: ParsedCsv[]) => {
        const data = parsedData;
        // Fetch details for each country account combo so we can validate transfer type and fee
        // Store with react-query to cache reults for later
        const transferPromises = transferTypesNeeded.map((transfer) => {
            const accountId = transfer.key.split('-')[0];
            const countryCode = transfer.key.split('-')[1];
            return queryClient.fetchQuery([TRANSFER_OPTIONS_KEY, accountId, countryCode], () =>
                fetchTransferOptions(accountId, countryCode)
            );
        });
        const transfers = await Promise.all(transferPromises);
        transferTypesNeeded.forEach((type) => {
            const transferOptions = transfers.find((transfer) => transfer.key === type.key);
            // Loop through each type to find check the items csv value against available options
            type.transfers.forEach((transferOpt) => {
                const transfer = matchTransferType(
                    transferOpt.transferType,
                    transferOptions?.transferOptions ?? []
                );
                if (!transfer) {
                    // Set the csv entry for transfer and fee to null. The index for the field is saved under transferOpt.index
                    data[transferOpt.index].transferType = undefined;
                    data[transferOpt.index].feeId = undefined;
                    return;
                }
                const fee = matchFeeOption(transferOpt.feeId ?? '', transfer?.feeOptions ?? []);
                if (!fee && fee !== null) {
                    // Set transfer type to value and fee to null. The index for the field is saved under transferOpt.index
                    data[transferOpt.index].transferType = transfer.transferType;
                    data[transferOpt.index].feeId = undefined;
                    return;
                }
                // Set transfer type and fee to found values. The index for the field is saved under transferOpt.index
                data[transferOpt.index].transferType = transfer.transferType;
                data[transferOpt.index].feeId = fee;
                return;
            });
        });
        // Now do a similar process for the purpose codes for each transfer
        fetchTransferPurposeCodes(data);
    };
    const fetchTransferPurposeCodes = async (parsedData: ParsedCsv[]) => {
        const data = parsedData;
        // Fetch details for each country account combo so we can validate the purpose and fee
        // Store with react-query to cache reults for later as these won't change frequently
        const purposePromises = purposeCodesNeeded.map((transfer) => {
            const accountId = transfer.key.split('-')[0];
            const countryCode = transfer.key.split('-')[1];
            return queryClient.fetchQuery([PURPOSE_OPTIONS_KEY, accountId, countryCode], () =>
                fetchPurposeOptions(accountId, countryCode)
            );
        });
        const transfers = await Promise.all(purposePromises);
        purposeCodesNeeded.forEach((purpose) => {
            const transferOptions = transfers.find((transfer) => transfer.key === purpose.key);
            // Loop through each type to find check the items csv value against available options
            purpose.transfers.forEach((purposeOpt) => {
                const transfer = matchToPurpose(
                    purposeOpt.givenPurpose,
                    transferOptions?.purposeCodes ?? []
                );
                if (!transfer) {
                    // Set the csv entry for transfer and fee to null. The index for the field is saved under transferOpt.index
                    data[purposeOpt.index].purpose = '';
                    return;
                }
                // Set transfer type and fee to found values. The index for the field is saved under transferOpt.index
                data[purposeOpt.index].purpose = transfer.code;
                return;
            });
        });
        // Update the transfer listed in transferTypes needed
        // Save this result as the final parsed data to trigger list display
        setFinalData(data);
    };

    useEffect(() => {
        if (!data) return;
        processData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    useEffect(() => {
        if (!parsedData) return;
        fetchPayeeTransferTypes(parsedData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsedData]);

    return { parsedData: finalData, missingColumns };
};
