import { ModalTransactionsFilters } from 'components/transactionsTableFilters/ModalTransactionsFilters';
import React, { useCallback, useState } from 'react';
import FilterIcon from 'assets/ibanera/Icon_Btn_04.png';
import './FiltersStyles.scss';
import {
    appendExtraFilters,
    FilterButtonProps,
    FiltersTxnsFormInitialValues,
    getOperatorForFilter,
    TypeSelectedFilters,
} from './FiltersConsts';
import { Filter } from 'components/categoryHelpers/filterBuilder/FilterBuilder';

/**
 * Component that renders a filter icon button and manages filter modal and state handling within it.
 */
const TransactionsFilterButton = ({ applyFilter }: FilterButtonProps) => {
    //Local state to show modal for filters selection.
    const [showFilterModal, setShowFilterModal] = useState(false);

    const [selectedFiltersCount, setSelectedFiltersCount] = useState(0);

    const [selectedFilters, setSelectedFilters] = useState(FiltersTxnsFormInitialValues);

    // handle filter button click to view or hide filter modal.
    const handleFilterBtnClick = useCallback(
        () => setShowFilterModal((previousState) => !previousState),
        []
    );

    /**
     * Callback function from the modal to apply filters.
     * Updates the state with the selected filters, hides the modal, and triggers the parent callback to handle the applied filters.
     * Calculates the count of selected filters and update it to state to view on bubble
     *
     * @param {TypeSelectedFilters} formValues - The object containing the selected filter values.
     */
    const onFilterApply = useCallback(
        (formValues: TypeSelectedFilters) => {
            setSelectedFilters(formValues);
            setShowFilterModal(false);

            (async () => {
                const filters: Filter[] = [];
                const params: { [k: string]: any } = {};
                const keys = Object.keys(formValues) as Array<keyof TypeSelectedFilters>;
                let selectedCounter = 0;
                for (let key of keys) {
                    const value = formValues[key];
                    if (value !== null && value !== undefined && value !== '') {
                        // eslint-disable-next-line no-console

                        selectedCounter++;
                        let operator = getOperatorForFilter(key);
                        if (operator) {
                            // const valueToSend = getValueForFilter(key, value);
                            filters.push({
                                columnKey: key,
                                operator: operator,
                                value: String(value ?? "").trim(),
                            });
                        }

                        appendExtraFilters(key, value, filters, params);
                    }
                }
                applyFilter(filters, params);

                setSelectedFiltersCount(selectedCounter);
            })();
        },
        [applyFilter]
    );

    return (
        <>
            <button onClick={handleFilterBtnClick} className="filter-button" title="Apply filters">
                <img className="FilterIcon" src={FilterIcon} alt="Filter" />
                {selectedFiltersCount > 0 && (
                    <span className={'filter-count'}>{selectedFiltersCount}</span>
                )}
            </button>
            {/** Filters modal rendered based on show filter modal flag */}
            {showFilterModal && (
                <ModalTransactionsFilters
                    onCloseModal={handleFilterBtnClick}
                    onFilterApply={onFilterApply}
                    filtersSelected={selectedFilters}
                />
            )}
        </>
    );
};

export default TransactionsFilterButton;
