import React from 'react';
import { useDispatch } from 'react-redux';
import { buildPaginationProps } from './models';
import { NextButtons, PreviousButtons } from './PaginationButtons';
import { PaginationConfig } from './PaginationConfig';
import { PaginationInfo } from './PaginationInfo';

interface TablePagination {
    loading: boolean;
    error?: any;
    data?: {
        details: {
            firstOnPage: number;
            pageNumber: number;
            lastOnPage: number;
            hasNextPage: boolean;
            hasPrevPage: boolean;
            resultsCount: number;
            pageSize: number;
        };
        actions: {
            goToNextPage(): void;
            goToPrevPage(): void;
            changePageSize(size: number): void;
            goToPage(page: number): void;
        };
    };
}

function Pagination<T extends TablePagination>({ table }: { table: T }) {
    const { loading, data, error } = table;
    const dispatch = useDispatch();

    const clearSelections = () => {
        dispatch({
            type: 'app/SET_SELECTED_IDS',
            selectedIds: [],
        });
        dispatch({
            type: 'app/SET_SELECTED_USER_NAMES',
            selectedUserNames: [],
        });
        dispatch({
            type: 'app/REFRESH_TABLE',
        });
    };

    if (loading && !data) return null;

    if (error) throw error;

    if (data) {
        const {
            firstOnPage,
            pageNumber,
            lastOnPage,
            hasNextPage,
            hasPrevPage,
            resultsCount,
            pageSize,
        } = data.details;

        const { goToNextPage, goToPrevPage, goToPage, changePageSize } = data.actions ?? {};
        const lastPage = Math.ceil(resultsCount / pageSize);

        const {
            paginationConfigProps,
            previousButtonsProps,
            nextButtonsProps,
            paginationInfoProps,
        } = buildPaginationProps({
            pageNumber,
            pageSize,
            lastPage,
            firstOnPage,
            lastOnPage,
            resultsCount,
            hasPrevPage,
            hasNextPage,
            changePageSize,
            goToPrevPage,
            goToNextPage,
            goToPage,
            clearSelections,
        });

        return (
            <div className="TableNavigation">
                <div className="PaginatorButtons">
                    <PreviousButtons {...previousButtonsProps} />
                    <PaginationConfig {...paginationConfigProps} />
                    <NextButtons {...nextButtonsProps} />
                </div>
                <PaginationInfo {...paginationInfoProps} />
            </div>
        );
    }

    return null;
}

export default Pagination;
