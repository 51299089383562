import * as Yup from 'yup';
import { BulkTransferOptions, ParsedCsv } from './models';
import {
    getFeeFromAccountLevel,
    getFeeFromTransferType,
    matchAccount,
    matchCountry,
    matchPayee,
    matchToPurpose,
} from './helpers';
import { ProductType } from '../../../components/sideMenu/SideMenu';
import { QueryClient } from '@tanstack/react-query';
import {
    CachedPurposeOptions,
    CachedTransferOptions,
    PURPOSE_OPTIONS_KEY,
    TRANSFER_OPTIONS_KEY,
} from './parseCsvResults';
import { transmitterObjectSchema } from '../Payees/helpers';

export const isDbsOrBc = (product: ProductType) =>
    product === ProductType.DBS || product === ProductType.DBS_TMP || product === ProductType.BC;

export const buildValidationSchema = (
    accounts: BulkTransferOptions['accounts'],
    countries: BulkTransferOptions['countries'],
    queryClient: QueryClient
) => {
    return Yup.object().shape({
        tfaCode: Yup.string()
            .required('Please enter your MFA code')
            .min(6, 'Please enter your MFA code'),
        bulkTransfer: Yup.array().of(
            Yup.object().shape({
                // account
                sourceAccountId: Yup.number()
                    .required('Please select an account to transfer from')
                    .nullable(),
                productType: Yup.string(),
                // Payee fields
                payeeId: Yup.mixed()
                    .nullable(true)
                    .test(
                        'payeeMatched',
                        'Payee Not Matched',
                        (value) => value == null || typeof value === 'number'
                    ),
                accountName: Yup.string()
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) => schema.required('Please enter an account name'),
                    }),
                countryCode: Yup.mixed()
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) =>
                            schema
                                .required('Please select a country')
                                .test(
                                    'countryMatched',
                                    'Country Not Matched - Please use a three-letter code e.g. USA',
                                    (value) => value == null || typeof value !== 'symbol'
                                ),
                    }),
                name: Yup.string()
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) => schema.required('Please enter a name'),
                    }),
                addressLine1: Yup.string()
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) => schema.required('Please enter an address'),
                    }),
                townCity: Yup.string()
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) => schema.required('Please enter an town or city'),
                    }),
                postCode: Yup.string()
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) => schema.required('Please enter a valid post/zip code'),
                    }),
                addressCountryCode: Yup.mixed()
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) =>
                            schema
                                .required('Please select a country')
                                .test(
                                    'countryMatched',
                                    'Country Not Matched - Please use a three-letter code e.g. USA',
                                    (value) => value == null || typeof value !== 'symbol'
                                ),
                    }),
                routingNumber: Yup.string()
                    .nullable()
                    .when(['payeeId', 'productType', 'countryCode'], {
                        is: (
                            payeeId: number | null,
                            productType: ProductType,
                            countryCode: string | undefined
                        ) => !payeeId && productType === ProductType.CRB && countryCode === 'USA',
                        then: Yup.string().required('Please enter a routing number'),
                    }),
                accountNumber: Yup.string()
                    .nullable()
                    .when(['payeeId', 'productType', 'countryCode'], {
                        is: (
                            payeeId: number | null,
                            productType: ProductType,
                            countryCode: string | undefined
                        ) =>
                            !payeeId &&
                            (isDbsOrBc(productType) ||
                                (productType === ProductType.CRB && countryCode === 'USA')),
                        then: Yup.string().nullable().required('Please enter an account number'),
                    }),
                swiftNumber: Yup.string()
                    .nullable()
                    .when(['payeeId', 'productType', 'countryCode'], {
                        is: (
                            payeeId: number | null,
                            productType: ProductType,
                            countryCode: string | undefined
                        ) =>
                            !payeeId &&
                            (isDbsOrBc(productType) ||
                                productType === ProductType.GLDB ||
                                (productType === ProductType.CRB && countryCode !== 'USA')),
                        then: Yup.string().required('Please enter a swift number'),
                    }),
                iban: Yup.string()
                    .nullable()
                    .when(['payeeId', 'productType', 'countryCode'], {
                        is: (
                            payeeId: number | null,
                            productType: ProductType,
                            countryCode: string | undefined
                        ) =>
                            !payeeId &&
                            (productType === ProductType.GLDB ||
                                (productType === ProductType.CRB && countryCode !== 'USA')),
                        then: Yup.string().required('Please enter an IBAN'),
                    }),
                intermediaryBic: Yup.string()
                    .nullable()
                    .when('productType', {
                        is: ProductType.BC,
                        then: (schema) => schema.required('Required'),
                    }),
                payeesReference: Yup.string()
                    .max(16, 'Reference cannot be longer than 16 characters')
                    .matches(/^[\w\d\- ]*$/, 'Reference must not contain special characters')
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) => schema.required(`Please enter payee's reference`),
                    }),
                bankName: Yup.string()
                    .max(35, 'Maximum 35 characters')
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) =>
                            schema.required(`Please enter the name of the destination bank`),
                    }),

                transmitter: Yup.object()
                    .nullable()
                    .when(['payeeId', 'bFinancialInstitution'], {
                        is: (payeeId: number | null, bFinancialInstitution: boolean) =>
                            !payeeId && bFinancialInstitution,
                        then: transmitterObjectSchema([], {}),
                    }),

                // payment details
                transferType: Yup.string().required('Please pick a payment method').nullable(),
                paymentReference: Yup.string().required('Please provide a payment reference'),

                // Amount & purpose
                feeId: Yup.string()
                    .nullable()
                    .test(
                        'validFee',
                        'Please choose a fee option',
                        function (val: string | undefined | null) {
                            return val === null || !!val;
                        }
                    ),
                amount: Yup.number()
                    .typeError('Amount must be a number')
                    .required('Please enter an amount')
                    .positive('Please enter a number greater than 0'),
                purpose: Yup.string()
                    .nullable()
                    .test('requiredIfInternational', 'Required', function (val) {
                        const account = matchAccount(this.parent.sourceAccountId, accounts);
                        if (!account) return true;

                        // Existing payee
                        const payee = matchPayee(this.parent.payeeId, account);
                        // New payee
                        const countryNewPayee = this.parent.countryCode;

                        const country = payee
                            ? matchCountry(payee.countryName, countries)?.countryISO3
                            : countryNewPayee;
                        if (!country) return true;

                        const purposeOptions = queryClient.getQueryData<CachedPurposeOptions>([
                            PURPOSE_OPTIONS_KEY,
                            account.id + '', // the cached query has id as a string
                            country,
                        ]);
                        const purposeIsRequired =
                            purposeOptions &&
                            purposeOptions.purposeCodes &&
                            purposeOptions.purposeCodes.length > 0;

                        if (purposeIsRequired) {
                            const purpose = matchToPurpose(
                                val ?? '',
                                purposeOptions?.purposeCodes || []
                            );

                            return !!purpose;
                        } else return true;
                    }),
                purposeOther: Yup.string()
                    .max(50, 'Maximum 50 characters')
                    .nullable()
                    .test('requiredIfOtherSelected', 'Required', function (val) {
                        const account = matchAccount(this.parent.sourceAccountId, accounts);
                        if (!account) return true;

                        // Existing payee
                        const payee = matchPayee(this.parent.payeeId, account);
                        // New payee
                        const countryNewPayee = this.parent.countryCode;

                        const country = payee
                            ? matchCountry(payee.countryName, countries)?.countryISO3
                            : countryNewPayee;
                        if (!country) return true;

                        const purposeOptions = queryClient.getQueryData<CachedPurposeOptions>([
                            PURPOSE_OPTIONS_KEY,
                            account.id + '', // the cached query has id as a string
                            country,
                        ]);
                        const selectedPurpose = this.parent.purpose;
                        const otherPurposeCode: string | undefined = (
                            purposeOptions?.purposeCodes ?? []
                        ).find((purpose) => purpose.description.toLowerCase() === 'other')?.code;

                        if (selectedPurpose === otherPurposeCode) {
                            return !!val;
                        } else return true;
                    }),
                // More details
                memo: Yup.string().max(140, 'Maximum 140 characters').nullable(),
                notes: Yup.string().max(1000, 'Maximum 1000 characters').nullable(),
            })
        ),
    });
};
