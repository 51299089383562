import Button from 'components/button/Button';
import React, { ReactNode, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import Icon_Close from '../../assets/ibanera/Icon_Close.png';

export const MODAL_PORTAL_ID = 'modal-portal';

type ModalProps = {
    title?: React.ReactNode;
    className?: string;
    backgroundClassName?: string;
    removeCloseButton?: boolean;
    customCloseButton?: ReactNode;
    primaryButton?: string;
    secondaryButton?: string;
    handlePrimary?: () => void;
    handleSecondary?: () => void;
    titleIcon?: React.ReactChild;
    customButtons?: React.ReactNode;
    onCloseModal?(): void;
    closeOnClickOutside?: boolean;
    disablePrimary?: boolean;
};

const Modal: React.FC<ModalProps> = ({
    children,
    title,
    className,
    backgroundClassName,
    primaryButton,
    secondaryButton,
    handlePrimary,
    handleSecondary,
    removeCloseButton = false,
    customCloseButton,
    titleIcon,
    customButtons,
    onCloseModal,
    closeOnClickOutside = true,
    disablePrimary = false,
}) => {
    const dispatch = useDispatch();
    const modalBackgroundRef = useRef<HTMLDivElement | null>(null);

    const handleCloseModal = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!closeOnClickOutside) return;
        if (event.target === modalBackgroundRef.current) {
            onCloseModal ? onCloseModal() : dispatch(closeModal());
        }
    };

    return (
        <div
            className={`ModalBackground ${backgroundClassName ? backgroundClassName : ''}`}
            // Want to avoid using useOnClickOutside hook as toast messages can pop up whilst
            // modals are open and clicking the close button on the toast message shouldn't
            // cause the modal to close.
            onClick={handleCloseModal}
            ref={modalBackgroundRef}
        >
            <div className={`Modal ${className ? className : ''}`}>
                <div id={MODAL_PORTAL_ID} />
                {title && (
                    <div className="ModalHeader">
                        {titleIcon && <div className="ModalTitleIconContainer">{titleIcon}</div>}
                        <p className="ModalHeading">{title}</p>
                        {customCloseButton
                            ? customCloseButton
                            : !removeCloseButton && (
                                  <div className="ExitImg">
                                      <img
                                          src={Icon_Close}
                                          alt="CloseIcon"
                                          onClick={() => {
                                              onCloseModal
                                                  ? onCloseModal()
                                                  : dispatch(closeModal());
                                          }}
                                      />
                                  </div>
                              )}
                    </div>
                )}
                <div className="ModalContent">{children}</div>
                {customButtons
                    ? customButtons
                    : ((primaryButton && handlePrimary) ||
                          (secondaryButton && handleSecondary)) && (
                          <div className="ModalNavigation">
                              {secondaryButton && handleSecondary && (
                                  <Button priority="secondary" onClick={handleSecondary}>
                                      {secondaryButton}
                                  </Button>
                              )}
                              {primaryButton && handlePrimary && (
                                  <Button
                                      priority="primary"
                                      onClick={handlePrimary}
                                      disabled={disablePrimary}
                                  >
                                      {primaryButton}
                                  </Button>
                              )}
                          </div>
                      )}
            </div>
        </div>
    );
};

export { Modal };
