import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { PaymentStepProps } from './MakePayment';
import { RadioButtons } from '../../../../components/radiobuttons/radiobuttons';
import FormTextField from '../../../../components/form/FormTextField';
import Button from '../../../../components/button/Button';
import { useIsFinancialInstitution } from '../helpers';
import { useGetFiatAccountDetails } from 'helpers/useGetFiatAccountDetails';
import { ProductType } from 'components/sideMenu/SideMenu';
import { transferTypesThatAllowBeneficiaryReference } from './ReviewPayment';

export const paymentRefTipForFI =
    'Information that will be provided to the beneficiary bank and should appear on their statement. Please note this text may be truncated or removed.';
export const paymentRefTipForNonFi = 'Message to be recieved by the beneficiary bank';
export const beneficiaryRefTip =
    "16-character reference which shows up on the recipient's bank statement";

type Props = PaymentStepProps & {
    paymentMethods: string;
    initialValues: Partial<PaymentDetailsForm>;
};

export type PaymentDetailsForm = {
    transferType: string;
    paymentReference: string;
    beneficiaryReference: string | null;
};

const validationSchema = Yup.object({
    paymentReference: Yup.string().required('Please provide a payment reference'),
});

export const PaymentDetails: React.FC<Props> = ({ nextStep, paymentMethods, initialValues }) => {
    const accountDetails = useGetFiatAccountDetails();
    const isCrb = accountDetails?.productDisplayName === ProductType.CRB;
    const bFinancialInstitution = useIsFinancialInstitution();

    const tooltip = !bFinancialInstitution ? paymentRefTipForNonFi : paymentRefTipForFI;

    const onSubmit = (values: PaymentDetailsForm) => {
        nextStep(values);
    };

    const showBeneficiaryReferenceForTransferType = (transferType: string) =>
        isCrb && transferTypesThatAllowBeneficiaryReference.includes(transferType);

    const allowedTransferTypes = paymentMethods.split(',').map((item) => item.trim()) ?? []; // allowedTransferTypes is a comma separated string e.g. "ACH, RTP, Wire"

    return (
        <Formik
            initialValues={{
                transferType: allowedTransferTypes[0],
                paymentReference: '',
                beneficiaryReference: null,
                ...initialValues,
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ values }) => (
                <Form>
                    <RadioButtons
                        options={allowedTransferTypes}
                        fieldname="transferType"
                        label="Payment Method"
                    />
                    <FormTextField
                        field="paymentReference"
                        label="Payment Reference"
                        maxLength={!bFinancialInstitution ? 16 : 32}
                        tooltip={
                            showBeneficiaryReferenceForTransferType(values.transferType)
                                ? undefined
                                : tooltip
                        }
                    />
                    {showBeneficiaryReferenceForTransferType(values.transferType) && (
                        <FormTextField
                            field="beneficiaryReference"
                            label="Beneficiary Reference"
                            maxLength={16}
                            tooltip={beneficiaryRefTip}
                        />
                    )}
                    <Button type="submit" color="third">
                        Next
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
