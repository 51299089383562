import { Filter } from 'components/categoryHelpers/filterBuilder/FilterBuilder';

export type FilterButtonProps = {
    applyFilter(filters: Filter[], params: { [k: string]: any }): void;
};
export type TypeSelectedFilters = {
    TransactionDirection__Direction?: string;
    TransactionTypes__Type?: string;
    CustomerAssetAccountsTransactions__Amount?: any;
    CustomerAssetAccountsTransactions_Amount_From?: string;
    CustomerAssetAccountsTransactions_Amount_To?: string;
    CustomerAssetAccountsExternalTransfers__Name?: string;
    filterBicOrRoutingNumber?: string;
    CustomerAssetAccounts__RoutingNumber?: string;
    CustomerAssetAccountsExternalTransfers__AchAccountNumber?: string;
    filterMemoOrDesc?: string;
};

export type ModalTransactionsFiltersProps = {
    onCloseModal(): void;
    onFilterApply(formValues: TypeSelectedFilters): void;
    filtersSelected: TypeSelectedFilters;
};
export type TransactionType =
    | 'Buy'
    | 'Sell'
    | 'Deposit'
    | 'Withdraw'
    | 'Fee'
    | 'Refund'
    | 'Transfer'
    | 'Adjustment'
    | 'TopUp'
    | 'BalanceCorrection'
    | 'Chargeback'
    | 'Mint';
export const TransactionTypes: TransactionType[] = [
    'Buy',
    'Sell',
    'Deposit',
    'Withdraw',
    'Fee',
    'Refund',
    'Transfer',
    'Adjustment',
    'TopUp',
    'BalanceCorrection',
    'Chargeback',
    'Mint',
];

export const FiltersTxnsFormInitialValues: TypeSelectedFilters = {
    TransactionDirection__Direction: '',
    TransactionTypes__Type: '',
    CustomerAssetAccountsTransactions__Amount: undefined,
    CustomerAssetAccountsTransactions_Amount_From: '',
    CustomerAssetAccountsTransactions_Amount_To: '',
    CustomerAssetAccountsExternalTransfers__Name: '',
    filterBicOrRoutingNumber: '',
    CustomerAssetAccounts__RoutingNumber: '',
    CustomerAssetAccountsExternalTransfers__AchAccountNumber: '',
    filterMemoOrDesc: '',
};

/**
 * Function to append extra filters or parameters based on a specific key and value.
 * It either updates the `params` object or adds a filter to the `filters` array.
 * 
 * @param {string} key - The key that determines how the value will be processed.
 * @param {any} value - The value associated with the key. This could be a string, object, or any other type.
 * @param {Filter[]} filters - An array of filters to which new filter objects may be added.
 * @param {Object} params - A key-value pair object to store additional parameters for the request. 
 */
export const appendExtraFilters = (key: string, value: any, filters: Filter[], params: { [k: string]: any }) => {
    if (key.toString() === 'filterBicOrRoutingNumber') {
        params[key] = String(value).trim()
    }
     else if (key.toString() === 'filterMemoOrDesc') {
        params[key] = String(value).trim()
    }
    else if(key === 'filterExactAmount'){
        params[key] = String(value.amount).trim()
    }
    else if (key === 'CustomerAssetAccountsTransactions__Amount') {
        if (value.amount?.length > 0) {
            params["filterExactAmount"] = String(value.amount).trim()
        } else if (value.minAmount?.length > 0 || value.maxAmount?.length > 0) {
            filters.push({
                columnKey: key,
                operator: 'BETWEEN',
                value: `${value.minAmount ?? "-10000000000"}TO${value.maxAmount ?? "10000000000"}`,
            });
        } 
    }
};


/**
 * Function that takes a key for a filter and returns the corresponding operator based on the database search criteria.
 * 
 * @param {string} key - The key representing a specific field in the database, used to determine the operator.
 * 
 * @returns {string | null} - Returns the operator for the given key, which can be 'INSTRINGARRAY', 'EQ', 'CONTAINS',
 *                            or `null` if no matching key is found.
 */
export const getOperatorForFilter = (key: string) => {
    if (key === 'TransactionDirection__Direction') {
        return 'EQ';
    } else if (key === 'TransactionTypes__Type') {
        return 'EQ';
    } else if (key === 'CustomerAssetAccountsExternalTransfers__Name') {
        return 'CONTAINS';
    } else if (key === 'CustomerAssetAccounts__SwiftBic') {
        return 'CONTAINS';
    }  else if (key === 'CustomerAssetAccountsExternalTransfers__AchRoutingNumber') {
        return 'CONTAINS';
    } else if (key === 'CustomerAssetAccountsExternalTransfers__WireRoutingNumber') {
        return 'CONTAINS';
    } else if (key === 'CustomerAssetAccountsExternalTransfers__AchAccountNumber') {
        return 'CONTAINS';
    } 
    // else if (key === 'CustomerAssetAccountsTransactions__Description') {
    //     return 'CONTAINS';
    // } 
    else if (key === 'CustomerAssetAccountsTransactions__Memo') {
        return 'CONTAINS';
    }
    return null;
};

/**
 * Function that processes the value for a given filter key and returns it in the correct format.
 * 
 * @param {string} key - The key representing a specific field in the database. Determines how the value will be formatted.
 * @param {any} value - The value associated with the key, which will be transformed into a string or array of strings.
 * 
 * @returns {string | string[]} - Returns:
 * - An array of strings (`string[]`) if the key is `'TransactionDirection__Direction'`.
 * - A trimmed string (`string`) for other keys. If the value is `null` or `undefined`, an empty string is returned.
 * 
 */
export const getValueForFilter = (key: string, value: any): string | string[] => {
    // if (key === 'TransactionDirection__Direction') {
    //     return [String(value ?? "")];
    // } 
    return String(value ?? "").trim() ?? "";
};
export const mapKeysForCSV = (filters: Filter[]) => {
    const accNumItem = filters.find(f => f.columnKey === "CustomerAssetAccountsExternalTransfers__AchAccountNumber");
    if(accNumItem){
        accNumItem.columnKey = "TransferData__AccountNumber"
    }
    const directionItem = filters.find(f => f.columnKey === "TransactionDirection__Direction");
    if(directionItem){
        directionItem.columnKey = "TransactionAmounts__Direction"
    }
    
};
