import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { FormSingleSelectField } from '@avamae/formbuilder';
import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import Button from 'components/button/Button';
import FormTextField, { FormTextFieldProps } from 'components/form/FormTextField';
import { RadioButton, RadioButtons } from 'components/radiobuttons/radiobuttons';
import { TFAInputMask } from 'components/tfaInputMask/TFAInputMask';
import { endpoints } from 'endpoints.config';
import { Form, Formik, FormikHelpers, useField, useFormikContext } from 'formik';
import { useFocus } from 'helpers/useFocus';
import { SavedCardForm } from 'pages/account/PayIn';
import { CountryDetails } from 'pages/register/models';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import * as Yup from 'yup';
import { handleGenericError } from './QuickCoinPage';
import { useInputMask } from './useInputMask';
import { CheckboxInput } from 'components/form/CheckboxInput';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import { getTokenAndEncrypt } from 'helpers/encryption';
import { WpgIframe, WpgSdkIframe } from './WpgIframe';
import { useNavigate } from '@reach/router';
import { GooglePayButton } from 'components/GooglePayButton';
import { ApplePayButton } from 'components/ApplePayButton';
import { Collapse, ManualCollapse } from 'components/Collapse/Collapse';
import logger from 'helpers/logger';
import {
    PaymentFlowType,
    PaymentGatewayMidConfig,
    PurchaseDetails,
    SavedCardDetails,
} from './model';
import { CardProviders, getCardProvider } from 'helpers/cards';

import VisaIcon from 'assets/ibanera/Icon_Card_01.png';
import MasterCardIcon from 'assets/ibanera/Icon_Card_02.png';
import classNames from 'classnames';
import { InitProperties } from 'helpers/googlePay';

export type PaymentInfo = {
    paymentReference: string;
    status: string;
    redirectUrl: string;
};
export type NewCardDetails = {
    // cardType: 'New card' | 'Saved card';
    // price: number | undefined;
    // currency: string;

    cvv: string;
    cardNumber: string;
    expiryDate: string;
    description: string;
    channel: string;
    cardholderName: string;
    addressLine1: string;
    addressLine2: string;
    postCode: string;
    townCity: string;
    stateProvince: string;
    countryISOCode: string;
    phone: string;
    email: string;
    bSaveCardDetails: boolean;
    cardProvider: string; // e.g. visa, mastercard
};
export type CardPaymentFormValues_Saved = PurchaseDetails & SavedCardDetails;
export type CardPaymentFormValues_New = PurchaseDetails & NewCardDetails;

export const initialValues_New: NewCardDetails = {
    // cardType: 'New card',
    // price: 0,
    // currency: '',

    cvv: '',
    cardNumber: '',
    expiryDate: '',
    description: '',
    channel: '',
    cardholderName: '',
    addressLine1: '',
    addressLine2: '',
    postCode: '',
    townCity: '',
    stateProvince: '',
    countryISOCode: '',
    cardProvider: '',
    phone: '',
    email: '',
    bSaveCardDetails: true,
};
export const initialValues_Saved: SavedCardDetails = {
    paymentCardsId: 0,
    cvV2: '',
};

export const validationSchema_Saved = Yup.object({
    paymentCardsId: Yup.number()
        .required('Please select a card')
        .notOneOf([0], 'Please select a card'),
    cvV2: Yup.string()
        .required('Please provide CVV')
        .test('CVV2', 'Must be 3 digits', function (val: any) {
            return val && String(val).length === 3;
        })
        .nullable(),
});
export const validationSchema_WpgNew = Yup.object({
    bSaveCard: Yup.boolean().required(),
});
export const validationSchema_New = Yup.object({
    cardholderName: Yup.string().required('Please provide the name of the card holder').nullable(),
    addressLine1: Yup.string()
        .required('Please provide the first line of your billing address')
        .nullable(),
    addressLine2: Yup.string().nullable(),
    townCity: Yup.string().required('Please provide your town / city').nullable(),
    countryISOCode: Yup.string().required('Please select your country').nullable(),
    stateProvince: Yup.string().required('Please provide your state / province').nullable(),
    postCode: Yup.string().required('Please provide your post code').nullable(),
    cardNumber: Yup.string().required('Please provide your card number').nullable(),
    expiryDate: Yup.string().required("Please provide card's expiry date").nullable(),
    cvv: Yup.string().required('Please provide your card verification code').nullable(),
    cardProvider: Yup.string().required('Please select your card provider').nullable(),
});

type Params = {
    paymentFlow?: PaymentFlowType;
    encryption?: { publicKey: string; keyId: string };
    idempotencyKey?: string;
    setPaymentReference?: (value: React.SetStateAction<string>) => void;
    setPaymentStatus: (value: React.SetStateAction<string>) => void;
    setRedirectUrl?: (value: React.SetStateAction<string>) => void;
    setCardPaymentErrMsg?: (value: React.SetStateAction<string>) => void;
};

export const handleSubmit_Saved = (params: Params) => {
    const {
        paymentFlow,
        encryption,
        idempotencyKey,
        setPaymentReference,
        setPaymentStatus,
        setRedirectUrl,
        setCardPaymentErrMsg,
    } = params;
    return async (
        values: CardPaymentFormValues_Saved,
        formikHelpers: FormikHelpers<CardPaymentFormValues_Saved>
    ) => {
        if (paymentFlow === 'Form' && !encryption) return formikHelpers.setSubmitting(false);
        const {
            asset,
            currency,
            price,
            amount,
            quantityType,
            paymentCardsId,
            bAcceptedTermsAndConditions,
            ...restPayload
        } = values;
        let encryptedMessage = '',
            keyId = '';
        if (encryption) {
            const encryptionResult = await getTokenAndEncrypt({ cvv: values.cvV2 }, encryption);
            encryptedMessage = encryptionResult.encryptedMessage;
            keyId = encryptionResult.keyId;
        }
        await instance
            .post(endpoints.quickCryptoModule.buyWithSavedCard, {
                currencyCode: currency,
                assetCode: asset,
                assetAmount: amount,
                currencyAmount: price,
                quantityType,
                paymentCardsId,
                bAcceptedTermsAndConditions,
                cvv2: restPayload.cvV2, //or values.cvV2,
                chargeCardIdempotencyKey: idempotencyKey,
                encryptedContent: encryptedMessage,
                //keyId: encryption?.keyId,
                keyId,
            })
            .then((res) => {
                setPaymentReference && setPaymentReference(res.data.details.paymentReference);
                setPaymentStatus && setPaymentStatus(res.data.details.status);
                setRedirectUrl && setRedirectUrl(res.data.details.redirectUrl);
            })
            .catch((err) => {
                if (
                    isAxiosErrorHandled(err) &&
                    err.response.data.errors.some(
                        (error) =>
                            error.fieldName === 'Create_Payment' && error.messageCode === 'Failed'
                    )
                ) {
                    return setPaymentStatus('Failed');
                }
            })
            .finally(() => {
                formikHelpers.setSubmitting(false);
            });
        // formikHelpers.setSubmitting(false);
    };
};
export const handleSubmit_New = (
    values: CardPaymentFormValues_New,
    formikHelpers: FormikHelpers<CardPaymentFormValues_New>
) => {
    formikHelpers.setSubmitting(false);
};

type CardPaymentFormProps = {
    basket: PurchaseDetails;
    setPurchaseStage: React.Dispatch<React.SetStateAction<'basket-stage' | 'checkout-stage'>>;
    setCardType: React.Dispatch<React.SetStateAction<string>>;
    setBasket?: React.Dispatch<React.SetStateAction<PurchaseDetails | null>>;

    paymentFlow?: PaymentFlowType;
    encryption?: { publicKey: string; keyId: string };
    idempotencyKey?: string;
    paymentReference: string;
    paymentStatus: string;
    setPaymentStatus: (status: string) => void;
    redirectUrl: string;
    iframeUrl: string;
    cardPaymentErrMsg?: string;
    setCardPaymentErrMsg: React.Dispatch<React.SetStateAction<string>>;
    onMobileWalletComplete: (result: PaymentInfo) => void;
    deviceSessionId: string;
    midConfig: PaymentGatewayMidConfig | null;
};
export const CardPaymentForm = ({
    //targetPurchase,
    basket,
    setPurchaseStage,
    setBasket,
    setCardType,

    paymentFlow,
    encryption,
    idempotencyKey,
    paymentReference,
    paymentStatus,
    setPaymentStatus,
    redirectUrl,
    iframeUrl,
    cardPaymentErrMsg,
    setCardPaymentErrMsg,
    onMobileWalletComplete,
    deviceSessionId,
    midConfig,
}: CardPaymentFormProps) => {
    const [availableCountries, setAvailableCountries] = useState<SelectOption[]>([]);
    const {
        ccNumberInputKeyDownHandler,
        ccNumberInputInputHandler,
        ccExpiryInputKeyDownHandler,
        ccExpiryInputInputHandler,
        ccCVCInputKeyDownHandler,
        ccCVCInputInputHandler,
    } = useInputMask();
    useEffect(() => {
        getAvailableCountries();
    }, []);
    const getAvailableCountries = () => {
        instance
            .get<
                ApiResponse<
                    {
                        name: string;
                        countryISO2: string;
                    }[]
                >
            >(endpoints.quickCryptoModule.getAvailableCountries)
            .then((res) => {
                setAvailableCountries(
                    res.data.details.map((country) => ({
                        value: country.countryISO2,
                        label: country.name,
                    }))
                );
            })
            .catch((err) => {
                setAvailableCountries([]); //TODO handle error
            });
    };
    const approvePayment = useCallback(() => setPaymentStatus('Approved'), [setPaymentStatus]);
    const cancelPayment = useCallback(() => setPaymentStatus('Failed'), [setPaymentStatus]);

    const navigate = useNavigate();
    useEffect(() => {
        if (paymentStatus === 'Approved') {
            setTimeout(() => navigate('/'), 10000);
        }
    }, [paymentStatus, navigate]);

    const formik = useFormikContext<CardPaymentFormValues_New | CardPaymentFormValues_Saved>();
    useEffect(() => {
        setCardType(formik.values.cardType ?? 'New card');
    }, [formik.values.cardType, setCardType]);
    if (paymentStatus === 'Pending') {
        return <div>PAYMENT PENDING</div>;
    }
    if (paymentStatus === 'Approved') {
        return (
            <div>
                <h3>PAYMENT APPROVED</h3>
                <p>You will be redirected shortly</p>
                {paymentReference && <p>Payment reference: {paymentReference}</p>}
            </div>
        );
    }
    if (paymentStatus === 'Failed') {
        return (
            <div>
                <h3>PAYMENT FAILED</h3>
                <p>Please try again</p>
            </div>
        );
    }
    if (paymentStatus === 'ActionRequired') {
        return paymentFlow === PaymentFlowType.IFrame ? (
            <WpgSdkIframe
                iframeUrl={redirectUrl}
                onSuccess={approvePayment}
                onFailure={cancelPayment}
            />
        ) : (
            <div>{redirectUrl && <iframe src={redirectUrl} title="3DS"></iframe>}</div>
        );
    }

    if (paymentStatus === 'WpgIframe') {
        return (
            <WpgSdkIframe
                iframeUrl={iframeUrl ?? ''}
                onSuccess={approvePayment}
                onFailure={cancelPayment}
            />
        );
    }

    return (
        <Form
            className="CardPaymentForm"
            key={paymentFlow === PaymentFlowType.Form ? undefined : formik.values.cardType}
        >
            <Button
                priority="tertiary"
                className="InlineLink"
                style={{ paddingLeft: '0', marginBottom: '10px' }}
                onClick={() => {
                    //setBasket && setBasket(null); //like purchaseStage === 'basket-stage'? initialValues : in quickCoinpage

                    setCardPaymentErrMsg('');
                    setPurchaseStage('basket-stage');
                }}
                type="button"
            >
                {'< Back'}
            </Button>
            {paymentFlow === PaymentFlowType.Form ? (
                <>
                    <div className="MobilePayButtons" style={{ marginBottom: 10 }}>
                        {midConfig?.bUseGooglePay && midConfig.googlePayDetails && (
                            <GooglePayButton
                                onComplete={onMobileWalletComplete}
                                basket={basket}
                                deviceSessionId={deviceSessionId}
                                idempotencyKey={idempotencyKey ?? ''}
                                googlePayProperties={midConfig.googlePayDetails}
                            />
                        )}
                        {midConfig?.bUseApplePay && (
                            <ApplePayButton
                                onComplete={onMobileWalletComplete}
                                basket={basket}
                                deviceSessionId={deviceSessionId}
                                idempotencyKey={idempotencyKey ?? ''}
                            />
                        )}
                    </div>
                    <SavedCardForm
                        plainForm
                        cardListUrl={endpoints.quickCryptoModule.getPaymentCards}
                        errorMessage={cardPaymentErrMsg}
                        setCardPaymentErrMsg={setCardPaymentErrMsg}
                        accordion
                    />
                    <ManualCollapse
                        header="Pay with new card"
                        isOpen={formik.values.cardType === 'New card'}
                        setOpen={(open: boolean) => {
                            formik.setFieldValue('cardType', open ? 'New card' : null);
                            setCardPaymentErrMsg('');
                        }}
                    >
                        <React.Fragment key={formik.values.cardType}>
                            <FormTextField
                                type="text"
                                field={'cardholderName'}
                                label={'Card holder name'}
                                required={true}
                            />
                            <FormTextField
                                type="text"
                                maxLength={19}
                                className="cc-number-input"
                                placeholder="Card Number"
                                onInput={ccNumberInputInputHandler}
                                onKeyDown={ccNumberInputKeyDownHandler}
                                field={'cardNumber'}
                                label={'Card Number'}
                                required={true}
                            />
                            <CardProviderIcons />
                            <div className="SideBySide">
                                <FormTextField
                                    type="text"
                                    maxLength={5}
                                    className="cc-expiry-input"
                                    placeholder="MM / YY"
                                    onInput={ccExpiryInputInputHandler}
                                    onKeyDown={ccExpiryInputKeyDownHandler}
                                    field={'expiryDate'}
                                    label={'Expiry'}
                                    required={true}
                                />
                                <FormTextField
                                    type="text"
                                    maxLength={3}
                                    className="cc-cvc-input"
                                    placeholder="CVC"
                                    onInput={ccCVCInputInputHandler}
                                    onKeyDown={ccCVCInputKeyDownHandler}
                                    field={'cvv'}
                                    label={'CVC'}
                                    required={true}
                                />
                            </div>

                            <FormTextField
                                type="text"
                                field={'addressLine1'}
                                label={'Address line 1'}
                                required={true}
                            />
                            <FormTextField
                                type="text"
                                field={'addressLine2'}
                                label={'Address line 2'}
                                required={false}
                            />
                            <div className="SideBySide">
                                <FormTextField
                                    type="text"
                                    field={'postCode'}
                                    label={'Post/Zip code'}
                                    required={true}
                                />
                                <FormTextField
                                    type="text"
                                    field={'townCity'}
                                    label={'Town/City'}
                                    required={true}
                                />{' '}
                                <FormTextField
                                    type="text"
                                    field={'stateProvince'}
                                    label={'State/Province'}
                                    required={true}
                                />
                            </div>
                            {/* <WrappedFormSingleSelectField
                                fieldName="countryISOCode"
                                label={'Country'}
                                options={availableCountries}
                            /> */}
                            <FormSingleSelectField
                                fieldName="countryISOCode"
                                label={'Country'}
                                options={availableCountries}
                            />
                            <CheckboxInput name="bSaveCardDetails" label="Save card details" />
                            {cardPaymentErrMsg && (
                                <div className="ErrorLabel StagedPayment">{cardPaymentErrMsg}</div>
                            )}
                            <Button type="submit" variety="full" disabled={formik.isSubmitting}>
                                Make payment
                            </Button>
                        </React.Fragment>
                    </ManualCollapse>
                </>
            ) : (
                <>
                    <div className="ChooseMethod">
                        {/* <RadioButtons options={['New card', 'Saved card']} fieldname={'cardType'} /> */}
                        <div className={'RadioButtons'}>
                            {['New card', 'Saved card'].map((option) => (
                                <RadioButton
                                    label={option}
                                    // selected={option === paymentOption}
                                    // onClick={() => setPaymentOption(option as any)}
                                    selected={option === formik.values.cardType}
                                    //or selected={option === formikProps.getFieldProps('cardType').value}
                                    onClick={() => {
                                        formik.setFieldValue('cardType', option); //opt
                                        if (cardPaymentErrMsg)
                                            setCardPaymentErrMsg && setCardPaymentErrMsg('');
                                    }}
                                    key={option}
                                />
                            ))}
                        </div>
                    </div>
                    <h4>Pay with {formik.values.cardType}</h4>
                    {/* <h4>Pay with {formik.values.cardType}</h4> */}
                    <>
                        {formik.values.cardType === 'Saved card' ? ( //or selected={option === formikProps.getFieldProps('cardType').value}
                            <>
                                <div className="PaymentForm" key={formik.values.cardType}>
                                    <SavedCardForm
                                        plainForm
                                        cardListUrl={endpoints.quickCryptoModule.getPaymentCards}
                                        errorMessage={cardPaymentErrMsg}
                                    />
                                </div>
                            </>
                        ) : (
                            /* for WPG we want to only get the iframe url after the user selects to pay with a new card */
                            <CheckboxInput name="bSaveCard" label="Save card details" />
                        )}
                    </>
                    <Button type="submit" variety="full" disabled={formik.isSubmitting}>
                        Make payment
                    </Button>
                </>
            )}
        </Form>
    );
};

type CardInputProps = {
    holdFocus?: boolean;
    fieldName: string;
    fieldLabel: string;
    inputClass?: string;
    maskClass?: string;
};
export const CardInputField = (props: CardInputProps) => {
    const { holdFocus, fieldName, fieldLabel, inputClass, maskClass } = props;
    const [inputRef, setFocus] = useFocus<HTMLInputElement>();
    const [fieldProps, fieldMetaProps, fieldHelperProps] = useField(fieldName);
    const handleBlur = (e: React.FocusEvent) => {
        fieldProps.onBlur(e);
        holdFocus && setFocus();
    };
    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            const { value } = e.target;
            const regex = /^[0-9]*$/;
            if (regex.test(value)) {
                fieldHelperProps.setValue(value, false);
            } else {
                fieldHelperProps.setValue(fieldProps.value, true);
            }
        },
        [fieldProps.value, fieldHelperProps]
    );
    return (
        <>
            <FormTextField
                field={fieldName}
                label={fieldLabel}
                required={false}
                maxLength={6}
                autoComplete={false}
                inputMode="numeric"
                {...props}
                className={`TFAHiddenField ${inputClass}`}
                ref={inputRef}
                onBlur={handleBlur}
                onChange={onChange}
            />
            <TFAInputMask value={fieldProps.value} className={`InputMask ${maskClass}`} />
        </>
    );
};

const CardProviderIcons = () => {
    const { values, setFieldValue } = useFormikContext<CardPaymentFormValues_New>();
    const { cardNumber, cardProvider } = values;
    useEffect(() => {
        if (!cardNumber) return;
        const impliedCardProvider = getCardProvider(cardNumber);
        setFieldValue('cardProvider', impliedCardProvider);
    }, [cardNumber, setFieldValue]);

    return (
        <div className="CardProviderIcons">
            <img
                src={VisaIcon}
                alt={'visa'}
                className={classNames('CardIcon', {
                    Selected: cardProvider === CardProviders.visa,
                })}
            ></img>
            <img
                src={MasterCardIcon}
                alt={'MasterCard'}
                className={classNames('CardIcon', {
                    Selected: cardProvider === CardProviders.mastercard,
                })}
            ></img>
        </div>
    );
};
