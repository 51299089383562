import { ThreeBounce } from 'better-react-spinkit';
import Button from 'components/button/Button';
import { Modal } from 'components/modal/Modal';
import { useCallback, useMemo, useState } from 'react';
import './../ConnectionsPage.scss';
import { endpoints } from 'endpoints.config';
import api, { ApiResponse } from 'api';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { GeneralErrorMessages } from 'helpers/categoryHelpers/processErrors';
import { ItemConnectionModel } from '../items/ItemConnection';
type PropsModalConnectionDetail = {
    closeModal: (isRefresh: boolean) => void;
    selectedConnection: ItemConnectionModel;
    makePayment?: (connection: ItemConnectionModel) => void;
};

export const ModalConnectionDetail = ({
    closeModal,
    selectedConnection,
    makePayment
}: PropsModalConnectionDetail) => {
    const [isAddingConnection, setAddingConnection] = useState(false);
    const [type, setType] = useState<string | undefined>();

    const handleMakePayment = useCallback(() => {
        makePayment?.(selectedConnection);
        closeModal(false);
    }, [closeModal, makePayment, selectedConnection]);
    const handleAddConnection = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            setAddingConnection(true);
            setType(e.currentTarget.id);
            try {
                const payload = {
                    id: selectedConnection.senderId,
                    type: e.currentTarget.id,
                };
                const res = await api.post<ApiResponse>(
                    endpoints.networkmodule.acceptConnectionRequest,
                    payload
                );
                if (res.data?.status === '1') {
                    Toast.openToastMessage(
                        type === 'ACCEPTED' ? 'Request Accepted!' : 'Request Rejected!',
                        ToastMessageReason.VALID
                    );
                    selectedConnection.networkConnectionStatus = type ?? '';
                    closeModal(true);
                } else {
                    Toast.openToastMessage(GeneralErrorMessages.Generic, ToastMessageReason.ERROR);
                }

                setAddingConnection(false);
            } catch (ex: any) {
                if (ex?.response?.data?.errors?.length > 0) {
                    const key = ex.response.data.errors[0]
                        .messageCode as keyof typeof GeneralErrorMessages;
                    Toast.openToastMessage(
                        GeneralErrorMessages[key] ?? GeneralErrorMessages.Generic,
                        ToastMessageReason.ERROR
                    );
                }
                setAddingConnection(false);
            }
            setType(undefined);
        },
        [closeModal, selectedConnection, type]
    );
    const handleCancelRequest = useCallback(
        async (e: any) => {
            setAddingConnection(true);
            try {
                const payload = {
                    id: selectedConnection.receiverId,
                    type: 'CANCEL',
                };
                const res = await api.post<ApiResponse>(
                    endpoints.networkmodule.connectionRequest,
                    payload
                );
                if (res.data?.status === '1') {
                    Toast.openToastMessage('Request Canceled!', ToastMessageReason.VALID);
                    closeModal(true);
                } else {
                    Toast.openToastMessage(GeneralErrorMessages.Generic, ToastMessageReason.ERROR);
                }

                setAddingConnection(false);
            } catch (ex: any) {
                if (ex?.response?.data?.errors?.length > 0) {
                    const key = ex.response.data.errors[0]
                        .messageCode as keyof typeof GeneralErrorMessages;
                    Toast.openToastMessage(
                        GeneralErrorMessages[key] ?? GeneralErrorMessages.Generic,
                        ToastMessageReason.ERROR
                    );
                }
                setAddingConnection(false);
            }
        },
        [closeModal, selectedConnection.receiverId]
    );
    const modalTitle = useMemo(() => {
        if (selectedConnection.networkConnectionStatus === 'ACCEPTED') return 'Connection Detail';
        else if (selectedConnection.networkConnectionStatus === 'PENDING') {
            if (selectedConnection.customerId !== selectedConnection.senderId) {
                return 'Accept Connection Request';
            }
            return 'Connection Request';
        }
        return '';
    }, [selectedConnection]);
    const modalMsg = useMemo(() => {
        if (selectedConnection.networkConnectionStatus === 'PENDING') {
            if (selectedConnection.customerId === selectedConnection.receiverId) {
                return `Are you sure you want to accept ${selectedConnection.senderName} connection request?`;
            }
            return 'Cancel Connection Request?';
        }
        return null;
    }, [selectedConnection]);
    return (
        <Modal
            className="accept-connection-modal"
            title={modalTitle}
            onCloseModal={() => {
                closeModal(false);
            }}
        >
            <div className="container">
                {selectedConnection.networkConnectionStatus === 'ACCEPTED' && (
                    <div className={'Layout'}>
                        <div>
                            <h4>Connection:</h4>
                            <p>
                                {selectedConnection.customerId == selectedConnection.senderId
                                    ? selectedConnection.receiverName
                                    : selectedConnection.senderName}
                            </p>
                        </div>
                        <div className="Buttons">
                            <Button color="second" id="Remove">
                                Remove
                            </Button>
                            <Button color="first" onClick={handleMakePayment} id="MakePayment">
                                Make Payment
                            </Button>
                        </div>
                    </div>
                )}
                {modalMsg && <p>{modalMsg}</p>}
                {selectedConnection.customerId !== selectedConnection.senderId &&
                    selectedConnection.networkConnectionStatus === 'PENDING' && (
                        <div className="Buttons">
                            <Button
                                color="second"
                                onClick={handleAddConnection}
                                id="REJECTED"
                                disabled={isAddingConnection}
                            >
                                {isAddingConnection && type === 'REJECTED' ? (
                                    <ThreeBounce size={10} color={'#ffffff'} />
                                ) : (
                                    'Reject Request'
                                )}
                            </Button>
                            <Button
                                color="first"
                                onClick={handleAddConnection}
                                id="ACCEPTED"
                                disabled={isAddingConnection}
                            >
                                {isAddingConnection && type === 'ACCEPTED' ? (
                                    <ThreeBounce size={10} color={'#ffffff'} />
                                ) : (
                                    'Accept Request'
                                )}
                            </Button>
                        </div>
                    )}

                {selectedConnection.customerId === selectedConnection.senderId &&
                    selectedConnection.networkConnectionStatus === 'PENDING' && (
                        <div className="Buttons">
                            <Button
                                color="first"
                                onClick={handleCancelRequest}
                                id="CANCEL"
                                disabled={isAddingConnection}
                            >
                                {isAddingConnection && type === 'ACCEPTED' ? (
                                    <ThreeBounce size={10} color={'#ffffff'} />
                                ) : (
                                    'Cancel Request'
                                )}
                            </Button>
                        </div>
                    )}
            </div>
        </Modal>
    );
};
