import instance, { ApiResponse, isErrorHandled } from 'api';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { TFAField } from 'components/form/TFAField';
import { Modal } from 'components/modal/Modal';
import { endpoints } from 'endpoints.config';
import { getErrorMessage } from 'errors';
import { Form, Formik, FormikHelpers } from 'formik';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { useTFAField } from 'helpers/useTFAField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectModalState, ModalTypes } from 'reducers/modal';
import * as Yup from 'yup';
import WarningIcon from 'assets/Icon_Warning.png';
import { OrderDetails, PayeeDetail, TradeCrypto } from 'pages/crypto/Crypto';
import { TFAType } from 'pages/register/models';
import { RadioButtons } from 'components/radiobuttons/radiobuttons';
import { ReviewOrderForm } from 'pages/crypto/ReviewOrderForm';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { CryptoAccountLink, SideMenuLink } from 'components/sideMenu/SideMenu';
import { selectComponentResources } from 'reducers/componentResources';
import { CalculatedPriceField, FiatToggle } from 'pages/quickCoin/QuickCoinFormComponents';

type LiquidateCryptoForm = Omit<
    TradeCrypto,
    'transferType' | 'account' | 'purpose' | 'purposeOther' | 'isInternalPayee'
> & {
    transferType: null;
    customerAssetAccountId: number;
    assetExchangeType: 'Sell';
};

export const liquidateCurrency = 'dusd';

const generateInitialValues = (
    customerAssetAccountId: number,
    asset: string
): LiquidateCryptoForm => ({
    tfaCode: '',
    tfaType: 'SMS',
    customerAssetAccountId: customerAssetAccountId,
    quantityType: 'Amount',
    asset: asset,
    amount: '',
    price: '',
    transferType: null,
    currency: 'USD',
    bVirtualBuy: false,
    assetExchangeType: 'Sell',
});

const validationSchemaStep1 = Yup.object({
    quantityType: Yup.string(),
    price: Yup.number().when('quantityType', {
        is: 'Price',
        then: (schema) =>
            schema
                .typeError('Please enter a target value')
                .required('Please enter a target value')
                .moreThan(0, 'Please enter a target value'),
    }),
    amount: Yup.number().when('quantityType', {
        is: 'Amount',
        then: (schema) =>
            schema
                .typeError('Please enter an amount')
                .required('Please enter an amount')
                .moreThan(0, 'Please enter an amount'),
    }),
});

const validationSchemaStep2 = Yup.object({
    tfaCode: Yup.string()
        .required('Please enter your MFA code')
        .min(6, 'Please enter your MFA code'),
});

export const LiquidateCryptoModal = () => {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const modalState = useSelector(selectModalState);

    const { customerMenuLinks } = useSelector(selectComponentResources);
    const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);

    const handleSubmit = (
        values: LiquidateCryptoForm,
        formikHelpers: FormikHelpers<LiquidateCryptoForm>
    ) => {
        if (!orderDetails) {
            instance
                .get<ApiResponse<OrderDetails>>(endpoints.cryptosmodule.reviewOrder, {
                    params: { ...values, totalPrice: values.price },
                })
                .then((res) => {
                    setOrderDetails(res.data.details);
                    setErrorMessage('');
                })
                .catch((err) => {
                    if (isErrorHandled(err) && err.response.data?.errors?.length > 0) {
                        setErrorMessage(getErrorMessage(err.response.data.errors[0].messageCode));
                    } else {
                        setErrorMessage(getErrorMessage(''));
                    }
                })
                .finally(() => {
                    formikHelpers.setSubmitting(false);
                });
            return;
        }
        const payload = {
            // sending extra data was causing problems with BE apparently
            tfaCode: values.tfaCode,
            tfaType: values.tfaType,
            customerAssetAccountID: values.customerAssetAccountId,
            quantityType: values.quantityType,
            asset: values.asset,
            amount: orderDetails.amount,
            totalPrice: orderDetails.totalPrice,
            payeesId: null,
            transferType: values.transferType,
        };
        instance
            .post(endpoints.cryptosmodule.sellAsset, payload)
            .then((res) => {
                if (!res?.data?.details?.bApproved) {
                    Toast.openToastMessage(
                        "Your transaction has been flagged for review. The funds have been reserved and we'll let you know when it has been approved",
                        ToastMessageReason.ERROR,
                        { autoClose: false }
                    );
                }
                dispatch(closeModal());
            })
            .catch((err) => {
                if (isErrorHandled(err) && err.response.data?.errors?.length > 0) {
                    setErrorMessage(getErrorMessage(err.response.data.errors[0].messageCode));
                } else setErrorMessage(getErrorMessage('Generic'));
                formikHelpers.setSubmitting(false);
                console.error(err);
            });
    };

    const createModalButtons = (submitting: boolean, submit: () => {}) => (
        <div className="ModalNavigation">
            <Button onClick={() => dispatch(closeModal())} type="button" priority="secondary">
                Close
            </Button>
            <Button priority="primary" disabled={submitting} type="submit" onClick={submit}>
                Liquidate
            </Button>
        </div>
    );

    if (modalState.modalType !== ModalTypes.LIQUIDATE_CRYPTO) return null;

    return (
        <Formik
            initialValues={generateInitialValues(
                getLiquidateAccountId(customerMenuLinks ?? []) ?? 0,
                modalState.data.crypto
            )}
            onSubmit={handleSubmit}
            validationSchema={orderDetails ? validationSchemaStep2 : validationSchemaStep1}
        >
            {({ submitForm, isSubmitting, values, setFieldValue }) => (
                <Modal
                    title={`Liquidate ${modalState.data.crypto}`}
                    customButtons={createModalButtons(isSubmitting, submitForm)}
                    closeOnClickOutside={false}
                >
                    <Form>
                        <div className="LiquidateCryptoModal">
                            {!orderDetails && (
                                <>
                                    <FiatToggle
                                        currencyCode={'USD'}
                                        assetCode={modalState.data.crypto}
                                    />
                                    <FormTextField
                                        type="number"
                                        autoComplete={false}
                                        step={values.quantityType === 'Price' ? 'any' : 0.01}
                                        field={values.quantityType === 'Price' ? 'price' : 'amount'}
                                        required={true}
                                        label={
                                            values.quantityType === 'Price'
                                                ? `Target value in ${'USD'}`
                                                : `Amount of ${modalState.data.crypto}`
                                        }
                                        key={values.quantityType === 'Price' ? 'price' : 'amount'}
                                        hint={undefined}
                                    />
                                    <CalculatedPriceField
                                        label={
                                            values.quantityType === 'Price'
                                                ? `Amount of ${modalState.data.crypto}`
                                                : `Value in USD`
                                        }
                                        isFiat={values.quantityType === 'Price'}
                                        hint={undefined}
                                        assetExchangeType={'Sell'}
                                        enabled={true}
                                    />
                                </>
                            )}
                            {orderDetails && (
                                <ReviewOrderForm
                                    back={() => setOrderDetails(null)}
                                    orderDetails={orderDetails}
                                    refreshOrder={() => {}}
                                    errorMessage=""
                                    hideSubmitButton
                                    noFormComponent
                                />
                            )}
                        </div>
                        {errorMessage && <div className="ErrorText">{errorMessage}</div>}
                        <button type="submit" style={{ display: 'none' }} />
                    </Form>
                </Modal>
            )}
        </Formik>
    );
};

const getLiquidateAccountId = (sideMenuLinks: SideMenuLink[]) => {
    return sideMenuLinks
        .find((link) => link.elementType === 'CryptoAsAccounts')
        ?.accounts?.find(
            (account) => (account as CryptoAccountLink).code.toLowerCase() === liquidateCurrency
        )?.id;
};

export const canShowLiquidateModal = (ticker: string, sideMenuLinks: SideMenuLink[] | null) => {
    if (ticker.toLowerCase() === liquidateCurrency || !sideMenuLinks) return false;
    return !!sideMenuLinks
        .find((link) => link.elementType === 'CryptoAsAccounts')
        ?.accounts?.some(
            (account) => (account as CryptoAccountLink).code.toLowerCase() === liquidateCurrency
        );
};
