import { useTable } from 'api';
import { Modal } from 'components/modal/Modal';
import { endpoints } from 'endpoints.config';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectModalState } from 'reducers/modal';
import { SearchFilter } from './SearchFilter';
import { AccountData, AccountsTable, UserSearch } from './UserSearch';
import { switchAccountType } from 'reducers/auth';

const RESULT_COUNT = 50;

type ModalState = {
    modalType: string;
    data: { setName: (name: string) => void; currentlyActiveAccount: number };
};

type Props = {};
export const SwitchAccountModal = (props: Props) => {
    const modalState = useSelector(selectModalState);
    const dispatch = useDispatch();
    const [selectedAccount, setSelectedAccount] = useState<AccountData | null>(null);

    const table = useTable<AccountData, any>({
        url: endpoints.profilemodule.linkedAccountsExtendedList,
        pageSize: RESULT_COUNT,
        bClearFilters: true,
        bClearSearch: true,
    });

    const activeAccount = useMemo(() => {
        return (modalState as ModalState).data.currentlyActiveAccount;
    }, [modalState]);

    useEffect(() => {
        if (
            table.data?.details?.listData &&
            table.data.details.listData.length > 0 &&
            !selectedAccount
        ) {
            setSelectedAccount(
                table.data.details.listData.find(
                    (row) => row.customerUsersCustomers__Id === activeAccount
                ) ?? null
            );
        }
    }, [table.data?.details, activeAccount]);

    const title = (result: AccountData) => {
        let fullName = `${result.customerUsers__FirstName} ${result.customerUsers__LastName}`;
        return `${result.customers__CompanyName ?? fullName} - ${result.customers__AccountType}`;
    };

    const [viewFilter, setViewFilter] = useState(false);

    const handleSwitch = useCallback((selectedAccount) => {
        if (selectedAccount.customerUsers__Id) {
            dispatch(
                switchAccountType({
                    targetCustomerUsersCustomersId: selectedAccount.customerUsersCustomers__Id,
                })
            );
            dispatch(closeModal());
        }
    }, []);

    return (
        <Modal
            primaryButton="Switch Account"
            handlePrimary={() => {
                handleSwitch(selectedAccount);
            }}
            disablePrimary={
                !selectedAccount || selectedAccount.customerUsersCustomers__Id === activeAccount
            }
            className={`UserSearchToolWrapper ${viewFilter ? 'Toggle' : ''}`}
        >
            <div className="UserSearchToolContent">
                <UserSearch
                    table={table as any as AccountsTable}
                    handleViewFilter={() => setViewFilter(true)}
                    selectedAccount={selectedAccount}
                    setSelectedAccount={setSelectedAccount}
                    formatTitle={title}
                />
                <SearchFilter
                    table={table as any as AccountsTable}
                    handleCloseFilter={() => {
                        setViewFilter(false);
                    }}
                />
            </div>
        </Modal>
    );
};
