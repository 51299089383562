import React, { useCallback, useEffect, useRef, useState } from 'react';

// Icons
import CrossIcon from 'assets/Cat-Entity/Icon_Form_Search_02.png';
import Icon_Close from 'assets/ibanera/Icon_Close.png';
import { ReactComponent as FilterIcon } from 'assets/icon-add-filter.svg';
import { ReactComponent as FilterIconCross } from 'assets/icon-remove-filter.svg';
import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import { FoldingCube } from 'better-react-spinkit';
import Button from 'components/button/Button';
import { useDebouncedState } from 'helpers/useDebouncedState';
import { useTheme } from '@emotion/react';
import { TableData } from 'helpers/createUseTable';
import { Filter } from 'components/categoryHelpers/filterBuilder/FilterBuilder';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import { localizedFormatDate } from 'helpers/categoryHelpers/userLocale';

export type AccountsTable = {
    loading: boolean;
    error: any;
    data: TableData<AccountData> & {
        actions: {
            toggleColumnSort: (key: keyof AccountsTable) => void;
            changeFilter: (key: keyof AccountsTable, value: Filter[]) => void;
            setFilters: (newFilters: Filter[]) => void;
            changeSearch: (searchString: string) => void;
        };
    };
    reload: () => void;
};

type Props = {
    handleViewFilter: () => void;
    table: AccountsTable;
    selectedAccount: AccountData | null;
    setSelectedAccount: React.Dispatch<React.SetStateAction<AccountData | null>>;
    formatTitle: (result: AccountData) => string;
};

export type AccountData = {
    customerUsersCustomers__Id: number;
    customerUsers__AddDate: string;
    customerUsers__CustomerUsersCode: string;
    customerUsers__FirstName: string;
    customerUsers__Id: number;
    customerUsers__LastName: string;
    customers__AccountType: string;
    customers__CompanyEmailAddress: string;
    customers__CompanyName: string;
    customers__Id: number;
    customers__bFinancialInstitute: boolean;
    licenseesBrands__SiteName: string;
    licensees__LicenseeName: string;
    siteUsers__EmailAddress: string;
    siteUsers__bSuppressed: boolean;
};

/*
Improvement: Make the search box an infinite scroll
*/

export const UserSearch: React.FC<Props> = ({
    handleViewFilter,
    table,
    selectedAccount,
    setSelectedAccount,
    formatTitle,
}) => {
    const [moreInfo, setMoreInfo] = useState<AccountData | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    const localCount = useRef<number>(1);
    const [searchString, setSearchString, localSearchString, count] = useDebouncedState<string>(
        '',
        300
    );
    const dispatch = useDispatch();
    const { colors } = useTheme();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(e.target.value);
    };
    const handleSearch = useCallback(
        (searchTerm: string) => {
            ref?.current?.scrollTo({ top: 0 });
            table.data?.actions.changeSearch(searchTerm);
        },
        [table]
    );

    useEffect(() => {
        if (localCount.current && localCount.current !== count) {
            localCount.current = count;
            handleSearch(searchString);
        }
    }, [searchString, handleSearch]);

    const handleSelectUser = (customer: AccountData) => {
        setSearchString('');
        localCount.current = count;
        setSelectedAccount(customer);
    };
    const handleClearInput = () => {
        if (!selectedAccount) {
            localCount.current = count;
            setSearchString('');
        } else {
            setSelectedAccount(null);
        }
    };

    const hasFilter = table?.data?.details.filters.length ?? 0 > 0;

    return (
        <div className="CustomerSearch">
            <div className="ModalHeader">
                <h2>Select Account</h2>
                <div className="ExitImg">
                    <img
                        src={Icon_Close}
                        alt="CloseIcon"
                        onClick={() => {
                            dispatch(closeModal());
                        }}
                    />
                </div>
            </div>
            <div className="SearchContainer">
                <div className="FormField">
                    <input
                        title="Search Accounts"
                        value={
                            selectedAccount ? `${formatTitle(selectedAccount)}` : localSearchString
                        }
                        disabled={table.loading}
                        className={`EditBox`}
                        onChange={handleChange}
                        placeholder="Search Accounts..."
                    />
                    {(searchString || selectedAccount) && (
                        <img
                            className="ClearIcon"
                            src={CrossIcon}
                            alt="ClearSearch"
                            onClick={handleClearInput}
                        />
                    )}
                </div>

                {!selectedAccount && (
                    <button
                        type="button"
                        className="FilterButton"
                        onClick={handleViewFilter}
                        title="Filters"
                        disabled={table.loading}
                    >
                        {hasFilter ? <FilterIconCross /> : <FilterIcon />}
                    </button>
                )}
                {selectedAccount && (
                    <button
                        type="button"
                        className="MoreInfoButtonLarge"
                        onClick={() => setMoreInfo(selectedAccount)}
                        title="More Info"
                    >
                        <span className="MoreInfoLarge">i</span>
                    </button>
                )}
            </div>

            <div ref={ref} className={`ResultsContainer`}>
                {table.loading && (
                    <div
                        className="Loading"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <FoldingCube color={colors.first} size={80} />
                    </div>
                )}
                <div className="ResultsContent">
                    {table.loading && <div className="Loading" />}
                    {table.data?.details.listData.map((result, i) => (
                        <div
                            key={i}
                            className={`Result ${
                                selectedAccount?.customerUsersCustomers__Id ===
                                result.customerUsersCustomers__Id
                                    ? 'Selected'
                                    : ''
                            }`}
                        >
                            {formatTitle(result)}
                            <span
                                title="More info"
                                className="MoreInfo"
                                onClick={() => setMoreInfo(result)}
                            >
                                i
                            </span>
                            <Button
                                type="button"
                                className="SelectButon"
                                onClick={() => handleSelectUser(result)}
                                title="Filters"
                            >
                                Select
                            </Button>
                        </div>
                    ))}
                    {!table.loading && table.data?.details.listData?.length === 0 && (
                        <div className="Empty">
                            <img src={EmptyList} alt="Empty Icon" style={{ height: '220px' }} />
                            <p>No Results Found</p>
                        </div>
                    )}
                </div>
            </div>
            {moreInfo && (
                <MoreInfoModal
                    closeModal={() => setMoreInfo(null)}
                    title={'More Info'}
                    moreInfo={moreInfo}
                    formatTitle={formatTitle}
                />
            )}
        </div>
    );
};

export const MoreInfoRow = ({
    label,
    value,
}: {
    label: string;
    value: string | undefined | null;
}) => (
    <div className="MoreInfoRow">
        <dt>{label}:</dt>
        <dd>{value}</dd>
    </div>
);

type MoreInfo = {
    closeModal: () => void;
    title: string | undefined;
    moreInfo: AccountData;
    formatTitle: (result: AccountData) => string;
};

const MoreInfoModal: React.FC<MoreInfo> = ({ closeModal, title, moreInfo, formatTitle }) => {
    const modalBackgroundRef = useRef<HTMLDivElement | null>(null);
    const handleCloseModal = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === modalBackgroundRef.current) {
            closeModal();
        }
    };

    return (
        <div className={`ModalBackground `} onClick={handleCloseModal} ref={modalBackgroundRef}>
            <div className={`Modal ModalContent`}>
                {title && (
                    <div className="ModalHeader">
                        <p className="ModalHeading">{title}</p>
                        <div className="ExitImg">
                            <img src={Icon_Close} alt="CloseIcon" onClick={closeModal} />
                        </div>
                    </div>
                )}
                <div className="ModalContent MoreInfoModal">
                    <dl>
                        <MoreInfoRow
                            label="Name"
                            value={`${moreInfo.customerUsers__FirstName} ${moreInfo.customerUsers__LastName}`}
                        />
                        {moreInfo.customers__CompanyName && (
                            <MoreInfoRow
                                label="Company Name"
                                value={moreInfo.customers__CompanyName}
                            />
                        )}
                        {moreInfo.customers__CompanyEmailAddress && (
                            <MoreInfoRow
                                label="Company Email"
                                value={moreInfo.customers__CompanyEmailAddress}
                            />
                        )}
                        <MoreInfoRow label="Account Type" value={moreInfo.customers__AccountType} />
                        <MoreInfoRow
                            label="Created On"
                            value={localizedFormatDate(new Date(moreInfo.customerUsers__AddDate))}
                        />
                    </dl>
                </div>
            </div>
        </div>
    );
};
