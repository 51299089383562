import { ThreeBounce } from 'better-react-spinkit';
import Button from 'components/button/Button';
import { Modal } from 'components/modal/Modal';
import { useCallback, useEffect, useState } from 'react';
import './../ConnectionsPage.scss';

import { ItemConnectionModel } from '../items/ItemConnection';
import { MakePayment } from 'pages/account/Payees/MakePayment/MakePayment';
import ModelTransferSuccess from './ModelTransferSuccess';
import { PaymentStatus } from '../models/models';
type PropsModalAddConnection = { closeModal: () => void; selectedConnection: ItemConnectionModel };

export const ModalSelectConnectionAssetAccount = ({
    closeModal,
    selectedConnection,
}: PropsModalAddConnection) => {
    const [txStatus, setTxStatus] = useState<PaymentStatus| undefined>();
    const closeHandler = useCallback((paymentStatus?: PaymentStatus) => {
        if(paymentStatus?.amount)
            setTxStatus(paymentStatus)
    }, []);
    return (
        <Modal
            className="make-connection-payment-modal"
            title="Make Payment To Connection"
            onCloseModal={closeModal}
            closeOnClickOutside={false}
        >
            {txStatus ? (
                <ModelTransferSuccess txDetail={txStatus} closeModal={closeModal} />
            ) : (
                <MakePayment goBack={closeHandler} connectionPayee={selectedConnection} />
            )}
        </Modal>
    );
};
