import Button from 'components/button/Button';
import { useCallback, useState } from 'react';
import { ModalAcceptConnectionRequest } from '../modals/ModalAcceptConnectionRequest';
import { ModalConnectionDetail } from '../modals/ModalConnectionDetail';
import { InitialsIcon } from 'components/initialsIcon/InitialsIcon';
type PropsItemConnection = {
    networkUser: ItemConnectionModel;
    showConnectionRequest: any;
    makePayment?: (connection: ItemConnectionModel) => void;
    refreshList?: () => void;
};
export type ItemConnectionModel = {
    senderId: number;
    receiverId: number;
    customerId: number;
    networkConnectionStatus: string;
    senderName: string;
    receiverName: string;
};
export const ItemConnection = ({
    networkUser,
    showConnectionRequest,
    makePayment,
    refreshList,
}: PropsItemConnection) => {
    const [showConnectionRequestModal, setShowConnectionRequestModal] = useState<boolean>(false);

    const handleItemClick = useCallback(() => {
        setShowConnectionRequestModal(true);
    }, []);

    const handleDetailClose = useCallback(
        (isRefresh?: boolean) => {
            setShowConnectionRequestModal(false);
            if (isRefresh) {
                refreshList?.();
            }
        },
        [refreshList]
    );
    const handleMakePayment = useCallback(() => {
        makePayment?.(networkUser);
    }, [makePayment, networkUser]);
    return (
        <>
            <div className="list-item-connection-contianer">
                <div className="list-item-connection" onClick={handleItemClick}>
                    <InitialsIcon
                        className="item-icon-connection"
                        name={
                            networkUser.customerId == networkUser.senderId
                                ? networkUser.receiverName
                                : networkUser.senderName
                        }
                    />
                    <span className="listitem-title-connection">
                        {networkUser.customerId == networkUser.senderId
                            ? networkUser.receiverName
                            : networkUser.senderName}
                    </span>

                    {networkUser.customerId === networkUser.senderId &&
                        networkUser.networkConnectionStatus === 'PENDING' && (
                            <img
                                src={'\\assets\\ibanera\\pending_icon.png'}
                                alt="icon"
                                className="item-icon-pending"
                                title="Request Sent"
                            />
                        )}

                    {networkUser.customerId !== networkUser.senderId &&
                        networkUser.networkConnectionStatus === 'PENDING' && (
                            <img
                                src={'\\assets\\ibanera\\pending_request.png'}
                                alt="icon"
                                className="item-icon-pending"
                                title="Accept Connection"
                            />
                        )}
                </div>
                {networkUser.networkConnectionStatus === 'ACCEPTED' && (
                    <Button priority="primary" color="second" onClick={handleMakePayment}>
                        Make payment
                    </Button>
                )}
            </div>
            {showConnectionRequestModal && (
                <ModalConnectionDetail
                    selectedConnection={networkUser}
                    // eslint-disable-next-line no-console
                    closeModal={handleDetailClose}
                    makePayment={makePayment}
                />
            )}
        </>
    );
};
