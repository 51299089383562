import { Form, Formik, useField } from 'formik';

import { AccountFeeInfo, FeeOption, PayeeDetails, PaymentStepProps } from './MakePayment';
import FormTextField from '../../../../components/form/FormTextField';
import { WrappedFormSingleSelectField } from '../../../../components/form/FormSingleSelectField';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import Button from '../../../../components/button/Button';
import { useGetFiatAccountDetails } from '../../../../helpers/useGetFiatAccountDetails';
import { RadioIdButtons } from '../../../../components/radiobuttons/RadioIdButtons';
import { useEffect, useMemo } from 'react';
import { AmountFx } from './AmountFx';
import {
    AmountPurposeForm,
    amountPurposeValidationSchema,
    FXAmountPurposeForm,
    fxValidationSchema,
    initialFXValues,
} from './amountPurposeModel';
import logger from 'helpers/logger';

export const getFeeLabel = (label: string, tempHoldFee?: string, accountName?: string) => {
    switch (label) {
        case 'Full Bank Fee':
            return {
                label: 'Us (OUR)',
                subLabel: `You pay all charges.${
                    tempHoldFee
                        ? ` A temporary hold of ${tempHoldFee} will be placed on your ${
                              accountName ? accountName : 'account'
                          } until the transfer is complete.`
                        : ''
                }`,
            };
        case null:
        case 'Half Bank Fee':
            return {
                label: 'Shared (SHA)',
                subLabel: "You pay Ibanera charges, payee pays their bank's charges",
            };
        case 'No Bank Fee':
            return { label: 'They (BEN)', subLabel: 'Payee pays all charges' };
        default:
            return { label: label, subLabel: '' };
    }
};

export const createFeeOptions = (
    feeOptions: Array<Partial<FeeOption> & { bankFeeSplitType: string }>,
    accountName?: string
) => {
    return (feeOptions ?? []).map((fee) => {
        const { label, subLabel } = getFeeLabel(fee.bankFeeSplitType, fee.tempHoldFee, accountName);
        return {
            label: label,
            value: fee.bankFeeSplitType,
            subLabel: subLabel,
        };
    });
};

type Props = PaymentStepProps & {
    bInternational: boolean;
    purposes: SelectOption[];
    accountFeeInfo: AccountFeeInfo;
    initialValues: Partial<AmountPurposeForm | FXAmountPurposeForm>;
    transferType: string;
    accountType?: string;
    loading: boolean;
    bFxTransfer: boolean;
    setBFxTransfer: (value: boolean) => void;
    sourceCurrency: string | undefined;
    destinationCurrency: string | undefined | null;
    estimatedAmount: number | null;
    estimatedDestinationAmount: number | null;
    destinationAmount: number;
    payeeDetails: PayeeDetails | undefined;
};

export const AmountPurpose: React.FC<Props> = ({
    nextStep,
    accountFeeInfo,
    transferType,
    bInternational,
    purposes,
    initialValues,
    accountType,
    loading,
    bFxTransfer,
    sourceCurrency,
    destinationCurrency,
    estimatedDestinationAmount,
    estimatedAmount,
    destinationAmount,
    payeeDetails,
    setBFxTransfer,
}) => {
    const accountDetails = useGetFiatAccountDetails();

    const onSubmit = (values: AmountPurposeForm) => {
        nextStep(values);
    };

    const purposeIsRequired = purposes.length > 0 && accountType !== 'Card';
    // BE tells me the best way to detect other will be to check for this string
    const otherPurposeCode: string | undefined = purposes.find(
        (purpose) => purpose.label.toLowerCase() === 'other'
    )?.value;

    const feeOptions =
        accountFeeInfo.transferTypes.find(
            (fee) => fee.transferType.toLowerCase() === transferType.toLowerCase()
        )?.feeOptions ?? [];

    const radioOptions = createFeeOptions(
        feeOptions ?? [],
        (accountDetails?.accountName as any) ?? undefined
    );
    const previousSelectedFee = feeOptions.find(
        (fee) => fee.bankFeeSplitType === initialValues.feeId
    );

    const validationSchema = useMemo(
        () =>
            bFxTransfer
                ? fxValidationSchema(purposeIsRequired, otherPurposeCode)
                : amountPurposeValidationSchema(purposeIsRequired, otherPurposeCode),
        [purposeIsRequired, otherPurposeCode, bFxTransfer]
    );

    useEffect(() => {
        logger.log({ destinationCurrency, sourceCurrency });
        setBFxTransfer(destinationCurrency !== sourceCurrency);
    }, [destinationCurrency, sourceCurrency, setBFxTransfer]);

    return (
        <Formik
            initialValues={
                bFxTransfer
                    ? {
                          ...initialFXValues,
                          amount: 0,
                          bFxTransfer,
                          purpose: '',
                          exchangeType: 'Send',
                          feeId: radioOptions.length > 0 ? radioOptions[0].value : null,
                          destinationAmount,
                          estimatedAmount,
                          estimatedDestinationAmount,
                          ...initialValues,
                          // This is in case the transfer type is changed
                          ...(!previousSelectedFee && {
                              feeId: radioOptions.length > 0 ? radioOptions[0].value : null,
                          }),
                      }
                    : {
                          ...initialFXValues,
                          amount: 0,
                          purpose: '',
                          feeId: radioOptions.length > 0 ? radioOptions[0].value : null,
                          ...initialValues,
                          // This is in case the transfer type is changed
                          ...(!previousSelectedFee && {
                              feeId: radioOptions.length > 0 ? radioOptions[0].value : null,
                          }),
                      }
            }
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {({ setFieldValue, values }) => (
                <Form>
                    {radioOptions.length > 1 && (
                        <RadioIdButtons
                            className="FeeOptions"
                            options={radioOptions}
                            fieldname="feeId"
                            label="Fee"
                        />
                    )}
                    {bFxTransfer ? (
                        <AmountFx
                            sourceCurrency={sourceCurrency}
                            destinationCurrency={destinationCurrency}
                            payeeDetails={payeeDetails}
                        />
                    ) : (
                        <FormTextField
                            field="amount"
                            label="Amount"
                            onChange={(e) => {
                                e.preventDefault();
                                const { value } = e.target;
                                const regex = /^[0-9]*\.?[0-9]{0,2}$/;
                                if (regex.test(value)) {
                                    setFieldValue('amount', value, false);
                                } else {
                                    setFieldValue('amount', values.amount, true);
                                }
                            }}
                        />
                    )}

                    <p className="mb-2">
                        Please {bInternational ? 'select' : 'provide'} an option that best describes
                        the reason for your transfer
                    </p>
                    {purposeIsRequired ? (
                        <WrappedFormSingleSelectField
                            fieldName="purpose"
                            options={purposes ?? []}
                            label={`${transferType} Payment Purpose `}
                            dropdownProps={{
                                placeholder: 'Please select',
                            }}
                        />
                    ) : (
                        <FormTextField
                            field="purpose"
                            label={`${transferType} Payment Purpose `}
                            placeholder="E.g. Utility Bill"
                        />
                    )}
                    <OtherPurpose otherPurposeCode={otherPurposeCode} />
                    <Button disabled={loading} color="third">
                        Next
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

type OtherPurposeProps = {
    purposeFieldname?: string;

    otherPurposeCode?: string;
    otherPurposeFieldname?: string;
};
export const OtherPurpose: React.FC<OtherPurposeProps> = ({
    purposeFieldname = 'purpose',
    otherPurposeFieldname = 'purposeOther',
    otherPurposeCode,
}) => {
    const [{ value: purposeValue }] = useField(purposeFieldname);
    const [, , { setValue: setOtherPurposeValue }] = useField(otherPurposeFieldname);

    useEffect(() => {
        // Reset other purpose value if purpose changes from other
        if (purposeValue === otherPurposeCode) return;
        setOtherPurposeValue(null);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purposeValue, otherPurposeCode]);

    if (purposeValue !== otherPurposeCode) return null;

    return (
        <FormTextField
            field={otherPurposeFieldname}
            label="Other Purpose"
            placeholder="E.g. Utility Bill"
        />
    );
};
