import React, { useState } from 'react';
import { ErrorM } from './ErrorM';
import { Input } from './Input';
import { FormFieldTooltip, TooltipIconData } from './FormFieldTooltip';

export interface FormTextFieldProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'autoComplete'> {
    field: string;
    label: React.ReactNode;
    required?: boolean;
    currencySymbol?: string;
    type?: string;
    hint?: string;
    optionalTag?: string;
    labelExtraInfo?: string;
    disabled?: boolean;
    placeholder?: string;
    sideBySide?: boolean;
    isVisible?: boolean;
    maxLength?: number;
    icon?: string;
    showIcon?: boolean;
    onClickIcon?: () => void;
    touched?: () => void;
    autoFocus?: boolean;
    autoComplete?: boolean;
    className?: string;
    wrapperClassname?: string;
    asterisk?: boolean;
    inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    readOnly?: boolean;
    step?: number | string;
    labelChild?: React.ReactNode;
    onInput?: (e: any) => void;
    onKeyDown?: (e: any) => void;
    inputProps?: JSX.IntrinsicElements['input'] | JSX.IntrinsicElements['textarea'];
    formSideBySide?: boolean;
    tooltip?: React.ReactNode;
    tooltipIconData?: TooltipIconData | null;
    disableFormikBlur?: boolean
}

const FormTextField = React.forwardRef<HTMLInputElement, FormTextFieldProps>(
    (
        {
            field,
            label,
            type,
            hint,
            labelExtraInfo,
            disabled,
            placeholder,
            sideBySide,
            isVisible = true,
            maxLength,
            icon,
            onClickIcon,
            showIcon = false,
            touched,
            autoFocus = false,
            autoComplete = true,
            inputMode,
            className = '',
            wrapperClassname: wrapperClassName = '',
            // Ideally shouldn't need this prop because the asterisk depends on whether the field
            // is required. However, there are plenty of forms in this project where the field are
            // required but it wouldn't make sense to show the asterisk and message.
            asterisk = false,
            onBlur,
            onChange,
            readOnly = false,
            step,
            labelChild,
            onKeyDown,
            onInput,
            inputProps,
            formSideBySide,
            currencySymbol,
            tooltip,
            tooltipIconData,
            disableFormikBlur = false
        },
        ref
    ) => {
        const [focused, setFocused] = useState(false);

        const onFocus = (e: any) => {
            inputProps?.onFocus && inputProps.onFocus(e);
            setFocused(true);
        };
        const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur && onBlur(e);
            setFocused(false);
        };

        return (
            <div
                className={`FormBox${sideBySide ? ' SideBySide' : ''}${
                    formSideBySide ? ' FormSideBySide' : ''
                }${isVisible ? '' : ' Hide'}${focused ? ' Focused' : ''} ${wrapperClassName}`}
            >
                <div className="FormLabel">
                    {labelChild ? (
                        labelChild
                    ) : (
                        <label htmlFor={field}>
                            {label}
                            {labelExtraInfo && (
                                <span className="Optional"> - {labelExtraInfo}</span>
                            )}
                            {asterisk && <span>*</span>}
                        </label>
                    )}
                    {hint && <div className="FormHint">{hint}</div>}
                </div>
                <div className="FormField">
                    <Input
                        id={field}
                        name={field}
                        type={type ? type : 'text'}
                        disabled={disabled}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        touched={touched}
                        autoFocus={autoFocus}
                        autoComplete={autoComplete ? 'on' : 'off'}
                        className={readOnly ? `${className} ViewOnly` : className}
                        inputMode={inputMode || undefined}
                        onBlur={handleOnBlur}
                        onChange={onChange}
                        readOnly={readOnly}
                        step={step}
                        onInput={onInput}
                        onKeyDown={onKeyDown}
                        currencySymbol={currencySymbol}
                        onFocus={onFocus}
                        tooltip={tooltip}
                        tooltipIconData={tooltipIconData}
                        disableFormikBlur={disableFormikBlur}
                        {...inputProps}
                        ref={ref}
                    />
                    {showIcon && icon && <img src={icon} alt="" onClick={onClickIcon} />}
                    <ErrorM key={field} name={field} />
                </div>
            </div>
        );
    }
);

export default FormTextField;
